import {React , useEffect, useContext, useState} from 'react';
import { Helmet } from 'react-helmet'; // Import the Helmet component
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom
import AboutZhTw from "./aboutZhTw";
import AuthContext from '../contexts/AuthContext';

function AboutUsZhTw() {
    const { goHome, goBackHome } = useContext(AuthContext);
    const [langData, setLangData] = useState(null);

    useEffect(() => {
        console.log('AboutUsZhTw goHome - ', goHome)
        goBackHome(false)
    }, [])

    useEffect(() => {
        // We use a IIFE (Immediately Invoked Function Expression) here
        // so we can use async/await, which isn't allowed in useEffect directly
        (async () => {
          try {
            const importedData = await import('../assets/locales/zh.json');
            setLangData(importedData.default || importedData);
          } catch (error) {
            console.error(`Error loading zh language file:`, error);
            // load English as default if the selected language file isn't found
            try {
              const fallbackData = await import('../assets/locales/en.json');
              setLangData(fallbackData.default || fallbackData);
            } catch (fallbackError) {
              console.error('Error loading fallback language file:', fallbackError);
            }
          }
        })();
    }, []); // Only re-run if defaultLanguage changes

    if (!langData) {
        return null; // or a loading spinner, etc.
    }
    
    return (
        <div>
            <Helmet>
                <title>關於我們 - 愛學霸 AI Learning Bar, Powered by GPT</title>
                <meta name="description" content="愛學霸針對五年級至十二年級正在學習英語的學生。由OpenAI的GPT驅動，提供個性化的閱讀和寫作測驗以加快語言學習。" />
                <meta name="keywords" content="ai, 人工智能, 英語學習, GPT, OpenAI, 閱讀測驗, 寫作測驗, ESL (以英語為第二語言的學習者), 語言學習, ChatGPT" />
                <link rel="alternate" href="https://ailearningbar.com/en/about-us" hreflang="en" />
                <link rel="alternate" href="https://ailearningbar.com/tw/about-us-zh-TW" hreflang="zh-TW" />
                <link rel="alternate" href="https://ailearningbar.com/jp/about-us-jp" hreflang="ja" />
                <link rel="alternate" href="https://ailearningbar.com/cn/about-us-zh-cn" hreflang="zh-CN" />

                <meta property="og:title" content="關於我們 - 愛學霸 AI Learning Bar, Powered by GPT" />
                <meta property="og:description" content="為五年級到十二年級的學生提供互動式閱讀測驗和寫作練習" />
                <meta property="og:locale" content="zh_TW" />
            </Helmet>

            <div className="content"  style={{ minWidth:'100%', padding: '2rem' }}>
                <AboutZhTw/>
            </div>
            <Link to="/terms-of-service">{langData.termOfService}</Link> {/* Add a link to the terms of service page */}
        </div>
    );
}

export default AboutUsZhTw;
