import React, {useContext, useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthContext from '../contexts/AuthContext';
import RadarDiagram from './RadarDiagram';
import './Hero_Section.css';
import './CTAButton.css';
import styled from 'styled-components';
import hero_background from '../assets/remote-learning.jpg'
import visualization_background from '../assets/Teal Illustration Digital Business Blog Banner.png'
import { Helmet } from "react-helmet";
import { Button as MuiButton, TextField as MuiTextField } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { buffer } from 'd3';
import TextWithLineBreaks from './TextWithLineBreaks';

const ReviewBox = styled(Box)`
  max-width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 15px;
  background-color: #f7f9fc; // a very light shade of blue
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); // shadow for depth
  border: 1px solid #dfe2e8; // a light gray border
  text-align: left;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    color: #2b8a3e; // a dark green for the title
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #37474f; // a dark gray for the text
  }
`;

const StyledRadarDiagramContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; // This will place maximum space between the two components
  align-items: center; // This will center the components vertically
  @media (max-width: 768px) { // This is the breakpoint for mobile devices
    flex-direction: column;
    align-items: flex-start; // This will align the components to the start in mobile view
  }
`;


const MainContent = styled.div`
  padding: 20px;
  background-color: #f1f1f1; /* Light grey */
  color: #37474f; /* Dark Grey (for good contrast against the background) */
  font-family: Arial, sans-serif;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; // Content is arranged in a column
  align-items: start; // Align the content to the start of the flex container

  h3 {
    color: #4caf50; /* Green */
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
`;


const StyledRadarDiagram = styled(RadarDiagram)`
  flex: 1; // This will make the component take up equal space as MessageContainer
  margin-right: 10px; // Add some margin to avoid sticking to the center
`;

// Styled Components
const MessageContainer = styled.div`
  flex: 1; // This will make the component take up equal space as StyledRadarDiagram
  padding: 10px 20px; 
  max-width:300px;
  margin: 10px;
  margin-left: 10px; // Add some margin to avoid sticking to the center
  border-radius: 10px;
  background-color: #ffeeba;
  border-color: #ffeeba;
  color: #856404;
  align-self: center; 
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 1.2em;
`;

const MessageButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: var(--green-500);
  color: white;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: var(--green-600);
  }
`;


const TextField = styled(MuiTextField)`
  && {
    width: 100%;
    .MuiOutlinedInput-root {
      fieldset {
        border-color: #4caf50; /* Green */
      }
      &:hover fieldset {
        border-color: #81c784; /* Light Green */
      }
      &.Mui-focused fieldset {
        border-color: #2e7d32; /* Dark Green */
      }
    }
    .MuiInputLabel-root {
      color: #4caf50; /* Green */
      &.Mui-focused {
        font-weight: 600; /* Make the font bolder when input is focused */
        color: #2e7d32; /* Dark Green */
      }
    }
    .MuiOutlinedInput-input {
      color: #37474f; /* Dark Grey (for good contrast against the green) */
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      color: #2e7d32; /* Dark Green */
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;

const Button = styled(MuiButton)`
  && {
    background-color: var(--green-600); // Initial background color
    color: white; // Initial text color

    &:hover {
      background-color: var(--green-700); // Darker shade of green when hovered
    }
  }
`;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  max-width: 100%;
  // margin: auto;
  height: calc(100vh - 40px);
  box-sizing: border-box;
  color: white;
  
`;

const TextBlock = styled.div`
  background-color: rgba(238, 238, 238, 0.7);
  color: #fff;
  padding: 20px;
  margin: 20px;
  margin-top: 20px;
  text-align: left;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 5px; //Added border-radius
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); //Added box-shadow for depth
  min-height: ${props => props.isLogin ? '30%' : '50%'}; 
  height: auto; 
  left: 0;
  top: 0;
  overflow: auto;
  h1 {
    color: var(--green-800);
    font-weight: bold;
    font-size: 2.5em; //Enlarged the font size for better visual hierarchy
  }
  h2 {
    color: var(--green-700);
    font-size: 1.8em; //Enlarged the font size for better visual hierarchy
  }
  h3 {
    color: var(--green-900);
  }
  p {
    color: var(--green-900);
  }
  max-height: ${props => props.isLogin ? '40vh' : '70vh'}; 
`;

const H1 = styled.h1`
  font-size: 3rem; // Enlarged the font size for better visual hierarchy
  margin-bottom: 20px;
`;

const H2 = styled.h2`
  font-size: 2rem; // Enlarged the font size for better visual hierarchy
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  font-size: 1.25rem; // Enlarged the font size for better readability
  margin-bottom: 40px;
  line-height: 1.6; // Added line-height for better readability
`;

const CTAButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
`;

const LicenseBlock = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;  /* adjust color as needed */
  background-color: rgba(0, 0, 0, 0.1); /* adjust color and opacity as needed */
  padding: 5px;
  font-size: 0.8rem;  /* adjust size as needed */
  border-radius: 5px;
`;

const HeroSection = styled.div`
  width: 100%;
  background-color: var(--green-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  min-height: 40vh; /* Adjust this value as needed */
  color: #1e1e1e; /* dark color for text */
  text-align: center;
  margin-bottom: 2rem; /* Adjust this value as needed */
  margin-top: 1rem; /* Adjust this value as needed */
`;



const CTAButton = styled(MuiButton)`
  && {
    font-size: 1.25rem;
    background-color: var(--green-500);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    margin-right: 80px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s; // add box-shadow to the transition
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
    &:hover {
      background-color: var(--green-400);
      box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.5);
    }
  }
`;

const TaskContainer = styled(Box)`
    width: 100%; // Container takes full width of the parent.
    border: 1px solid #ddd; // Border for the container.
    padding: 16px; // Padding for inner content.
    box-sizing: border-box; // Ensuring padding is included in total width.
    overflow-wrap: break-word; // Ensuring long text wraps and doesn't overflow.
`;

const Background = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  /* Adjust the height as you need it */
  height: 600px;
  /* Positioning text to the bottom */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2rem;  /* Add some padding at the bottom */
`;

const mobileStyles = {
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'flex-end', 
  textAlign: 'right',
};

const desktopStyles = {
  // your current styles here...
};

function Hero_Section({ onStartQuiz }) {

  const { userOauthInfo, selectedLevel, defaultLanguage, setDefaultLanguage, isLogin, startLoading, finishLoading, setFetchError } = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [task_state, setTaskState] = useState('database');
  const [student_response, setStudentResponse] = useState(''); // state to store user's input
  const [userInput, setUserInput] = useState(''); // state to store user's input
  const [quickTask, setQuickTask] = useState(null)
  const [aiReview, setAiReview] = useState(false);

  const reviewBoxRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const theme = useTheme();
  
  const isMobileDevice = window.innerWidth <= 480;
  <Helmet>
      <meta charSet="utf-8" />
      <title>{data===null?"AI English Learning GPT - Personalized Reading and Writing Tests":data.title}</title>
      <meta name="description" content={data===null?"For Grade 5-12 students learning English. Powered by OpenAI's GPT, it provides personalized reading and writing tests to accelerate language acquisition.":data.metaDescription} />
      <meta name="keywords" content={data===null?"ai, english learning, gpt, OpenAI, reading test, writing test, ESL, language acquisition, ChatGPT":data.keywords} />
  </Helmet>


  const handleClick = () => {
    console.log('CTA button clicked');
    onStartQuiz(); // Call the onStartReadingQuiz function when the CTA button is clicked
  };

  const handleSubmit = async () => {
    // Here you will make a call to AI API to get answer
    // After getting answer, you might want to show it somewhere
    console.log('handleSubmit quickTask - ', quickTask)
    console.log('handleSubmit userInput- ', userInput)
    if (window.gtag) {
      window.gtag('event', 'quick_task_submit', {
        'event_category': 'button_click',
        'event_label': 'quick_task_handleSubmit'
      });
    }
    if (userInput === null || userInput === "" || userInput === undefined) {
      return;
    }
    setQuickTask(previousQuickTask => ({
      ...(previousQuickTask || {}), 
      'Student response': userInput
    }))
    
  };


  async function fetchWritingScore() {
    startLoading("The AI is now calculating your scores based on your answers. Please wait...");

    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
    console.log("fetchWritingScore answerFeedback - ", quickTask)

    const data = {
      'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
      "type":"MScore",
      'content': quickTask
    };

    // console.log('fetchWritingScore data - ', JSON.stringify(data))
    if (data == null) {
        return
    }
    const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://alice-language.netlify.app/'
      };

    try {
        const response = await fetch(fetch_url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
            mode: 'cors'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        console.log('http request headers', headers)
    
        const answers = await response.json();
        console.log('get answers - ', answers)

        console.log('fetchArticle writing answers - ', answers)    
        setQuickTask(previousQuickTask => ({
          ...(previousQuickTask || {}), 
          'response': {
              ...(previousQuickTask?.response || {}), 
              'Score': answers['score']
           }
        }))
        
        finishLoading()
    } catch (error) {
        finishLoading()
        console.error('Error fetching data from server:', error);

        setFetchError(`Error fetching data from server: ${error}`);
    }

  }

  async function fetchAnswer() {

    if (quickTask === null) {
        return
    }
    let bilingualLanguage = sessionStorage.getItem('defaultLanguage')
    // "language": defaultLanguage === 'zh-TW' ? 'Taiwanese' : (defaultLanguage === 'zh-CN' ? 'Chinese' : (defaultLanguage === 'ja' ? 'Japanese' : 'English')),

    startLoading("Please wait while the AI to review your answer...")
    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/blog';
    const data = {
      'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
      "type":"answer",
      "question": {"task": quickTask['task'], "instruction": quickTask['instructions']},
      "language": bilingualLanguage,
      'content': quickTask['Student response']
    };

    console.log('hero fetchAnswer data - ',data)

    const headers = {
      'Content-Type': 'application/json',
      'Origin': 'https://alice-language.netlify.app/'
    };

    try {
        const response = await fetch(fetch_url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
            mode: 'cors'
        });
        // console.log('http request headers', headers)
  
        const article_data = await response.json();
    
        console.log('fetchAnswer article_data - ', article_data)
        finishLoading()
        setQuickTask(previousQuickTask => ({
            ...(previousQuickTask || {}), 
            'response': {'feedback': article_data['content']}
        }))
    } catch (error) {
        finishLoading()
        console.error('Error fetching data from server:', error);
        setFetchError(error)
    }
  }

  const saveDataToCloudFunction = () => {
    const url = 'https://us-central1-writingai-380014.cloudfunctions.net/save_answer_data';
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    console.log('saveDataToCloudFunction quickTask - ', quickTask)
    const user_answers = {
        user_id: userOauthInfo?userOauthInfo.id: undefined,
        task_id: date,
        task: quickTask?[quickTask]:null,
    };
    console.log('saveDataToCloudFunction - ', user_answers)

    if (userOauthInfo) {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Origin': 'https://ailearningbar.com'
            },
            body: JSON.stringify(user_answers),
            })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch((error) => {
            console.error('Error:', error);
            });

    } else {
        console.log('writing page saveDataToCloudFunction - ', user_answers)
        sessionStorage.setItem('WritingQuizAnswer', JSON.stringify(user_answers))
        console.log('writing page sessionStorage - ', JSON.parse(sessionStorage.getItem('WritingQuizAnswer')))
    }
  
  }



  const selectRandomTaskItem = (task_object) => {
    const randomTask = task_object.Tasks[Math.floor(Math.random() * task_object.Tasks.length)];
    const randomItem = randomTask.Items[Math.floor(Math.random() * randomTask.Items.length)];

    console.log('selectRandomTaskItem randomTask - ', randomTask)
    console.log('selectRandomTaskItem randomItem - ', randomItem)
    const currentTimestamp = new Date().getTime(); 
    setQuickTask({
      "id": `${currentTimestamp}-${randomTask.ExerciseType}`,
      "expected level": "A2 Elementary",
      "exerciseType": randomTask.ExerciseType,
      "instructions": randomItem.Instructions,
      "task": randomItem.Task,
      "creation_date": new Date()
    })
  };

  async function fetchWritingTaskFromDatabase() {
    startLoading("Please wait while the AI is generating your Writing Contest questions...")
    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
    const request_data = {
        'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
        "type":"try",
        'message': ""
    };

    const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://alice-language.netlify.app/'
    };

    try {
        const response = await fetch(fetch_url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(request_data),
        mode: 'cors'
        });

        console.log('http request headers', headers)

        const article_data = await response.json();
        console.log('article_data - ', article_data)
        finishLoading()
        selectRandomTaskItem(article_data)
    } catch (error) {
        finishLoading()
        console.error('Error fetching data from server:', error);
        setFetchError(error)
    }
}


  const handleLogin = () => {
    if (isLogin) {
      navigate('/dashboard')
    } else {
      navigate('/dashboard?record=WritingQuizAnswer')
    }
  }

  const handleCET = () => {
    if (isLogin) {
      navigate('/tw/entrance-zh-tw')
    } else {
      sessionStorage.setItem('redirect', '/tw/entrance-zh-tw');
      navigate('/sign-in')
    }
  }

  useEffect(() => {
    console.log('useEffect quickTask - ', quickTask)
    console.log('useEffect quickTask aiReview- ', aiReview)
    if (quickTask === null) {
      return;
    }
    if (aiReview === true) {
      if (quickTask['response']['Score'] !== null && quickTask['response']['Score'] !== undefined) {
        console.log('ready to save data to cloud')
        saveDataToCloudFunction()
      }
      return
    }
    if (quickTask['Student response'] !== null && quickTask['Student response'] !== undefined && quickTask['Student response'] !== "") {
      if (quickTask['response'] === null || quickTask['response'] === undefined) {
        fetchAnswer()
      }
    }
    if (quickTask['response'] !== null && quickTask['response'] !== undefined) {
      setAiReview(true)
    } else {
      setAiReview(false)
    }
  }, [quickTask])

  useEffect(() => {
    if (aiReview && reviewBoxRef.current) {
      fetchWritingScore()
      window.scrollTo({
        top: reviewBoxRef.current.offsetTop,
        behavior: 'smooth' // smooth scroll
      });
    }
  }, [aiReview]);

  useEffect(() => {
    fetchWritingTaskFromDatabase()
  }, [])

  useEffect(() => {
    // We use a IIFE (Immediately Invoked Function Expression) here
    // so we can use async/await, which isn't allowed in useEffect directly
    (async () => {
      console.log('Hero_Section defaultLanguage - ', defaultLanguage)
      let file_name = 'en'
      try {
        if (defaultLanguage === 'zh-TW') {
          file_name = 'zh'
        } else if(defaultLanguage === 'zh-CN') {
          file_name = 'cn'
        } else if(defaultLanguage === 'ja') {
          file_name = 'ja'
        } else {
          file_name = 'en'
        }
        console.log('Hero_Section file_name - ', file_name)
        const importedData = await import(`../assets/locales/${file_name}.json`);
        setData(importedData.default || importedData);
      } catch (error) {
        console.error(`Error loading ${file_name} language file:`, error);
        // load English as default if the selected language file isn't found
        try {
          const fallbackData = await import('../assets/locales/en.json');
          setData(fallbackData.default || fallbackData);
        } catch (fallbackError) {
          console.error('Error loading fallback language file:', fallbackError);
        }
      }
    })();
  }, [defaultLanguage]); // Only re-run if defaultLanguage changes

  if (!data) {
    return null; // or a loading spinner, etc.
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{data===null?"AI English Learning GPT - Personalized Reading and Writing Tests":data.title}</title>
          <meta name="description" content={data===null?"For Grade 5-12 students learning English. Powered by OpenAI's GPT, it provides personalized reading and writing tests to accelerate language acquisition.":data.metaDescription} />
          <meta name="keywords" content={data===null?"ai, english learning, gpt, OpenAI, reading test, writing test, ESL, language acquisition, ChatGPT":data.keywords} />
      </Helmet>
      <div style={isMobileDevice ? mobileStyles : desktopStyles}>
        <div className="hero-section" id='hero'
            style={{ 
              background: '#ffffff',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              position: 'relative',  
              height: '90vh',
              width: '100%',
              marginTop: '0px'
            }}
            aria-label={data.description}
        >
          <TextBlock isLogin={isLogin}>

            <h1>{data.welcomeTitle}</h1>
            <h2>{data.heroIntroduce1}</h2>
            <p>{data.heroIntroduce2}</p>
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-end',  // Aligns items to the right in flex container
              textAlign: 'right'  // Aligns text to the right
            }}>
              <Button 
                variant="contained" 
                onClick={handleCET}
                style={{ width: '320px', marginBottom: '10px' }}
              >
                大學學測英文作文模擬
              </Button>
              <Typography variant="body1" style={{ color: 'red' }}>免費註冊，馬上測試！</Typography>
            </div>
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'flex-end',  // Aligns items to the right in flex container
              textAlign: 'right'  // Aligns text to the right
            }}>
              <h3>Quick Writing Test</h3> 
              <TaskContainer>
                  {quickTask ? (
                      <>
                          <Typography variant="h6">Task: {quickTask.task}</Typography>
                          <Typography variant="body1">Instructions: {quickTask.instructions}</Typography>
                      </>
                  ) : (
                      <Typography variant="body1">Loading Task...</Typography>
                  )}
              </TaskContainer>


              <TextField 
                label="Your Response"
                variant="outlined"
                multiline
                minRows={3}
                maxRows={6}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: 'rgba(255,255,255,0.1)' }}  
                placeholder={data.QuickTextField_Hint}  // Add this line for placeholder
              />
              <Button 
                variant="contained" 
                onClick={handleSubmit}
                style={{ marginTop: '10px' }}
              >
                {data.QuickGPTAnalysis}
              </Button>

            </div>
          </TextBlock>
        </div>
        {aiReview && (
          <div ref={reviewBoxRef}>
            <ReviewBox>
              <StyledRadarDiagramContainer>
                <StyledRadarDiagram
                  record={{
                    response: {
                      Score: {
                        Adherence: quickTask?.response?.Score?.Adherence || '1',
                        Clarity: quickTask?.response?.Score?.Clarity || '1',
                        Grammar: quickTask?.response?.Score?.Grammar || '1',
                        Positive: quickTask?.response?.Score?.Positive || '1',
                        Vocabulary: quickTask?.response?.Score?.Vocabulary || '1',
                      },
                    },
                  }}
                />
                <MessageContainer>
                  {isLogin===true?
                    <div>
                      <MessageText>Go to dashboard</MessageText>
                      <MessageButton onClick={handleLogin}>Go to Dashboard</MessageButton>
                    </div>
                    :
                    <div>
                      <MessageText>You're not logged in!</MessageText>
                      <MessageButton onClick={handleLogin}>Log in to see your score diagram</MessageButton>
                    </div>
                  }
                </MessageContainer>
              </StyledRadarDiagramContainer>
              <MainContent>
                <h3>{data.aiReviewTitle}</h3>
                <TextWithLineBreaks text={quickTask['response']===null?'':quickTask['response']===undefined?'':quickTask['response']['feedback']} />
              </MainContent>
            </ReviewBox>
          </div>
        )}
      </div>
    </>
  );
}

export default Hero_Section;
