import React, { useState, useEffect } from 'react';
import AuthContext from './AuthContext';
import MessageBox from '../components/MessageBox';


const AuthProvider = ({ children }) => {

  let browserLanguage = navigator.language || navigator.userLanguage;
  console.log('AuthProvider browserLanguage - ', browserLanguage)
  // browserLanguage = browserLanguage.split('-')[0];
  // console.log('browserLanguage - ', browserLanguage)
  const supportedLanguages = ['en', 'zh-TW', 'ja', 'zh-CN']; // add here your supported languages
  if (!supportedLanguages.includes(browserLanguage)) {
    browserLanguage = 'en'; // Fallback to English if the browser language is not supported
  }
  
  const [isLogin, setIsLogin] = useState(false); // Or whatever default you want
  const [userOauthInfo, setOauthUserInfo] = useState(null);
  const [selectedLevel, setLevel] = useState('A1 Beginner');  // Default level
  const [showReadingQuiz, setShowReadingQuiz] = useState(false); // Add this line
  const [showWritingQuiz, setShowWritingQuiz] = useState(false);
  const [goHome, setGoHome] = useState(true);
  const [currentProgressIndex, setCurrentProgressIndex] = useState(0);
  const [lastCompleteIndex, setLastCompleteIndex] = useState(0)
  // const [loading, setLoading] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [defaultLanguage, setDefaultLanguage] = useState(browserLanguage)
  const [FetchError, setFetchError] = useState(null);
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [messageBoxMessage, setMessageBoxMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState("");


  // Function to set a sessionStorage item
  const setSessionStorageItem = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  // Function to get a sessionStorage item
  const getSessionStorageItem = (key) => {
    return sessionStorage.getItem(key);
  };

  // Function to remove a sessionStorage item
  const removeSessionStorageItem = (key) => {
    sessionStorage.removeItem(key);
  };

  // Function to clear all sessionStorage items
  const clearSessionStorage = () => {
    sessionStorage.clear();
  };
  
  const showMessage = (message) => {
    setMessageBoxMessage(message);
    setMessageBoxOpen(true);
    // Automatically close the message box after 3 seconds
    setTimeout(() => {
      setMessageBoxOpen(false);
    }, 3000);
  }

  // We'll use this to change the login status
  const setLoginStatus = (status) => {
    setIsLogin(status);
  }

  const startLoading = (message) => {
    setLoadingCounter(prevCount => prevCount + 1);
    setLoadingMessage(message);
  };
  
  const finishLoading = () => {
    setLoadingCounter(prevCount => Math.max(0, prevCount - 1));
    if (loadingCounter <= 0) {
      // If no more loading processes are active after this one, clear the loading message
      setLoadingMessage("");
    }
  };

  useEffect(() => {
    console.log('AuthProvider loadingCounter - ', loadingCounter)
  }, [loadingCounter])

  // manage showReadingQuiz and showWritingQuiz based on currentProgressIndex
  useEffect(() => {
    if (currentProgressIndex < 0) {
      setCurrentProgressIndex(0)
    } else if (currentProgressIndex > 1) {
      setCurrentProgressIndex(1)
    }
    if (currentProgressIndex === 1) {
      setShowReadingQuiz(false)
      setShowWritingQuiz(true)
    } else {
      setShowReadingQuiz(false)
      setShowWritingQuiz(false)
    }
  }, [currentProgressIndex]);

  useEffect(() => {
    console.log('AuthProvider showReadingQuiz - ', showReadingQuiz)
    console.log('AuthProvider showWritingQuiz - ', showWritingQuiz)
    if (!showReadingQuiz && !showWritingQuiz) {
      console.log('ready to go home')
      // setGoHome(true);
    }

  }, [showReadingQuiz, showWritingQuiz])

  useEffect(() => {
    console.log('useEffect FetchError - ', FetchError)
    if (FetchError && !FetchError.includes('200')) {
      setTimeout(() => {
        // setFetchError(null);
        if (lastCompleteIndex <= 0) {
          goBackHome()
        }
      }, 3000);
    }
  }, [FetchError])

  const setSelectedLevel = (level) => {
    console.log('update level in AuthProvider - ', level)
    setLevel(level);
    // Assuming userOauthInfo contains user's ID or email
    if (userOauthInfo) {
      console.log('setSelectedLevel userOauthInfo - ', userOauthInfo)
      console.log('setSelectedLevel level - ', level)
      updateUserLevel(userOauthInfo.id, level).catch(err => console.error(err));
    }
  }

  // Add this function
  const startReadingQuiz = () => {
    setGoHome(false)
    // setCurrentProgressIndex(1)
    setShowWritingQuiz(false)
    setShowReadingQuiz(true);

    return setShowReadingQuiz;
  }

  const startWritingQuiz = () => {
    setGoHome(false)
    setShowWritingQuiz(true)
    setShowReadingQuiz(false)
    setCurrentProgressIndex(0)
    // setCurrentProgressIndex(4)
    // setShowWritingQuiz(false)
    // setShowReadingQuiz(true);
  }

  const goBackHome = () => {
    setShowWritingQuiz(false)
    setShowReadingQuiz(false)
    setCurrentProgressIndex(0)
    setLastCompleteIndex(0)
    setLastCompleteIndex(0)
    setGoHome(true);
  }


  const setSessionLanguage = (language) => {
    console.log('set session language - ', language)
    sessionStorage.setItem('defaultLanguage', language)
    switch (language) {
      case 'zh-TW':
        sessionStorage.setItem('defaultLanguage', 'Taiwanese')
        break;
      case 'zh-CN':
        sessionStorage.setItem('defaultLanguage', 'Chinese')
        break;
      case 'ja':
        sessionStorage.setItem('defaultLanguage', 'Japanese')
        break;
      case 'en-US':
        sessionStorage.setItem('defaultLanguage', 'English')
        break;
      default:
        sessionStorage.setItem('defaultLanguage', 'English')
        break;
    }
  }

  useEffect(() => {
    console.log('context ready to set default language - ', defaultLanguage)
    let selectedLanguage = sessionStorage.getItem('defaultLanguage')
    console.log('context ready to set selectedLanguage - ', selectedLanguage)
    if (selectedLanguage === null) {
      setSessionLanguage(defaultLanguage)
    }
    if (userOauthInfo) {
      updateUserDefaultLanguage(userOauthInfo.id, defaultLanguage)
    }
  }, [defaultLanguage])

  useEffect(() => {
    if (userOauthInfo) {
      console.log('User OAuth info has been updated:', userOauthInfo);
      // Here you can add any process you want to happen when userOauthInfo is updated
      // For instance, an API call, data manipulation, etc.
      setSelectedLevel(userOauthInfo.level===null?'A1 Beginner': userOauthInfo.level)
    }
  }, [userOauthInfo]); // The dependency array includes userOauthInfo, so this useEffect will run any time userOauthInfo changes


  return (
    <AuthContext.Provider value={{ 
      setSessionStorageItem,
      getSessionStorageItem,
      removeSessionStorageItem,
      clearSessionStorage,
      isLogin, setLoginStatus, 
      userOauthInfo, setOauthUserInfo, 
      selectedLevel, setSelectedLevel,
      showReadingQuiz, startReadingQuiz,
      showWritingQuiz, startWritingQuiz,
      goHome, goBackHome ,
      currentProgressIndex, setCurrentProgressIndex,
      lastCompleteIndex, setLastCompleteIndex,
      loadingCounter, startLoading, finishLoading,
      defaultLanguage, setDefaultLanguage,
      FetchError, setFetchError,
      showMessage,
      loadingMessage
  }}>
      {children}
      <MessageBox isOpen={messageBoxOpen} message={messageBoxMessage} />
    </AuthContext.Provider>
  );
};

async function updateUserDefaultLanguage(userId, language) {
  try {

    // Construct the URL of your cloud function
    const url = `https://us-central1-writingai-380014.cloudfunctions.net/update_user_information?user_id=${userId}&language=${language}`;

    console.log('updateUserLevel url - ' + url)
    // Send an HTTP request to your cloud function
    const response = await fetch(url, {
      method: 'POST', // or 'PUT', depending on how you've implemented your cloud function
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary authorization headers
      },
      // No need to send a body with the request since you're sending data in the URL parameters
    });

    // Throw an error if the request was not successful
    if (!response.ok) {
      throw new Error(`Failed to update level for user ${userId}`);
    }
  } catch(error) {
    console.error('There was an error!', error);
  }

}

async function updateUserLevel(userId, level) {

  try {

    // Construct the URL of your cloud function
    const url = `https://us-central1-writingai-380014.cloudfunctions.net/update_user_information?user_id=${userId}&level=${level}`;

    console.log('updateUserLevel url - ' + url)
    // Send an HTTP request to your cloud function
    const response = await fetch(url, {
      method: 'POST', // or 'PUT', depending on how you've implemented your cloud function
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary authorization headers
      },
      // No need to send a body with the request since you're sending data in the URL parameters
    });

    // Throw an error if the request was not successful
    if (!response.ok) {
      throw new Error(`Failed to update level for user ${userId}`);
    }
  } catch(error) {
    console.error('There was an error!', error);
  }
}

export default AuthProvider;
