import React, { useContext } from 'react'; // Don't forget to import useContext
import styled from 'styled-components';
import { Box } from '@mui/system';
import { Circles } from 'react-loader-spinner';
import AuthContext from './contexts/AuthContext';

const LoadingOverlay = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  flex-direction: column; // Added to stack the spinner and the message vertically
`;

const LoadingMessage = styled.div`
  color: #ffffff;
  margin-top: 20px; // Added to provide space between the spinner and the message
`;

const Loader = () => {
    const { loadingMessage } = useContext(AuthContext); // This line should be here

    return (
        <LoadingOverlay>
            <Circles type="Oval" color="#00BFFF" height={80} width={80} />
            <LoadingMessage>{loadingMessage}</LoadingMessage> // Use the loadingMessage from context here
        </LoadingOverlay>
    );
}

export default Loader;
