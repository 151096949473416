import React, {useContext, useState, useEffect} from 'react';
import AuthContext from '../contexts/AuthContext';
import './About.css'; // Import the new CSS file
// import zhTw from '../assets/locales/zh-tw.json'
// import en from '../assets/locales/en.json'


function About() {
  const { defaultLanguage, setDefaultLanguage } = useContext(AuthContext);
  const [data, setData] = useState(null);
  // const selectedLanguage = languages[defaultLanguage] ? defaultLanguage : 'en';

  console.log('defaultLanguage - ' + defaultLanguage)


  useEffect(() => {
    // We use a IIFE (Immediately Invoked Function Expression) here
    // so we can use async/await, which isn't allowed in useEffect directly
    (async () => {
      let file_name = 'en'
      try {
        if (defaultLanguage === 'zh-TW') {
          file_name = 'zh'
        } else if(defaultLanguage === 'zh-CN') {
          file_name = 'cn'
        } else if(defaultLanguage === 'ja') {
          file_name = 'ja'
        } else {
          file_name = 'en'
        }
        const importedData = await import(`../assets/locales/${file_name}.json`);
        setData(importedData.default || importedData);
      } catch (error) {
        console.error(`Error loading ${file_name} language file:`, error);
        // load English as default if the selected language file isn't found
        try {
          const fallbackData = await import('../assets/locales/en.json');
          setData(fallbackData.default || fallbackData);
        } catch (fallbackError) {
          console.error('Error loading fallback language file:', fallbackError);
        }
      }
    })();
  }, [defaultLanguage]); // Only re-run if defaultLanguage changes

  if (!data) {
    return null; // or a loading spinner, etc.
  }
  const { tableHeaders } = data;

    return (
      <div className="about" style={{ width:'100%', paddingRight:'60px' }}>
        <h1 style={{ color: 'var(--green-700)'}}>{data.welcomeTitle}</h1>
        <p>
          {data.introParagraph1}
        </p>
        <p>
          {data.introParagraph2}
        </p>
        <h3 style={{color: 'var(--green-700)'}}>{data.highlightsTitle}</h3>
        <ul>
          <li style={{color: 'var(--green-700)'}}>
            {data.highlight1}
          </li>
          <li style={{color: 'var(--green-700)'}}>
            {data.highlight2}
          </li>
          <li style={{color: 'var(--green-700)'}}>
            {data.highlight3}
          </li>
          <li style={{color: 'var(--green-700)'}}>
            {data.highlight4}
          </li>
        </ul>
          <p style={{color: 'var(--green-700)'}}>
            {data.AIUseStatement}
          </p>
          <h3 style={{color: 'var(--green-700)'}}>{data.stageTitle}</h3>
          <table id="my-table" style={{borderCollapse: 'collapse'}}>
            <thead>
              <tr style={{backgroundColor: 'var(--green-100)'}}>
                <th style={{padding: '10px'}}>{tableHeaders.englishLevel}</th>
                <th style={{padding: '10px'}}>{tableHeaders.target}</th>
                <th style={{padding: '10px'}}>{tableHeaders.readingTest}</th>
                <th style={{padding: '10px'}}>{tableHeaders.writing}</th>
              </tr>
            </thead>
            <tbody>
              {data.englishLevels.map((level, index) => (
                <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'var(--green-200)' : 'var(--green-300)'}}>
                  <td style={{padding: '10px'}}>{level.level}</td>
                  <td style={{padding: '10px'}}>{level.target}</td>
                  <td style={{padding: '10px'}}>{level.readingTest}</td>
                  <td style={{padding: '10px'}}>{level.writing}</td>
                </tr>
              ))}
            </tbody>
          </table>
        <p style={{color: 'var(--green-700)'}}>
          {data.closingStatement}
        </p>
      </div>
    );
}

export default About;
