import React from 'react';
import ReactDOM from 'react-dom';
import './MessageBox.css'; // assuming you will create this CSS file

const MessageBox = ({ isOpen, message }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='message-box-overlay'>
      <div className='message-box-body'>
        <p>{message}</p>
      </div>
    </div>,
    document.getElementById('message-box-root')
  );
}

export default MessageBox;
