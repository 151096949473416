import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DataLoader from '../contexts/DataLoader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import styled from 'styled-components';
import { Typography, Container, Button } from '@material-ui/core';
import { Helmet } from "react-helmet";
import AuthContext from '../contexts/AuthContext';

import { CardMedia } from '@material-ui/core';

import EnglishWritingPractice from './EnglishWritingPractice';

const StyledContainer = styled(Container)`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 100px;
`;

const ImageWrapper = styled.div`
  width: 70%;  // 设置容器宽度为整体UI的70%
  max-width: 100%;
  height: 180px;  // 你可以根据需求设置这个高度
  overflow: hidden;  // 以防万一图片超出容器
  margin: 20px auto;  // 居中图片并设置上下边距
`;

const StyledCardMedia = styled(CardMedia)`
  height: 100%;
  width: auto;  // 让图片自动缩放
  max-width: 100%;  // 确保图片不超出容器
  object-fit: contain;  // 使图片保持其原始的宽高比
  display: block;  // 去掉图片下方的小间距
  margin: 0 auto;  // 将图片水平居中
`;

const BlogDetail = ({ lang }) => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [showPractice, setShowPractice] = useState(false)
  const englishWritingPracticeRef = useRef(null);  // Using useRef
  const { defaultLanguage } = useContext(AuthContext);

  const togglePractice = () => {
    setShowPractice(!showPractice)
  }

  useEffect(() => {

  }, [defaultLanguage])

  useEffect(() => {
    console.log('BlogDetail post - ', post);
  }, [post]);
  
  useEffect(() => {
    console.log('BlogDetail id - ', id);
    // EnglishWritingPracticeRef.current.clearSessionStorage();
    if (englishWritingPracticeRef.current !== null) {
      englishWritingPracticeRef.current.clearSessionStorage();
    }
  }, [id]);

  return (
    <div>
      <Helmet>
        {/* <title>{post !== null ? post[0].title : 'Post Not Found'}</title> */}
        {/* <meta name="description" content={post !== null ? post[0].description : 'Post not found'} /> */}
        {/* Add other meta tags as needed */}
        <link rel="alternate" href={`http://localhost:3000/en/blog/${id}`} hreflang="en" />
        <link rel="alternate" href={`http://localhost:3000/tw/blog/${id}`} hreflang="zh-TW" />
        <link rel="alternate" href={`http://localhost:3000/jp/blog/${id}`} hreflang="ja" />
        <link rel="alternate" href={`http://localhost:3000/cn/blog/${id}`} hreflang="zh-CN" />
      </Helmet>
      <StyledContainer>
      <DataLoader postId={id} lang={lang}>
      {
        (data) => {
            setPost(data);
            return data===null || data.length<=0 ? (
              <Typography variant="h6">Post not found!</Typography>
            ) : (
              <div>
                  <Typography variant="h4" gutterBottom>{data[0].title}</Typography>
                  {
                    data[0].imageSrc && 
                    <ImageWrapper>
                      <StyledCardMedia 
                        image={data[0].imageSrc} 
                        alt={data[0].imageAlt ? data[0].imageAlt : "ailearningbar.com 愛學霸 Blog Image"} 
                      />
                    </ImageWrapper>
                  }
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{data[0].content}</ReactMarkdown>
                  <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: "1rem 0", backgroundColor: "#3f51b5" }} // customize as you like
                        onClick={togglePractice}
                      >
                        Toggle Practice
                      </Button>
                      {showPractice && (
                        <EnglishWritingPractice
                          ref={englishWritingPracticeRef}
                          post={data[0]}
                          visible={showPractice}
                        />
                      )}
              </div>
            );
        }
      }
      </DataLoader>
      </StyledContainer>
    </div>
  );
}

export default BlogDetail;
