import React from 'react';

const EnglishCertificationLevels = () => {
  const data = [
    { level: 'A1', description: 'Beginner', certificates: 'Cambridge English: Starters (YLE Starters), PTE Young Learners Firstwords' },
    { level: 'A2', description: 'Elementary', certificates: 'KET (Cambridge English: Key), Trinity GESE Grade 2-3, PTE General Level 1' },
    { level: 'B1', description: 'Intermediate', certificates: 'PET (Cambridge English: Preliminary), IELTS (band 4-5), PTE General Level 2' },
    { level: 'B2', description: 'Upper intermediate', certificates: 'FCE (Cambridge English: First), IELTS (band 5.5-6.5), TOEFL iBT (score 72-94), PTE General Level 3' },
    { level: 'C1', description: 'Advanced', certificates: 'CAE (Cambridge English: Advanced), IELTS (band 7-8), TOEFL iBT (score 95-120), PTE General Level 4' },
    { level: 'C2', description: 'Mastery or proficiency', certificates: 'CPE (Cambridge English: Proficiency), IELTS (band 8.5-9)' },
  ];

  return (
    <table>
      <thead>
        <tr>
          <th>Level</th>
          <th>Description</th>
          <th>Common Certificates</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.level}</td>
            <td>{row.description}</td>
            <td>{row.certificates}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EnglishCertificationLevels;