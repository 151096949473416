import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography, Container, Paper, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AuthContext from '../contexts/AuthContext';

const english_junior_book1_108_curriculum = {
    "NanYi":{
        "Starter": {
            "Topics": ["Asking names or phone numbers using 'What'"],
            "Grammar": ["Pronouns: singular and plural forms, object forms, possessive forms", "Sentence types: WH-questions"]
        },
        "U1: What's This?": {
            "Topics": ["Appearance or relative distance of objects/people"],
            "Grammar": ["Demonstratives: this, that, these, those", "Sentence types: declaratives with be-verbs", "Nouns: plural forms"]
        },
        "U2: Where Is Annie From?": {
            "Topics": ["International postcards, Wonders of the world"],
            "Grammar": ["Sentence types: WH-questions", "Asking identity or relationship using 'Who'", "Question and answer structures for 'Where ... from?'", "Question and answer structures for 'How old ...?'"]
        },
        "U3: Please Bring Your Favorite Dish": {
            "Topics": ["Potluck; Invitation card"],
            "Grammar": ["Imperative sentences"]
        },
        "U4: There Are Two Hippos in the House": {
            "Topics": ["Comic; Vet and animals", "Asking location using 'where'", "Question and answer structures for 'There is/are ...'"],
            "Grammar": ["Sentence types: WH-questions", "Introduction sentences with 'there'"]
        },
        "U5: My Friend Is Showing Me Around": {
            "Topics": ["Night market snacks/games; Chimei Museum"],
            "Grammar": ["Tenses: present continuous", "Sentence types: WH-questions", "Present continuous tense", "Question and answer structures for 'What time ...?'"]
        },
        "U6: We Can Save the Earth": {
            "Topics": ["Superheroes; Sea level rise; Asking expertise"],
            "Grammar": ["Auxiliary verbs", "Question and answer structures with auxiliary 'can'"]
        }
    },
    "Hanlin":{
        "starter": {
            "Topics": ["Asking names or phone numbers and age"],
            "Grammar": ["Singular personal pronouns in nominative and possessive cases", "Asking names or phone numbers using 'What'", "Asking age using 'How'"],
        },
        "U1 Who's That Handsome Boy?": {
            "Topics": ["Family relationships", "Professions", "Age", "Characteristics of people"],
            "Grammar": ["Usage of 'be' verb in declarative sentences", "Yes/No questions with 'be' verb", "Adjectives", "Sentence structures starting with the question word 'who' and their answers"]
        },
        "U2 What Are Those?": {
            "Topics": ["Locations", "Rooms", "Features of objects"],
            "Grammar": ["Demonstrative pronouns: this, that, these, those", "Plural form of nouns", "Questions using 'Where'"]
        },
        "U3 Open the Magic Door": {
            "Topics": ["Symbolic pictograms", "Suggestions (imperatives)"],
            "Grammar": ["Imperative sentences", "Usage of 'Can'", "Usage: Objective case of personal pronouns"]
        },
        "U4 What Time Is the Concert?": {
            "Topics": ["Days of the week", "(Idol) fan meetings", "News reports"],
            "Grammar": ["Asking about days using 'What day'", "Asking about time using 'What time'", "Yes/No questions in the present continuous tense and their answers"]
        },
        "U5 What's the Date?": {
            "Topics": ["Dates and months", "Festivals"],
            "Grammar": ["Asking about dates using 'What date'", "Cardinal and ordinal numbers", "Questions using 'When'"]
        },
        "U6 There Are Some Elephants over There": {
            "Topics": ["Travel", "Relationships between animals"],
            "Grammar": ["Questions and answers with 'There is/are'"]
        }
    }

}

const UnitReadingByPublisher = () => {
  const { selectedPublisher, selectedGrade } = useParams();
  const [units, setUnits] = useState([]);
  const [content, setContent] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { startLoading, finishLoading, FetchError, setFetchError } = useContext(AuthContext);


  useEffect(() => {
    console.log('useEffect selectedPublisher - ', selectedPublisher)
    console.log('useEffect selectedGrade - ', selectedGrade)
    const fetchUnits = async () => {
      const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/junior';
    
      const data = {
        'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
        "type":"Units",
        "PName": selectedPublisher,
        "level": "7U"
      };
    
      const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://alice-language.netlify.app/'
      };
    
      console.log('ready to fetch')
      try {
        startLoading()

        const response = await fetch(fetch_url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          mode: 'cors'
        });
    
        finishLoading()
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let fetchedUnits = await response.json();
        console.log('fetchedUnits - ', fetchedUnits)
        setUnits(fetchedUnits);
        // setLoading(false);
      } catch (error) {
        finishLoading()
        console.log('fetch error - ', error)
        setError(error.message);
        setFetchError(error.message)
        // setLoading(false);
      }
    };

    fetchUnits();
  }, [selectedPublisher, selectedGrade]);


  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleListItemClick = (unitName) => {
    console.log(unitName);
    console.log('PName - ' , selectedPublisher);
    console.log('level - ' , selectedGrade);

    const fetchUnitContent = async () => {
      const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/junior';
    
      const data = {
        'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
        "type":"GenJunior",
        "PName": selectedPublisher,
        "level": "7U",
        "Unit": unitName
      };
    
      const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://alice-language.netlify.app/'
      };
    
      console.log('ready to fetch')
      try {
        startLoading()

        const response = await fetch(fetch_url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          mode: 'cors'
        });
    
        finishLoading()
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let fetchedUnits = await response.json();
        console.log('fetchedUnits - ', fetchedUnits)
        // Set the content once fetched
        setContent(fetchedUnits);
        setSelectedUnit(unitName); // Set the currently selected unit

        // setLoading(false);
      } catch (error) {
        finishLoading()
        console.log('fetch error - ', error)
        setError(error.message);
        setFetchError(error.message)
        // setLoading(false);
      }
    };

    // Here you can navigate to the unit detail page or do other stuff
    fetchUnitContent()
  };

  return (
    <Container>
      <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick}>
        Back
      </Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Units List
      </Typography>
      <Paper elevation={3}>
        <List component="nav">
          {!selectedUnit ?
            Object.keys(units).map(unitName => (
              <ListItem button key={unitName} onClick={() => handleListItemClick(unitName)}>
                <ListItemText primary={unitName} />
              </ListItem>
            )) :
            // Display the selected unit
            <ListItem button onClick={() => setSelectedUnit(null)}> {/* To allow it to be expanded again */}
              <ListItemText primary={selectedUnit} />
            </ListItem>
          }
        </List>
      </Paper>
      {content && (
        <div style={{ 
          marginTop: '20px', 
          padding: '10px', 
          border: '1px solid #ddd', 
          borderRadius: '5px', 
          textAlign: 'left'  // Ensure left alignment
        }}>
          <Typography variant="h5" component="h2" gutterBottom align="left">
            {content.title}
          </Typography>
          <Typography variant="body1" paragraph style={{ whiteSpace: 'pre-line', fontSize: '1.5em' }} align="left">
            {content.content}
          </Typography>
        </div>
      )}
    </Container>
  );
};

export default UnitReadingByPublisher;