import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid, Paper, Box } from '@material-ui/core';

import { Helmet } from "react-helmet";
import './BlogPost.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'left',
  },
  headerImage: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  contentImage: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  truncatedTitle: {
    fontSize: '2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  excerpt: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  fullContent: {
    marginTop: theme.spacing(2),
  },
}));


function BlogPost(props) {

  const { post } = props;

  const classes = useStyles();

  console.log('BlogPost props - ', props)

  // Structured data for the blog post
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "image": post.imageSrc===undefined?"":post.imageSrc,
    "author": post.author, // add the author to your post data
    "datePublished": post.datePublished, // add the publish date to your post data
    // Add any other data that's relevant to your blog post
  };


  return (
    <div>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.keywords} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.imageSrc} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Container maxWidth="md" className={classes.root}>
        {post.imageSrc===""?<></>:<img src={post.imageSrc} alt={post.imageAlt} className={classes.headerImage} />}
        
        <Typography variant="h1" component="h1" gutterBottom className={classes.truncatedTitle}>
          <strong>{post.title}</strong>
        </Typography>


        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </Container>
    </div>
  );
}

export default BlogPost;
