import { viewReport } from './viewReport';
import React, { useState, useRef, useEffect, Fragment, useContext } from 'react';

import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styled from 'styled-components'; 
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import History from './History';
import ReactModal from 'react-modal';
import BarChart from './Visualizer';
import feedbackToScore from './feedbackToScore';
import TestShow from './TestShow';
import RadarDiagram from './RadarDiagram';
import ReviewFeedback from './ReviewFeedback';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import jwtDecode from 'jwt-decode';

import defaultUser from '../assets/8666609_user_icon.png'
import googleLogo from '../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png'; // you should replace 'path-to-google-logo.png' with the path to the actual Google logo
import ScoreChart from './ScoreChart';

const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: var(--grey-50);
    max-width: 1400px;
    margin: 0 auto;
`;
const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid var(--grey-300);
    border-radius: 10px;
    padding: 20px;
    background: var(--white);
`;

const ProfilePic = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
`;

const LevelSelection = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0;
    border: 1px solid var(--grey-300);
    border-radius: 10px;
    padding: 20px;
    background: var(--white);
`;

const LevelButton = styled.button`
    padding: 10px 20px;
    border: none;
    background: var(--green-500);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
    margin-left: 20px;
    &:hover {
        background: var(--green-600);
    }
`;

const PageContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const PageButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  ${({ active }) =>
    active
      ? `
        background: var(--green-500);
        color: white;
        &:hover {
          background: var(--green-600);
        }
      `
      : `
        background: var(--grey-200);
        &:hover {
          background: var(--grey-300);
        }
      `}
`;

const BarChartContainer = styled.div`
  width: 100%;
  height: 500px;
`;

const TestRound = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid var(--grey-300);
    border-radius: 10px;
    padding: 20px;
    background: var(--white);
`;

const TestRoundButton = styled.button`
    font-size: 1.25rem;
    background-color: var(--green-500);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 30px;

    &:hover {
        background-color: var(--green-400);
    }
`;

const StyledModal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  outline: none;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 80%;
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: white;
    background-color: var(--green-500);
    &:hover {
      background-color: var(--green-600);
    }
  }
`;

const StyledCancelButton = styled(Button)`
  && {
    color: var(--green-500);
  }
`;

const ModalTitle = styled.h2`
  color: var(--green-500);
`;

const ModalBody = styled.p`
  color: var(--green-800);
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  border: none;
  background: var(--green-500);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: var(--green-600);
  }
`;

// Styled Components
const MessageContainer = styled.div`
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  background-color: #ffeeba;
  border-color: #ffeeba;
  color: #856404;
`;

const MessageText = styled.p`
  margin: 0;
  font-size: 1.2em;
`;

const MessageButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: var(--green-500);
  color: white;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: var(--green-600);
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const LeftColumn = styled.div`
  width: calc(50% - 10px);
`;

const RightColumn = styled.div`
  width: calc(50% - 10px);
`;

const EraseDataButton = styled.button`
    padding: 10px 20px;
    border: none;
    background: var(--red-500);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
        background: var(--red-600);
    }
`;


function Dashboard(writingAnswerJson) {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recordHistory, setRecordHistory] = useState(null);
    const [scores_sample, setScoresSample] = useState(null);
    const [activePage, setActivePage] = useState('history');
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);
  
    const [confirmText, setConfirmText] = useState('');
    const [inputText, setInputText] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const record = query.get('record');
    const { isLogin, userOauthInfo, selectedLevel, setSelectedLevel, setLoginStatus, setOauthUserInfo, startReadingQuiz, startWritingQuiz, removeSessionStorageItem, getSessionStorageItem, clearSessionStorage, startLoading, finishLoading, setFetchError } = useContext(AuthContext);


    const handleLogin = async () => {
      navigate('/sign-in'+(query===null?'':'?'+query));
    };

    const handlePageChange = (page) => {
      setActivePage(page);
    };

    const formatDate = dateStr => {
      const [year, month, day] = dateStr.split('-').map(Number); // convert the parts to numbers
      const date = new Date(year, month - 1, day); // create a date with the parts
    
      // format the parts again to ensure they are two digits long
      const yearStr = String(date.getFullYear());
      const monthStr = ('0' + (date.getMonth() + 1)).slice(-2);
      const dayStr = ('0' + date.getDate()).slice(-2);
      
      return `${yearStr}-${monthStr}-${dayStr}`;
    }

    const transformData = (oldData) => {
      oldData = oldData.sort((a, b) => new Date(formatDate(a.task_id)) - new Date(formatDate(b.task_id)));

      const labels = oldData.map(item => {
        const [year, month, day] = item.task_id.split('-').map(Number);
        const date = new Date(year, month - 1, day);
    
        const yearStr = String(date.getFullYear());
        const monthStr = ('0' + (date.getMonth() + 1)).slice(-2);
        const dayStr = ('0' + date.getDate()).slice(-2);
        return `${yearStr}-${monthStr}-${dayStr}`;
      });
    
      // const labels = oldData.map(item => item.task_id);
      const colors = [
          'rgba(255,206,86,1)',  // color for Adherence
          'rgba(75,192,192,1)',  // color for Clarity
          'rgba(153,102,255,1)',  // color for Vocabulary
          'rgba(255,159,64,1)'  // color for Grammar
      ];
      const datasets = [];
      const totals = {};
      const counts = {};
  
      oldData.forEach((day, i) => {
          day.task.forEach(task => {
              const { response } = task;
  
              ['Adherence', 'Clarity', 'Vocabulary', 'Grammar'].forEach((criteria, colorIndex) => {                
                  if (!totals[criteria]) {
                      totals[criteria] = {};
                      counts[criteria] = {};
                  }
  
                  if (!totals[criteria][labels[i]]) {
                      totals[criteria][labels[i]] = 0;
                      counts[criteria][labels[i]] = 0;
                  }
  
                  totals[criteria][labels[i]] += response.Score[criteria];
                  counts[criteria][labels[i]] += 1;
              });
          });
      });
  
      Object.entries(totals).forEach(([label, data], colorIndex) => {
          datasets.push({
              label: label,
              data: Object.entries(data).map(([x, total]) => ({
                  x: x,
                  y: total / counts[label][x]
              })),
              borderColor: colors[colorIndex % colors.length],
              backgroundColor: colors[colorIndex % colors.length],
              pointBackgroundColor: colors[colorIndex % colors.length],
              pointBorderColor: colors[colorIndex % colors.length],
              tension: 0.1
          });
      });
  
      return { labels, datasets };
    };
    

    useEffect(() => {
      console.log('Dashboard recordHistory - ', recordHistory)
      console.log('Dashboard activePage - ', activePage)
      console.log('Dashboard isLogin - ', isLogin)
      if (recordHistory === null) {
        return;
      }
      const score_data = transformData(recordHistory.tasks)
      console.log('Dashboard score_data - ', score_data)
      setScoresSample(score_data)
    }, [recordHistory])
  
    useEffect(() => {
    }, [location]);

    useEffect(() => {
        // Do something when isLogin changes...
        console.log('Dashboard isLogin - ', isLogin)

        if(isLogin) {
          // For example, if the user is logged in, fetch user data
        }
    }, [isLogin]);

    useEffect(() => {
        console.log('user isLogin - ', isLogin)
        if (isLogin) {
            console.log('user userOauthInfo - ', userOauthInfo)
            console.log('user level - ', selectedLevel)
            console.log('session item - ', record)
            console.log('session item WritingQuizAnswer - ', JSON.parse(sessionStorage.getItem('WritingQuizAnswer')))
            if (record !== null) {
              saveDataToCloudFunction(JSON.parse(sessionStorage.getItem(record)))
              
            }
            handleHistoryLoaded()
        }
    }, [userOauthInfo, selectedLevel])
      
    useEffect(() => {
      // Set the history state using the writingAnswerJson prop
      // console.log('set history - ', JSON.stringify(writingAnswerJson))
      // setHistory(writingAnswerJson.task);
    }, [writingAnswerJson]);

    const changeLevel = (newLevel) => {
        // Logic to change level here
        setSelectedLevel(newLevel);
    }

    const getTasks = async (user_id) => {
      const response = await fetch('https://us-central1-writingai-380014.cloudfunctions.net/get_recent_tasks?user_id='+user_id+'&num_tasks=20');
      const data = await response.json();
      console.log('getTasks data - ', data)
      setRecordHistory(data);
    }

    const handleHistoryLoaded = () => {
      console.log('handleHistoryLoaded callback')
      getTasks(userOauthInfo.id);
    };
  
    const handleTestRound = () => {
        if (isLogin) {
          // If the user is logged in, start the test round and navigate to the appropriate page
          navigate("/");
          startWritingQuiz()
          // startReadingQuiz();
        } else {
          // If the user isn't logged in, open the modal
          setIsModalOpen(true);
        }
      }
    
      const closeModal = () => {
        setIsModalOpen(false);
      }
    
      const saveDataToCloudFunction = (user_answer) => {
        const url = 'https://us-central1-writingai-380014.cloudfunctions.net/save_answer_data';
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
        console.log('saveDataToCloudFunction - ', user_answer)
        console.log('saveDataToCloudFunction sessionStorage - ', JSON.parse(getSessionStorageItem('WritingQuizAnswer')))
        const user_answers = {
            user_id: userOauthInfo?userOauthInfo.id: undefined,
            task_id: date,
            task: user_answer?user_answer['task']:null,
        };
        if (userOauthInfo) {
            console.log('saveDataToCloudFunction - ', user_answers)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://ailearningbar.com'
                },
                body: JSON.stringify(user_answers),
                })
                .then((response) => {
                  console.log('saveDataToCloudFunction pass - ', response.json())
                  removeSessionStorageItem('WritingQuizAnswer')
                })
                .then(data => console.log(data))
                .catch((error) => {
                  console.error('Error:', error);
                });
    
        }
    }

    const handleSelectedItem = (item) => {
      console.log('habdleSelectedItem callback item - ', item)
      setSelectedHistory(item)
      // ReviewFeedback(item)
    }
 
    const handleEraseData = () => {
      openModal()
    };

    const handleConfirmErase = () => {
      console.log('handleConfirmErase useuserOauthInfo.idId - ', userOauthInfo.id)
      if (inputText !== confirmText) {
          // Show an error message if the inputText does not match the confirmText
          console.error("Confirmation text does not match! Deletion aborted.");
          return;
      }
  
      const userId = userOauthInfo.id
      const refresh_token = userOauthInfo.refresh_token
      console.log('handleConfirmErase userOauthInfo - ', userOauthInfo)
      console.log('handleConfirmErase userId - ', userId)
      // Assuming your API call is a POST request and doesn't require any additional data
      fetch(`https://us-central1-writingai-380014.cloudfunctions.net/delete_user_by_id?user_id=${userId}&refresh_token=${refresh_token}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // You might want to use the response data for something

        setConfirmOpen(false);
        setIsToastOpen(true);
        setErrorMessage(null); // Reset the error message
        setLoginStatus(false);
        clearSessionStorage();
        setOauthUserInfo(null);

        // Reset the input text
        setInputText('');

        setTimeout(() => {
            setIsToastOpen(false);
            navigate("/");
          }, 3000); // Hide the toast after 3 seconds.
      })
      .catch((error) => {
        console.error(error); // Handle the error
        setErrorMessage("Failed to erase data. Please try again."); // Set the error message
      });
    };

    const openModal = () => {
      // Generate a random string
      const randomString = Math.random().toString(36).substring(2, 7);
      setConfirmText(randomString);
      setConfirmOpen(true);
    }
   
    return (
      <>
        <DashboardContainer>
            {userOauthInfo ? (
                <UserInfo>
                    <ProfilePic src={userOauthInfo.picture} alt="Profile" />
                    <h2>Hello, {userOauthInfo.name}!</h2>
                    <p>Email: {userOauthInfo.email}</p>
                    <p>Current Level: {selectedLevel}</p>
                    <EraseDataButton onClick={handleEraseData}>Erase All Data</EraseDataButton>
                </UserInfo>
            ) : (
                <UserInfo>
                    <ProfilePic src={defaultUser} alt="Profile" />
                    <h2>Loading...</h2>
                </UserInfo>
            )}

            <LevelSelection>
                <h2>Select your proficiency level</h2>
                <LevelButton onClick={() => changeLevel('A1 Beginner')}>A1 Beginner</LevelButton>
                <LevelButton onClick={() => changeLevel('A2 Elementary')}>A2 Elementary</LevelButton>
                <LevelButton onClick={() => changeLevel('B1 Intermediate')}>B1 Intermediate</LevelButton>
                <LevelButton onClick={() => changeLevel('B2 Upper Intermediate')}>B2 Upper Intermediate</LevelButton>
            </LevelSelection>
            {selectedHistory && activePage === 'history' && (
              <MainContent>
                <LeftColumn>
                  <ReviewFeedback record={selectedHistory} />
                </LeftColumn>
                <RightColumn>
                  <RadarDiagram record={selectedHistory} />
                </RightColumn>
              </MainContent>
            )}
            <PageContainer>
              <PageButton
                active={activePage === 'history'}
                onClick={() => handlePageChange('history')}
              >
                Answer History
              </PageButton>
              <PageButton
                active={activePage === 'score'}
                onClick={() => handlePageChange('score')}
              >
                Score Diagram
              </PageButton>
            </PageContainer>
              {activePage === 'history' && (
                isLogin === false ? 
                <MessageContainer>
                  <MessageText>You're not logged in!</MessageText>
                  <MessageButton onClick={handleLogin}>Log in to see your history</MessageButton>
                </MessageContainer> 
                : 
                <History input_history={recordHistory} onSelectedItem={handleSelectedItem} ></History>
              )}
              {activePage === 'score' && (
                isLogin === false ? 
                <MessageContainer>
                  <MessageText>You're not logged in!</MessageText>
                  <MessageButton onClick={handleLogin}>Log in to see your score diagram</MessageButton>
                </MessageContainer> 
                : 
                <ScoreChart scoresList={scores_sample}/>
              )}

            <TestRound>
                <h2>Ready for the next challenge?</h2>
                <TestRoundButton onClick={handleTestRound}>Go to New Test Round</TestRoundButton>
                {/* <TestRoundButton onClick={isLogin===false?'':goToTestRound}>Go to New Test Round</TestRoundButton> */}
            </TestRound>

            <StyledModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Login Reminder"
                ariaHideApp={false}
            >
                <ModalTitle>Reminder!</ModalTitle>
                <ModalBody>Please login to get more Quiz opportunities.</ModalBody>
                <CloseButton onClick={closeModal}>Close</CloseButton>
            </StyledModal>

        </DashboardContainer>
        <StyledDialog open={isConfirmOpen} onClose={() => setConfirmOpen(false)}>
          <DialogTitle>
            <Typography variant="h5" color="primary">Are you sure?</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to erase all data? This action cannot be undone.
            </Typography>
            <Typography variant="body1">
              Please enter this text to confirm: <strong>{confirmText}</strong>
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmText"
              label="Confirm Text"
              name="confirmText"
              autoComplete="off"
              autoFocus
              value={inputText}
              onChange={e => setInputText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleConfirmErase} variant="contained">
              Yes, erase all data
            </StyledButton>
            <StyledCancelButton onClick={() => setConfirmOpen(false)} variant="text">
              Cancel
            </StyledCancelButton>
          </DialogActions>
        </StyledDialog>
        <ToastContainer />
        <Snackbar
          open={errorMessage !== null}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
          message={errorMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setErrorMessage(null)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
        {isToastOpen && toast.success('Data successfully erased!', {
          onClose: () => setIsToastOpen(false)
        })}
      </>
    );
}

export default Dashboard;
