import React, { useEffect, useState, useContext } from 'react';
import './VocabularyList.css';
import TextWithLineBreaks from './TextWithLineBreaks';
import AuthContext from '../contexts/AuthContext';

const VocabularyList = ({ pageIndex, words, level, native_language }) => {

  const { isLogin, setLoginStatus, userOauthInfo, setOauthUserInfo, selectedLevel, setSelectedLevel, startLoading, finishLoading } = useContext(AuthContext);

  const vocabulary_list = {"vocabularyList":[]}


  const [vocabularyList, setVocabularyList] = useState(vocabulary_list);


  // Append an item to the article array
  const appendItem = (newItem) => {
    setVocabularyList(newItem);
    let vocabularyListInStorage = sessionStorage.getItem('vocabularyList');
    if (vocabularyListInStorage && vocabularyListInStorage !== 'undefined') {
      vocabularyListInStorage = JSON.parse(vocabularyListInStorage);
      vocabularyListInStorage[pageIndex] = newItem;
    } else {
      vocabularyListInStorage = { [pageIndex]: newItem };
    }
    sessionStorage.setItem('vocabularyList', JSON.stringify(vocabularyListInStorage));
  };
  
  const deleteVocabulary = (pageIndexToDelete) => {
    setVocabularyList(prevState => {
      const newVocabularyList = { ...prevState };
      delete newVocabularyList[pageIndexToDelete];
  
      sessionStorage.setItem('vocabularyList', []);
      return newVocabularyList;
    });
  };

  const getVocabularyByPageIndex = (pageIndex) => {
    let vocabularyListInStorage = sessionStorage.getItem('vocabularyList');
    if (vocabularyListInStorage && vocabularyListInStorage !== 'undefined') {
      vocabularyListInStorage = JSON.parse(vocabularyListInStorage);
      const vocabularyInPage = vocabularyListInStorage[pageIndex];
      if (vocabularyInPage) {
        setVocabularyList(vocabularyInPage);
        return;
      }
    }
    fetchVocabulary();
  };
  

  const updateVocabulary = (pageIndexToUpdate, newVocabularyDetails) => {
    setVocabularyList(prevState => {
      const newVocabularyList = {
        ...prevState,
        [pageIndexToUpdate]: newVocabularyDetails,
      };

      sessionStorage.setItem('vocabularyList', vocabularyList[pageIndexToUpdate]);
      // sessionStorage.setItem('vocabularyList', JSON.stringify(newVocabularyList));
      return newVocabularyList;
    });
  };
  

  
  async function fetchVocabulary() {
    
    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
    console.log('ready to fetch words - ', words)
    const data = {
      'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
      "type":"vocabulary",
      "level": level,
      'vocabulary_list': words,
      "native_language": native_language
    };
  
    const headers = {
      'Content-Type': 'application/json',
      'Origin': 'https://alice-language.netlify.app/'
    };
  
    startLoading("The AI is retrieving details for your vocabulary list. Please hold on...");

    try {
      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors'
      });

      console.log('http request headers', headers)
  
      const vocabulary_detail = await response.json();
      
      finishLoading()
    // try {
      // const vocabulary_detail = vocabulary_item
      console.log('fetch vocabulary - ', vocabulary_detail)
      appendItem(vocabulary_detail)    
    } catch (error) {
      console.error('Error fetching data from server:', error);
      finishLoading()
    }

  }

  useEffect(() => {
  }, [])

  useEffect(() => {
    console.log('useEffect vocabularyList - ', vocabularyList)
    if (vocabularyList === undefined || vocabularyList.length <= 0) {
      getVocabularyByPageIndex(pageIndex);
    }
  }, [vocabularyList])

  useEffect(() => {
    getVocabularyByPageIndex(pageIndex);
  }, [pageIndex]);
  

  return (
      <div className="vocabulary-list">
        {vocabularyList && vocabularyList.length > 0 ?
          vocabularyList.map((word, index) => (
            <div className="vocabulary-item" key={index}>
              <VocabularyCard word={word} />
            </div>
          )) : ''
        }
      </div>
  );
};

const VocabularyCard = ( {word} ) => {
  useEffect(() => {
    console.log('word - ', word)
  }, [word])

  return (
    <div className="vocabulary-card">
      <h2>{word.Word}</h2>
      <div className="definition-container">
        <p className="definition">Definition:</p>
        <p className="definition-item">
         {word["Detailed Explanation"]===undefined?"":word["Detailed Explanation"]}
        </p>
      </div>
      <div className="example-container">
        <div className="example-item">
          <TextWithLineBreaks text = {word["Example Sentences"]===undefined?"":word["Example Sentences"]} />

        </div>
      </div>
    </div>
  );
};

export default VocabularyList;
