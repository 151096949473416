import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Avatar } from '@mui/material';

import jwt_decode from 'jwt-decode';
import axios from 'axios';
// const jwt = require('jsonwebtoken');  // Import the jsonwebtoken module
import { useLocation } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components'; 

import googleLogo from '../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png'; // you should replace 'path-to-google-logo.png' with the path to the actual Google logo
import default_User from '../assets/default_user_icon.png'

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: var(--green-500);
  color: #fff;
  font-size: 16px;
  gap: 5px;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--green-700);
  }
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357ae8;
  }
`;

const GoogleLogo = styled.img`
  height: 50px;
  width: auto;
`;

const UserImage = styled(Avatar)`
  margin-top: 10px;
  margin-right: 10px;
  width: 50px; // Set this to your preferred size
  height: 50px;
  cursor: pointer;
`;

const Login = () => {
  const location = useLocation();
  const { isLogin, setLoginStatus, userOauthInfo, setOauthUserInfo, selectedLevel, setSelectedLevel, FetchError, setFetchError } = useContext(AuthContext);

  const navigate = useNavigate();
  // const handleLogin = async () => {
  //   navigate('/sign-in');
  // };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    console.log('Login change location - ', location)
    console.log("Login urlParams:", urlParams);
    console.log("Login Token:", token);
    try {
      const decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);
      const fetchExtraUserInfo = async () => {
        let userInfo;
        try {
          userInfo = decodedToken.userinfo;
        } catch (error) {
          console.error('Failed to decode user info:', error);
        }
  
        if (userInfo) {
          console.log('userInfo - ', userInfo)
          const userId = userInfo['id']; // Assuming 'id' is the key for userId
  
          try {
            const response = await fetch(`https://us-central1-writingai-380014.cloudfunctions.net/get_user_by_id?user_id=${userId}`);
            const extraUserInfo = await response.json();
            console.log('extraUserInfo - ', extraUserInfo)
  
            // Merge userInfo and extraUserInfo
            const fullUserInfo = {
              ...userInfo,
              ...extraUserInfo,
              'refresh_token': decodedToken.refresh_token,
            };
  
            setLoginStatus(true);
            console.log('fullUserInfo - ', fullUserInfo)

            setOauthUserInfo(fullUserInfo);
          } catch (error) {
            console.error('Error:', error);
          }
        }
      };
      fetchExtraUserInfo();

    } catch (err) {
      console.log("Failed to decode token:", err);
    }

  }, [location])

  const handleLogin = async () => {
    if (isLogin) {
      // Check for redirect URL in session storage when the user tries to login
      const redirectTo = sessionStorage.getItem('redirect');
      if (redirectTo) {
          navigate(redirectTo); // Navigate to the specified URL
          sessionStorage.removeItem('redirect'); // Clear the redirect URL from session storage
      } else {
          navigate('/dashboard'); // Navigate to the default dashboard if no redirect URL is specified
      }
    } else {
      console.log('Login, handleLogin - sign-in')
      navigate('/sign-in');
    }
  };
  
  return (
    userOauthInfo ? (
      <UserImage src={userOauthInfo.picture} alt={userOauthInfo.name} onClick={handleLogin} />
    ) : (
      <LoginButton onClick={handleLogin}>Login</LoginButton>
    )
  )
};

export default Login;
