import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  dialogContent: {
    marginBottom: theme.spacing(2),
  },
  consentButton: {
    color: theme.palette.success.main,
  },
  rejectButton: {
    color: theme.palette.error.main,
  },
}));

const ConsentForm = ({ onConsent }) => {
  const classes = useStyles();

  const handleConsent = (isConsentGiven) => {
    onConsent(isConsentGiven);
    // if (isConsentGiven) {
    //   // Handle consent logic here
    //   onConsent(isConsentGiven);
    // }
  };

  return (
    <Dialog open className={classes.dialog}>
      <DialogTitle>{"User Consent"}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>
            In order to provide you with our services, we need your consent to process your personal data.
            Please click Agree if you consent to your data being processed for this purpose.
            You can read more about how we handle your data in our 
            <Link to="/privacy-policy">Privacy Policy</Link>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConsent(false)} className={classes.rejectButton}>
          Disagree
        </Button>
        <Button onClick={() => handleConsent(true)} className={classes.consentButton} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentForm;
