import React from 'react';

const TextWithLineBreaks = ({ text, fontSize = '22px' }) => {
  console.log('splitArticle - ' + text);

  if (typeof text === 'undefined') {
    return ''; // or you can return a message indicating that the text is not available
  }

  if (text === null) {
    return '';
  }


  return text.split(/(\r\n|\n|\r)/).map((line, index) => (
    <React.Fragment key={index}>
      {line === '\n' || line === '\r\n' ? <br /> : <span style={{ fontSize }}>{line}</span>}
    </React.Fragment>
  ));
};

export default TextWithLineBreaks;
