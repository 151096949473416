import { Line } from 'react-chartjs-2';
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom'; //import zoom plugin
import { useEffect, useState } from 'react';

// Register the components
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, TimeScale, zoomPlugin);


const ScoreChart = ({scoresList}) => {
    const [chartData, setChartData] = useState(null);

    console.log('ScoreChart scoresList - ', scoresList)
    // const data = {
    //         "labels": [
    //             "2023-7-3",
    //             "2023-7-4"
    //         ],
    //         "datasets": [
    //             {
    //                 "label": "Adherence",
    //                 "data": [
    //                     {
    //                         "x": "2023-7-3",
    //                         "y": 2.125
    //                     },
    //                     {
    //                         "x": "2023-7-4",
    //                         "y": 2.5
    //                     }
    //                 ],
    //                 "borderColor": "rgba(255,206,86,1)",
    //                 "backgroundColor": "rgba(255,206,86,1)",
    //                 "pointBackgroundColor": "rgba(255,206,86,1)",
    //                 "pointBorderColor": "rgba(255,206,86,1)",
    //                 "tension": 0.1
    //             },
    //             {
    //                 "label": "Clarity",
    //                 "data": [
    //                     {
    //                         "x": "2023-7-3",
    //                         "y": 4.25
    //                     },
    //                     {
    //                         "x": "2023-7-4",
    //                         "y": 2.5625
    //                     }
    //                 ],
    //                 "borderColor": "rgba(75,192,192,1)",
    //                 "backgroundColor": "rgba(75,192,192,1)",
    //                 "pointBackgroundColor": "rgba(75,192,192,1)",
    //                 "pointBorderColor": "rgba(75,192,192,1)",
    //                 "tension": 0.1
    //             },
    //             {
    //                 "label": "Vocabulary",
    //                 "data": [
    //                     {
    //                         "x": "2023-7-3",
    //                         "y": 4
    //                     },
    //                     {
    //                         "x": "2023-7-4",
    //                         "y": 2.125
    //                     }
    //                 ],
    //                 "borderColor": "rgba(153,102,255,1)",
    //                 "backgroundColor": "rgba(153,102,255,1)",
    //                 "pointBackgroundColor": "rgba(153,102,255,1)",
    //                 "pointBorderColor": "rgba(153,102,255,1)",
    //                 "tension": 0.1
    //             },
    //             {
    //                 "label": "Grammar",
    //                 "data": [
    //                     {
    //                         "x": "2023-7-3",
    //                         "y": 2.625
    //                     },
    //                     {
    //                         "x": "2023-7-4",
    //                         "y": 2.3125
    //                     }
    //                 ],
    //                 "borderColor": "rgba(255,159,64,1)",
    //                 "backgroundColor": "rgba(255,159,64,1)",
    //                 "pointBackgroundColor": "rgba(255,159,64,1)",
    //                 "pointBorderColor": "rgba(255,159,64,1)",
    //                 "tension": 0.1
    //             }
    //         ]
    // };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Language Level Progress'
            },
            zoom: {
                limits: {
                    x: {
                        min: chartData && chartData.labels && !isNaN(Date.parse(chartData.labels[0])) 
                        ? new Date(chartData.labels[0]).toISOString().slice(0,7) + "-01" 
                        : '',
                    max: chartData && chartData.labels && !isNaN(Date.parse(chartData.labels[chartData.labels.length - 1])) 
                        ? new Date(chartData.labels[chartData.labels.length - 1]).toISOString().slice(0,7) + "-" 
                        + new Date(chartData.labels[chartData.labels.length - 1]).getDate() 
                        : ''                                                    },
                    y: {
                        min: 0,
                        max: 5
                    }
                },
                pan: {
                    enabled: true,
                    mode: 'x',
                    speed: 20
                },
                zoom: {
                    wheel: {
                        enabled: false
                    },
                    pinch: {
                        enabled: false
                    },
                    drag: {
                        enabled: false
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                },
                min: chartData && chartData.labels ? chartData.labels[0] : '',
                max: chartData && chartData.labels ? chartData.labels[chartData.labels.length - 1]: ''
            },
            y: {
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    useEffect(() => {
        console.log(scoresList);
        if (scoresList && scoresList.labels && scoresList.datasets) {
            // Prepare data for the chart
            const data = {
                labels: scoresList.labels,
                datasets: scoresList.datasets
            };
            setChartData(data);
        }
    }, [scoresList]);
    

    return chartData && <Line data={chartData} options={options} />;
};

export default ScoreChart;
