// BlogPage.js

import React, { Component } from 'react';
import BlogPost from './BlogPost';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

import { Typography, Container, Grid, Paper, Divider } from '@material-ui/core';
import { Helmet } from "react-helmet";
import EnglishWritingPractice from './EnglishWritingPractice';
import BlogItem from './BlogItem'
import DataLoader from '../contexts/DataLoader';
import './BlogPage.css'
import { id } from 'date-fns/locale';
import { Box } from '@mui/material';


class BlogPage extends Component {

  englishWritingPracticeRefs = [];

  constructor(props) {
    super(props);
    this.state = {
      posts: [],  // assuming posts is an array of objects with a unique id
      showPractice: {}, // using an object to store visibility for each post
      dataLoaded: false
    };
  }

  componentDidMount() {
    this.scrollToHash(); // Scroll to the post if there is a hash in the URL

    window.addEventListener('scroll', this.handleScroll);
    const fetchedPosts = [/* fetched posts */];
    const showPractice = {};
    fetchedPosts.forEach(post => {
      showPractice[post.id] = false;
    });
    this.setState({ posts: fetchedPosts, showPractice });
  }

  componentWillUnmount() {
    console.log('Blog Page Unmount - ')
    // Clear sessionStorage for all EnglishWritingPractice components
    this.englishWritingPracticeRefs.forEach(ref => ref.current && ref.current.clearSessionStorage());
    // Remove scroll event listener when the component is unmounted
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToHash = () => {
    const { posts } = this.state;
    const hash = window.location.hash;
    if (hash) {
      const postId = hash.substring(1); // Remove the "#" character from the hash
      const postElement = document.getElementById(`post-${postId}`);
      if (postElement) {
        postElement.scrollIntoView({ behavior: 'smooth' });
        this.setState({ showWritingPractice: true, activePostId: postId });
      }
    }
  };


  handleScroll = () => {
    const { posts } = this.state;
    this.setState({ showWritingPractice: false, activePostId: null });

    // Get the current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    // Find the post that corresponds to the current scroll position
    let currentPost = null;
    for (const post of posts) {
      const postElement = document.getElementById(`post-${post.id}`);
      if (postElement) {
        const postOffsetTop = postElement.offsetTop;
        if (scrollPosition >= postOffsetTop) {
          currentPost = post;
        } else {
          break;
        }
      }
    }

    // Update the URL with the post ID
    if (currentPost) {
      const postURL = `#${currentPost.id}`;
      window.history.replaceState(null, null, postURL);
      this.setState({ showWritingPractice: true, activePostId: currentPost.id });
    }
  };

  togglePractice = (postId) => {
    this.setState(prevState => ({
      showPractice: {
        ...prevState.showPractice,
        [postId]: !prevState.showPractice[postId]
      }
    }));
  }

  render() {

    return (
      <div>
        <Helmet>
            <title>Blogger - 愛學霸 AI Learning Bar, Powered by GPT</title>
            <meta name="description" content="Our AI English Learning platform, powered by OpenAI's GPT, offers personalized reading and writing tests for Grade 5-12 ESL students. Start accelerating your language acquisition today." />
            <meta name="keywords" content="ai, english learning, gpt, OpenAI, reading test, writing test, ESL, language acquisition, ChatGPT" />
            <meta property="og:title" content="Blogger - 愛學霸 AI Learning Bar, Powered by GPT" />
            <meta property="og:description" content="Our AI English Learning platform, powered by OpenAI's GPT, offers personalized reading and writing tests for Grade 5-12 ESL students. Start accelerating your language acquisition today." />
            <link rel="alternate" href="https://ailearningbar.com/en/blogpost-en" hreflang="en" />
            <link rel="alternate" href="https://ailearningbar.com/tw/blogpost-zh-tw" hreflang="zh-TW" />
            <link rel="alternate" href="https://ailearningbar.com/jp/blogpost-jp" hreflang="ja" />
            <link rel="alternate" href="https://ailearningbar.com/cn/blogpost-zh-cn" hreflang="zh-CN" />
            <meta property="og:locale" content="en-US" />
        </Helmet>

        <DataLoader lang='en'>
          {(data) => {
            const showPractice = {};
            console.log('DataLoader data.length - ', data.length)
            console.log('DataLoader this.state.posts.length - ', this.state.posts.length)
            if (!this.state.dataLoaded) {
              data.forEach(post => {
                this.englishWritingPracticeRefs[post.id] = React.createRef();
                showPractice[post.id] = false;
                if (data.length === this.state.posts.length) {
                  this.setState({ posts: data, showPractice, dataLoaded: true });
                } else {
                  this.setState({ posts: data, showPractice, dataLoaded: false });
                }
              });
            } 

            
            return this.state.posts.slice().reverse().map((post, index) => (
              <div className="blog-page">
              {/* Iterate over all posts */}
                <React.Fragment key={post.id}>
                  {/* Each blog post content */}
                  <div id={`post-${post.id}`}>
                    <Box id='BlogItems' marginLeft='10px' maxWidth="90%" marginTop='20px'>
                      
                      <BlogItem id={post.id} title={post.title} brief={post.brief} url={''} imageUrl={post.imageSrc} />                    
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: "1rem 0", backgroundColor: "#3f51b5" }} // customize as you like
                        onClick={() => this.togglePractice(post.id)}
                      >
                        Toggle Practice
                      </Button>
                      {this.state.showPractice[post.id] && (
                        <EnglishWritingPractice
                          ref={ref => {
                            this.englishWritingPracticeRefs[post.id] = ref;
                          }}
                          post={post}
                          visible={this.state.showPractice[post.id]}
                        />
                      )}
                    </Box>
                    {/* Add a divider between each blog post */}
                    {index !== this.state.posts.length - 1 && <Divider />}
                  </div>
                </React.Fragment>
              </div>
              ));
          }}
        </DataLoader>
      </div>
    );
  }
}

export default BlogPage;
