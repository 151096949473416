import React, { useState, useEffect } from 'react';
import blogData_tw from '../assets/blog_dataset/blog_tw.json';
import blogData_cn from '../assets/blog_dataset/blog_cn.json'
import blogData_jp from '../assets/blog_dataset/blog_jp.json'
import blogData_en from '../assets/blog_dataset/blog_en.json'

const DataLoader = ({ postId, lang, children }) => {
    const [data, setData] = useState([]);


    useEffect(() => {
        let selectedData; // This will store the data based on the lang prop

        switch(lang) {
            case 'tw':
                selectedData = blogData_tw;
                break;
            case 'cn':
                selectedData = blogData_cn;
                break;
            case 'jp':
                selectedData = blogData_jp;
                break;
            case 'en':
                selectedData = blogData_en;
                break;
            default:
                // You can default to one of them or even have a default English data etc.
                selectedData = blogData_en; 
                break;
        }

        setTimeout(() => {
            console.log('DataLoader useEffect postId - ', postId)
            if (postId) {
                console.log('DataLoader useEffect selectedData - ', selectedData)
                console.log(typeof postId);
                console.log(typeof selectedData[0].id);

                const matchingPost = selectedData.find(p => p.id === Number(postId));
                console.log('DataLoader useEffect matchingPost - ', matchingPost)
                if(matchingPost) {
                    setData([matchingPost]);
                } else {
                    setData([]);  // if no matching post, set an empty array or handle this case differently
                }
            } else {
                console.log('DataLoader useEffect selectedData - ', selectedData)
                setData(selectedData);
            }
        }, 500);
    }, [postId, lang]);  // Added 'lang' dependency here

    useEffect(() => {
        console.log('DataLoader data - ', data)
    }, [data])

    return children(data);
};

export default DataLoader;
