import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const Container = styled.div`
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
`;

const FeedbackSection = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const FeedbackTitle = styled(Typography)`
  font-weight: bold;
  flex-grow: 1;
`;

const FeedbackItem = styled.div`
  margin-top: 10px;
`;

const LeftAlignedTypography = styled(Typography)`
  text-align: left;
`;

const findKeyInsensitive = (object, key) => {
  const foundKey = Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase());
  return object[foundKey];
};

const ReviewFeedback = ({ record }) => {
  const [open, setOpen] = useState(false);

  if (!record || !record['Student response']) {
    return null; // or render a default message/error
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <LeftAlignedTypography variant="h5">{record.Task}</LeftAlignedTypography>
      <LeftAlignedTypography>{record.Instructions}</LeftAlignedTypography>

      <FeedbackSection>
        <LeftAlignedTypography variant="h5">Student's Response</LeftAlignedTypography>
        <Box ml={1}>
          <IconButton onClick={handleToggle}>
            <ExpandMoreIcon color={open ? 'primary' : 'inherit'} />
          </IconButton>
        </Box>
      </FeedbackSection>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box ml={2}>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Student response")}</LeftAlignedTypography>
        </Box>
      </Collapse>

      <FeedbackSection>
        <LeftAlignedTypography variant="h5">Feedback</LeftAlignedTypography>
        <Box ml={1}>
          <IconButton onClick={handleToggle}>
            <ExpandMoreIcon color={open ? 'primary' : 'inherit'} />
          </IconButton>
        </Box>
      </FeedbackSection>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Feedback")}</LeftAlignedTypography>
        </FeedbackItem>
        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Sample Answer:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Sample Answer")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Positive Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Positive Feedback")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Grammar Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Grammar Feedback")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Vocabulary Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Vocabulary Feedback")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Clarity Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Clarity Feedback")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Adherence Feedback:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Adherence Feedback")}</LeftAlignedTypography>
        </FeedbackItem>

        <FeedbackItem>
          <FeedbackTitle variant="subtitle1">Assessment:</FeedbackTitle>
          <LeftAlignedTypography>{findKeyInsensitive(record.response, "Assessment")}</LeftAlignedTypography>
        </FeedbackItem>
      </Collapse>
    </Container>
  );
}

export default ReviewFeedback;
