// AppEn.js
import React, {useContext} from 'react';
import Highlight from './components/Highlight';
import Hero_Section from './components/Hero_Section';
import EnglishCertificationLevels from './components/EnglishCertificationLevels';
import WritingQuizPage from './components/WritingQuizPage';
import ReadingQuizPage from './components/ReadingQuizPage';
import AuthContext from './contexts/AuthContext';
import { Helmet } from "react-helmet";
import { Box } from '@mui/material';
import styled from 'styled-components';


// import other necessary components and hooks...
const StyledCertificationLevels = styled(EnglishCertificationLevels)`
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem;
`;

const StyledHighlight = styled(Highlight)`
  background-color: #fafafa;
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem;
`;
const AppCn = () => {
  // Move the related states, functions and effects here...
  const { showReadingQuiz, showWritingQuiz, currentProgressIndex, lastCompleteIndex, setCurrentProgressIndex, setLastCompleteIndex, startReadingQuiz, startWritingQuiz, goHome, setGoHome } = useContext(AuthContext);


  const handleReadingQuizPrevious = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex - 1)
  } 

  const handleReadingQuizCompletion = () => {
    if (currentProgressIndex >= lastCompleteIndex) 
      setLastCompleteIndex(currentProgressIndex)
  };

  const handleReadingQuizNext = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex + 1)
  }


  return (
    <div className="AppCn">
      <Helmet>
        <title>AI Learning Bar - 由人工智能驱动的高级英语学习</title>
        <meta name="description" content="通过AI Learning Bar提高您的英语语言技能。我们的平台由GPT提供支持，提供个性化的阅读和写作测试，实现有效而有趣的学习。立即开始！" />
        <meta name="keywords" content="AI驱动的英语学习，GPT，OpenAI，个性化阅读测试，个性化写作测试，学生英语，高级英语学习，ESL" />
        <link rel="alternate" href="https://ailearningbar.com/en/" hreflang="en" />
        <link rel="alternate" href="https://ailearningbar.com/tw/" hreflang="zh-TW" />
        <link rel="alternate" href="https://ailearningbar.com/jp/" hreflang="ja" />
        <link rel="alternate" href="https://ailearningbar.com/cn/" hreflang="zh-CN" />
      </Helmet>

      {/* English version components here */}
      {!showReadingQuiz && goHome && (
        <>
          <Box id='hero_section' marginLeft='10px' maxWidth="90%">
            <Hero_Section onStartQuiz={startWritingQuiz} />
          </Box>

          <Box id='Hightlights' marginLeft='10px' maxWidth="90%" >
            <Highlight />
          </Box>
        </>
      )}
      {!goHome && (
        <div className="reading-quiz-page-wrapper">
          {showWritingQuiz ? <WritingQuizPage /> : <ReadingQuizPage onPrev={handleReadingQuizPrevious} onCompletion={handleReadingQuizCompletion} onNext={handleReadingQuizNext}/>}
        </div>
      )}
    </div>
  );
}

export default AppCn;
