import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grade } from '@material-ui/icons';

const PublisherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; // This aligns items to the top
  margin-top: 50px;
  justify-content: space-around; // This will give more space between images
  height: 400px;
  flex-wrap: wrap; // This allows the items to wrap if they don't fit in a single row
`;

const PublisherGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px; // Adding horizontal margin for more space between publishers
`;

const GradeGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px; // Providing some spacing between grade groups
`;


const PublisherName = styled.div`
  margin-top: 10px;
  font-size: 32px;
  font-weight: bold;
`;

const GradeName = styled.div`
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
`;


const PublisherButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: relative;  /* Added */
  top: ${(props) => (props.selected ? '5px' : '0')}; /* Shifts the button downwards when selected */
  overflow: hidden;    /* Ensure the pseudo-element doesn't spill out */

  img {
    max-width: 100%;
    max-height: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    box-shadow: ${(props) => (props.selected ? 'inset 0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(0, 0, 0, 0.1)')};

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }
  }

`;


const GradeContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start; // This aligns items to the top
margin-top: 10px;
justify-content: space-around; // This will give more space between images
height: 400px;
flex-wrap: wrap; // This allows the items to wrap if they don't fit in a single row
`;

const GradeButton = styled.button`
background: ${(props) => (props.selected ? '#f0f0f0' : 'transparent')};
border: 1px solid ${(props) => (props.disabled ? '#ccc' : '#ddd')}; // Grey out the border for disabled buttons
opacity: ${(props) => (props.disabled ? '0.5' : '1')}; // Reduce the opacity for disabled buttons
pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; // Disable interactions for disabled buttons
padding: 0;
border-radius: 5px;
position: relative;  /* Added */
top: ${(props) => (props.selected ? '5px' : '0')}; /* Shifts the button downwards when selected */
overflow: hidden;    /* Ensure the pseudo-element doesn't spill out */

img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  box-shadow: ${(props) => (props.selected ? 'inset 0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(0, 0, 0, 0.1)')};

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
}
`;

const NextButton = styled.button`
  display: block;
  margin: 30px auto;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;


const fetchPublishers = async () => {
  // Mock function simulating an API call
  let publisher = [];
  const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/junior';

  const data = {
    'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
    "type":"publishers",
  };

  const headers = {
    'Content-Type': 'application/json',
    'Origin': 'https://alice-language.netlify.app/'
  };

  try {
    const response = await fetch(fetch_url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors'
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log('http request headers', headers);

    publisher = await response.json();
    publisher = publisher.map(p => ({
      ...p, 
      imageUrl: 'https://storage.googleapis.com/site_resource/publisher.png',
      selectedImageUrl: 'https://storage.googleapis.com/site_resource/publisher_selected.png'
    }));
    console.log('fetchArticle publishers - ', publisher);    

  } catch (error) {
    console.error('Error fetching data from server:', error);
  }

  return publisher;

};



const PublisherList = () => {
  const [publishers, setPublishers] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [grades, setGrades] = useState([
      {
        "id":"grade7", 
        "name":"七年級",
        imageUrl: 'https://storage.googleapis.com/site_resource/Student_Normal.png',
        selectedImageUrl: 'https://storage.googleapis.com/site_resource/Student_Selected.png'
      }, 
      {
        "id":"grade8", 
        "name":"八年級",
        imageUrl: 'https://storage.googleapis.com/site_resource/Student_Normal.png',
        selectedImageUrl: 'https://storage.googleapis.com/site_resource/Student_Selected.png'
      }, 
      {
        "id":"grade9", 
        "name":"九年級",
        imageUrl: 'https://storage.googleapis.com/site_resource/Student_Normal.png',
        selectedImageUrl: 'https://storage.googleapis.com/site_resource/Student_Selected.png'
      }]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPublishers().then(data => {
      setPublishers(data);
    });
  }, []);

  const handlePublisherClick = (publisherName) => {
    setSelectedPublisher(publisherName);
  };

  const handleGradeClick = (grade) => {
    setSelectedGrade(grade);
  };

  const handleNextClick = () => {
    if (selectedPublisher && selectedGrade) {
      navigate(`/tw/unit-reading/${selectedPublisher}/${selectedGrade}`);
    }
  };

  return (
    <div>
      <PublisherContainer>
        {publishers.map(publisher => (
          <PublisherGroup key={publisher.id}>
            <PublisherButton 
              onClick={() => handlePublisherClick(publisher.id)} 
              selected={selectedPublisher === publisher.id}
            >
              <img 
                src={selectedPublisher === publisher.id ? publisher.selectedImageUrl : publisher.imageUrl} 
                alt={publisher.name} 
              />
            </PublisherButton>
            <PublisherName>{publisher.name}</PublisherName>
          </PublisherGroup>
        ))}
      </PublisherContainer>
      <GradeContainer>
        {grades.map(grade => (
          <GradeGroup key={grade.id}>
            <GradeButton 
              onClick={() => handleGradeClick(grade.id)} 
              selected={selectedGrade === grade.id}
              disabled={['grade8', 'grade9'].includes(grade.id)} 
            >
              <img 
                src={selectedGrade === grade.id ? grade.selectedImageUrl : grade.imageUrl} 
                alt={grade.name} 
              />
            </GradeButton>
            <GradeName>{grade.name}</GradeName>
          </GradeGroup>
        ))}
      </GradeContainer>
      <NextButton onClick={handleNextClick}>Next</NextButton>
    </div>
  );
};

export default PublisherList;
