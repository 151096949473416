import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <p>© {new Date().getFullYear()} [AILearningBar]. All rights reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
