import React from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    return (
        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
            m: 2, 
            mb: 5 // Add margin bottom 
            }}
        >
              <h1>Privacy Policy</h1>
            <h2>Personal Information We Collect</h2>
            <p>
                We collect various personal information regarding you or your device. This includes the 
                following:
                <ul>
                <li>Your web browser type and version.</li>
                <li>Your operating system.</li>
                <li>Your internet service provider.</li>
                <li>Your IP address.</li>
                <li>The date and time of your visit.</li>
                <li>Your geographic location.</li>
                </ul>
            </p>

            <h2>How We Use Your Personal Information</h2>
            <p>
                We use the personal information we collect to deliver our services to you. We also use it 
                to detect and prevent fraud, to personalize your experience, and to analyze how our website 
                is used.
            </p>

            <h2>Third-Party Services</h2>
            <p>
                We do not sell your personal information to third parties. However, we use third-party 
                services for website analytics. These services may access our data solely for the purpose 
                of performing specific tasks on our behalf. We do not share any personally identifying 
                information with them without your explicit consent. We do not give them permission to 
                disclose or use any of our data for any other purpose.
            </p>

            <h2>Cookies</h2>
            <p>
                We use "cookies" to collect information about you and your activity across our site. A cookie 
                is a small piece of data that our website stores on your computer, and accesses each time you 
                visit, so we can understand how you use our site. This helps us serve you content based on 
                preferences you have specified.
            </p>

            <h2>Changes To This Policy</h2>
            <p>
                At our discretion, we may change our privacy policy to reflect current acceptable practices. 
                We will take reasonable steps to let users know about changes via our website. Your continued 
                use of this site after any changes to this policy will be regarded as acceptance of our 
                practices around privacy and personal information.
            </p>

            <h2>Contact Us</h2>
            <p>
                For any questions or concerns regarding your privacy, you may contact us using the 
                following details:
                <br/>
                ailearningbar.com
                <br/>
                chinghua.fan@gmail.com
            </p>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </Box>
    );
}

export default PrivacyPolicy;
