import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import ConsentForm from './ConsentForm'

import googleLogo from '../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png'; // you should replace 'path-to-google-logo.png' with the path to the actual Google logo
import default_User from '../assets/default_user_icon.png'
import jwt_decode from "jwt-decode";

// import jwt from 'jsonwebtoken'

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const SignInTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--green-500);
  font-weight: bold;
`;

const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignInInput = styled.input`
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px;
`;

const SignInButton = styled.button`
  width: 200px;
  height: 40px;
  background-color: var(--green-500);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--green-700);
  }
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357ae8;
  }
`;

const GoogleLogo = styled.img`
  height: 50px;
  width: auto;
`;

const SeparatorLine = styled.div`
  width: 50px;
  height: 1px;
  background-color: var(--green-500);
  margin: 0 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  width: 200px;
  height: 40px;
  background-color: #ccc;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #999;
  }
`;

const ORText = styled.span`
  font-weight: bold;
  color: var(--green-500);

  &.hoverable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const HintText = styled.span`
  font-size: 12px;
  color: #666;
  margin-top: 5px;
`;

const SignInPage = () => {
    const location = useLocation();
    const navigate = useNavigate(); // useNavigate hook
    const [hasConsent, setHasConsent] = useState(false);
    const [isRegistration, setIsRegistration] = useState(false);

    const query = new URLSearchParams(location.search);
    const record = query.get('record');

    const { isLogin, setLoginStatus, userOauthInfo, setOauthUserInfo, selectedLevel, setSelectedLevel, FetchError, setFetchError, startLoading, finishLoading, showMessage } = useContext(AuthContext);

    const handleSignIn = async (event) => {
        event.preventDefault();
        // Handle sign-in logic here

        const email = event.target[0].value;
        const password = event.target[1].value;
        const fetch_url = 'https://us-central1-writingai-380014.cloudfunctions.net/login'
    
        const headers = {
            'Content-Type': 'application/json',
            'Origin': 'https://alice-language.netlify.app/'
        };
        
        const data = {
            email: email,
            password: password
        };
    
        try {
            startLoading("Validating your credentials. Please wait a moment...");
            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            });
            
            const responseData = await response.json();
    
            finishLoading();
            console.log('sign in responseData - ', responseData)

            if (response.status === 200) {
                console.log('Login successful');
                localStorage.setItem('token', responseData.message.token);
                var decodedToken = jwt_decode(responseData.message.token);

                console.log('decodedToken - ', decodedToken)
                setLoginStatus(true);
                const user = {
                    'email':decodedToken.email,
                    'id':decodedToken.email,
                    'level': decodedToken.level,
                    logged_in: true,
                    'name': decodedToken.name,
                    'picture': decodedToken.picture
                };

                console.log('sign in user - ', user)
                setOauthUserInfo(user);
                const redirectTo = sessionStorage.getItem('redirect');
                if (redirectTo) {
                    navigate(redirectTo); // Navigate to the specified URL
                    sessionStorage.removeItem('redirect'); // Clear the redirect URL from session storage
                } else {          
                  navigate('/dashboard' + (query===null?'':'?'+query));
                }
            } else {
                console.log('Login failed');
                setLoginStatus(false);
                showMessage('Login failed');
            }
    
        } catch (error) {
            console.log('Error occurred during sign in:', error);
            setLoginStatus(false);
            finishLoading();
        }
    
    };

    const handleRegistration = async (event) => {
      const fetch_url = 'https://us-central1-writingai-380014.cloudfunctions.net/registration'
      // const post_auth_redirect_uri = encodeURIComponent(`http://localhost:3000/dashboard?record=WritingQuizAnswer`);  // Redirect URL after successful authentication
      const post_auth_redirect_uri = encodeURIComponent(`https://ailearningbar.com/dashboard?record=WritingQuizAnswer`);  // Redirect URL after successful authentication
      event.preventDefault();
        // Handle registration logic here
        // Get the user's alias, email, and password
        startLoading("We are creating your account. Please wait a moment...");
        const aliasName = event.target[0].value;
        const email = event.target[1].value;
        const password = event.target[2].value;
        // const fetch_url = fetch(`${fetch_url}?state=${post_auth_redirect_uri}`)
        // const fetch_url = 'https://us-central1-writingai-380014.cloudfunctions.net/registration?state=https:ailearningbar.com/dashboard'
    
        const headers = {
          'Content-Type': 'application/json',
          'Origin': 'https://alice-language.netlify.app/'
        };
          
        const data = {
          id: email,
          email: email,
          password: password, // add this line to include the password
          name: aliasName,  // You may want to modify this if you have a separate field for the user's name
          picture: default_User,  // You may want to modify this if you have a picture for the user
        };
    
        const response = await fetch((`${fetch_url}?state=${post_auth_redirect_uri}`), {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          mode: 'cors'
        });
  
        console.log('http request response', response)
        finishLoading()
    
        // Check the response
        if (response.status === 200) {
            const account_response = await response.json();
            console.log('http request account_response', account_response)
            if (account_response.message.status === "301") {
              // Handle what should happen after successful registration here
              console.log('Registration successful');
              // Store the token if necessary
              localStorage.setItem('token', account_response.message.token);
              setSelectedLevel('A1 Beginner')
              setLoginStatus(true);
              const user = {'email':data.email, 'id':data.email, 'level': 'A1 Beginner', logged_in: true, 'name': data.aliasName, 'picture': default_User}
              setOauthUserInfo(user)
              // You may also want to navigate to the dashboard
              const redirectTo = sessionStorage.getItem('redirect');
              if (redirectTo) {
                navigate(redirectTo); // Navigate to the specified URL
                sessionStorage.removeItem('redirect'); // Clear the redirect URL from session storage
              } else {          
                navigate('/dashboard' + (query===null?'':'?'+query));
              }
          } else {
              // Handle what should happen if the user already exists
              console.log('User already exists');
              showMessage('User already exists')

          }
      } else if (response.status === 409) {
        console.log('User already exists');
        showMessage('User already exists')
      } else {
          console.log('Registration failed');
          setLoginStatus(false);
      }
    };
    
    
    const handleGoogleLogin = () => {
        // Handle Google login logic here
        // const fetch_url = 'https://usermanager-8jxrzavs.uc.gateway.dev/login';
        const record_param = 'record=' + (record===null || record===undefined)?'':record['record'];
        console.log('handleGoogleLogin record_param - ', record_param)
        const stateParam = `${record_param}`; // Encode your parameters here

        const fetch_url = 'https://us-central1-writingai-380014.cloudfunctions.net/login'
        let post_auth_redirect_uri = ''  // Redirect URL after successful authentication
        // let post_auth_redirect_uri = encodeURIComponent(`https://ailearningbar.com/dashboard?record=WritingQuizAnswer`);  // Redirect URL after successful authentication
        const redirectTo = sessionStorage.getItem('redirect');
        if (redirectTo) {
          // post_auth_redirect_uri = encodeURIComponent(`http://localhost:3000/tw/entrance-zh-tw`); // Navigate to the specified URL
          post_auth_redirect_uri = encodeURIComponent(`https://ailearningbar.com/tw/entrance-zh-tw`); // Navigate to the specified URL
          sessionStorage.removeItem('redirect'); // Clear the redirect URL from session storage
        } else {
          // post_auth_redirect_uri = encodeURIComponent(`http://localhost:3000/dashboard?record=WritingQuizAnswer`);
          post_auth_redirect_uri = encodeURIComponent(`https://ailearningbar.com/dashboard?record=WritingQuizAnswer`);
        }    

        const headers = {
          'Content-Type': 'application/json',
          'Origin': 'https://ailearningbar.com/'
        };
        (async () => {
          try {
            console.log('Login location - ', location)
            // Make a GET request to the login URL, not a POST request
            // const response = await fetch(fetch_url);
            const response = await fetch(`${fetch_url}?state=${post_auth_redirect_uri}`);  // Add 'state' parameter to the request
      
            const jsonData = await response.json();
      
            console.log('login response - ', jsonData)
            const authorization_url = jsonData.authorization_url
            // Redirect the user to the authorization URL
            window.location.href = authorization_url;
          } catch (error) {
            console.error('Error fetching data from server:', error);
          }
        })();

    };

    const handleCancel = () => {
        // Handle cancel logic here to go back to the home page
        navigate('/'); // Navigate to the home page
    };

    const toggleRegistration = () => {
      setIsRegistration(!isRegistration);
    };
  
    useEffect(() => {
      const userConsent = localStorage.getItem("userConsent");
      setHasConsent(userConsent === "true");
    }, []);
  
    const handleConsent = (isConsentGiven) => {
      console.log('handleConsent isConsentGiven - ', isConsentGiven)
      if (isConsentGiven === true) {
        localStorage.setItem("userConsent", isConsentGiven);
        setHasConsent(isConsentGiven);  
      } else {
        navigate("/")
      }
    };
  
    if (!hasConsent) {
      return <ConsentForm onConsent={handleConsent} />;
    }
  
    return (
      <SignInContainer>
        { !hasConsent ? 
          (
            <ConsentForm onConsent={handleConsent} />
          )
          :
          (
            <>
              <SignInTitle>{isRegistration ? 'Create an Account' : 'Login to 愛學霸'}</SignInTitle>
              <SignInForm onSubmit={isRegistration ? handleRegistration : handleSignIn}>
                {isRegistration && (
                  <>
                    <SignInInput type="text" placeholder="Alias Name" />
                    <HintText>Enter your alias name</HintText>
                  </>
                )}
                <SignInInput type="email" placeholder="Email" />
                <SignInInput type="password" placeholder="Password" />
                <ButtonContainer>
                  <SignInButton type="submit">{isRegistration ? 'Register' : 'Sign In'}</SignInButton>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                </ButtonContainer>
              </SignInForm>
              <ButtonContainer>
                <SeparatorLine />
                <ORText>OR</ORText>
                <SeparatorLine />
              </ButtonContainer>
              <GoogleLoginButton onClick={handleGoogleLogin}>
                <GoogleLogo src={googleLogo} alt="Google logo" />
              </GoogleLoginButton>
              <ButtonContainer>
                <ORText className={isRegistration ? '' : 'hoverable'} onClick={toggleRegistration}>
                    {isRegistration ? 'Login with existing account' : 'Create an Account'}
                </ORText>
              </ButtonContainer>
            </>
          )
        }
      </SignInContainer>
    );    
};

export default SignInPage;
