import React, { useState, useRef, useEffect, Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import TextWithLineBreaks from './TextWithLineBreaks';
import Draggable from 'react-draggable';
import WordBank from './WordBank';
import Answer from './Answer';
import Dashboard from './Dashboard';
import AuthContext from '../contexts/AuthContext';
import feedbackToScore from './feedbackToScore';
import '../App.css';  // Assuming your App.css file is in the same directory level

import styled from 'styled-components';

const QuizContainer = styled.div`
    border: 2px solid var(--green-500);
    margin: 1rem;
    width: 90%;
    padding: 1rem;
`;

const TaskDescription = styled.div`
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 18px;
`;

const Instruction = styled.div`
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid black;
    padding: 1rem;
    white-space: pre-wrap;
    text-align: left;
`;

const UserInput = styled.textarea`
    width: 100%;
    height: 100px;
    resize: vertical;
    overflow: auto;
    margin-bottom: 1rem;
    font-size: 18px;
`;

const SubmitButton = styled.div`
  padding: 0.5rem 1rem;
  background-color: var(--green-500);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--green-600);
  }
`;

const DashboardButton = styled.div`
  padding: 0.5rem 1rem;
  background-color: var(--green-500);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--green-600);
  }
`;

function WritingQuizPage() {

    ReactGA.pageview('/WritingQuizPage');
    const navigate = useNavigate();

    const writing_answer_json  = {}

    const [submitted, setSubmitted] = useState(false);
    const [isDashboardVisible, setIsDashboardVisible] = useState(false);
    const [writing_answers, setWritingAnswers] = useState([])
    const [writing_task_with_ids, setWritingTaskWithIds] = useState([]);
        
    const [writing_task, setWritingTask] = useState({})
    const [selectedTab, setSelectedTab] = useState(null);

    const [ExerciseTypeIndex, setExerciseTypeIndex] = useState(0);
    const [taskIndex, setTaskIndex] = useState(0);
    const [answers, setAnswers] = useState(null);
    const [user_input, setUserInput] = useState(null)
    const [word_confirmed, setWordConfirm] = useState("")
    const [element, setElement] = useState(null)
    const [userInputs, setUserInputs] = useState([]);
    const [answerFeedback, setAnswerFeedback] = useState(null)
    const { isLogin, setLoginStatus, userOauthInfo, setOauthUserInfo, selectedLevel, setSelectedLevel, startLoading, finishLoading, setFetchError } = useContext(AuthContext);

    // Add an answer element
    const addAnswerElement = (newAnswerElement) => {
        // if (userOauthInfo === null) {
        //     return
        // }
        setAnswerFeedback(prevState => {
            if(prevState){
                console.log('setAnswerFeedback prevState', prevState)
                return {
                    ...prevState,
                    task: [...prevState.task, newAnswerElement]
                };
            }
            return {
                user_id: (userOauthInfo?userOauthInfo.id: undefined),
                task: [newAnswerElement]
            };
        });
    }
    // Update an answer element by ID
    const updateAnswerElementById = (id, updatedResponse) => {
        console.log('ready to updateAnswerElementById userOauthInfo - ', userOauthInfo)
        // if (userOauthInfo === null) {
        //     return
        // }
        const scores = feedbackToScore(updatedResponse);

        setAnswerFeedback(prevState => ({
            ...prevState,
            task: prevState.task.map(task =>
                task.ID === id ? {...task, response: {...task.response, 
                    "Adherence Feedback": updatedResponse["Adherence Feedback"], 
                    "Assessment": updatedResponse["Assessment"], 
                    "Clarity Feedback": updatedResponse["Clarity Feedback"], 
                    "Grammar Feedback": updatedResponse["Grammar Feedback"],
                    "Positive Feedback": updatedResponse["Positive Feedback"],
                    "Vocabulary Feedback": updatedResponse["Vocabulary Feedback"], 
                    "Sample Answer": updatedResponse["Sample Answer"], 
                    "Score": {
                        "Grammar":scores["Grammar Feedback"],
                        "Vocabulary":scores["Vocabulary Feedback"],
                        "Clarity":scores["Clarity Feedback"],
                        "Adherence":scores["Adherence Feedback"]
                    }
                }} : task
            ),
        }));
    }

    // Update the feedback fields of an answer element by ID
    const updateFeedbackFieldsById = (id, updatedResponse) => {
        setAnswerFeedback((prevState) => ({
        ...prevState,
        task: prevState.task.map((task) =>
            task.ID === id
            ? {
                ...task,
                response: {
                    ...task.response,
                    "Adherence Feedback": updatedResponse["Adherence Feedback"],
                    "Assessment": updatedResponse["Assessment"],
                    "Clarity Feedback": updatedResponse["Clarity Feedback"],
                    "Grammar Feedback": updatedResponse["Grammar Feedback"],
                    "Positive Feedback": updatedResponse["Positive Feedback"],
                    "Vocabulary Feedback": updatedResponse["Vocabulary Feedback"],
                    "Sample Answer": updatedResponse["Sample Answer"],
                },
                }
            : task
        ),
        }));
    };
    
    // Update the score field of an answer element by ID
    const updateScoreFieldById = (id, scores) => {
        // const scores = feedbackToScore(updatedResponse);
    
        setAnswerFeedback((prevState) => ({
        ...prevState,
        task: prevState.task.map((task) =>
            task.ID === id
            ? {
                ...task,
                response: {
                    ...task.response,
                    "Score": {
                        "Grammar": scores["Grammar"],
                        "Vocabulary": scores["Vocabulary"],
                        "Clarity": scores["Clarity"],
                        "Positive": scores["Positive"],
                        "Adherence": scores["Adherence"],
                    },
                },
                }
            : task
        ),
        }));
    };
  
        
    // Clear all answer elements
    const clearAll = () => {
        setAnswerFeedback(null);
    }
    
    // const wordRef = useRef(null);
    async function fetchWritingTask() {
        // return setWritingTask(writing_prompt)
        startLoading("Please wait while the AI is generating your Writing Contest questions...")
        const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
        const data = {
          'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
          "type":"Writing",
          "level":selectedLevel,
          'message': ""
        };
      
        const headers = {
          'Content-Type': 'application/json',
          'Origin': 'https://alice-language.netlify.app/'
        };
      
        try {
          const response = await fetch(fetch_url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
            mode: 'cors'
          });
  
          console.log('http request headers', headers)
      
          const article_data = await response.json();
          // const article_data = sample_element
          console.log('fetchArticle article_data - ', article_data)
        //   setWritingTask(article_data)
          setWritingTask(article_data)
          finishLoading()
        } catch (error) {
            finishLoading()
            console.error('Error fetching data from server:', error);
            setFetchError(error)
        }
    }
     
    // Function to handle tab selection.
    const handleTabClick = (tab) => {
        console.log('handleTabClick - ', tab)
        console.log('handleTabClick writing_task.Tasks - ', writing_task_with_ids.Tasks)
        console.log('handleTabClick writing_task.Tasks.findIndex(task => task.ExerciseType === tab) - ', writing_task_with_ids.Tasks.findIndex(task => task.ExerciseType === tab))
        setElement(null)
        setSelectedTab(tab);
        setExerciseTypeIndex(writing_task_with_ids.Tasks.findIndex(task => task.ExerciseType === tab));
        setTaskIndex(0);
        setUserInput(null)
        setWordConfirm("")
    };

    
    const saveAnswer = () => {
        setAnswers(prevAnswers => {
            const newAnswers = [...prevAnswers];
            if (writing_task_with_ids.Tasks[ExerciseTypeIndex].ExerciseType === 'FillInTheBlanks') {
                newAnswers[ExerciseTypeIndex][taskIndex] = Array.from(document.querySelectorAll('.answer-input')).map((input) => input.value);
            } else {
                newAnswers[ExerciseTypeIndex][taskIndex] = document.getElementById('answer-input').value===undefined?'':document.getElementById('answer-input').value;
            }
            return newAnswers;
        });
    };

    const getAnswerItem = (element_id) => {
        console.log('getAnswerItem in element_id - ', element_id)
        console.log('getAnswerItem in writing_answers - ', writing_answers)
        var sample = null
        if (writing_answers.length <= 0) {
            return null
        }
        sample = writing_answers.find((item) => item.ID === element_id);
        console.log('element_id - ' + element_id + ', sample - ', sample)
        return sample
        // return writing_answer_json.findIndex((item) => {
        //     console.log('getAnswerItem item - ', item)
        //     const [_, x, y] = item.ID.match(/T(\d+)I(\d+)/);
        //     return a === parseInt(x) && b === parseInt(y);
        // });
    }
    
    const handlePreviousClick = () => {
        if (taskIndex > 0) {
            saveAnswer();
            setTaskIndex(taskIndex - 1);
        } else if (ExerciseTypeIndex > 0) {
            saveAnswer();
            setExerciseTypeIndex(ExerciseTypeIndex - 1);
            setTaskIndex(writing_task.Tasks[ExerciseTypeIndex - 1].Items.length - 1);
        }
    };
    
    const handleNextClick = () => {
        if (taskIndex < writing_task.Tasks[ExerciseTypeIndex].Items.length - 1) {
            saveAnswer();
            setTaskIndex(taskIndex + 1);
        } else if (ExerciseTypeIndex < writing_task.Tasks.length - 1) {
            saveAnswer();
            setExerciseTypeIndex(ExerciseTypeIndex + 1);
            setTaskIndex(0);
        }
    };
    
    // const element = null;

    useEffect(() => {
        console.log('ready to get writing task')
        fetchWritingTask()
    }, [])

    useEffect(() => {
        if (writing_task && writing_task.Tasks) {
            const tasksWithId = writing_task.Tasks.map((task, taskIndex) => {
                return {
                    ...task,
                    Items: task.Items.map((item, itemIndex) => {
                        const id = `${Date.now()}-T${task.ExerciseType}I${itemIndex}`;
                        return { ...item, id };
                    }),
                };
            });
            
            setWritingTaskWithIds({
                ...writing_task,
                Tasks: tasksWithId
            });
            console.log('writing_task - ', writing_task)
    
            setUserInputs(writing_task.Tasks.map(task => task.Items.map(() => '')));
            setSelectedTab(writing_task.Tasks[0].ExerciseType)
            setAnswers(writing_task.Tasks.map(task => task.Items.map(() => '')))
        }
    }, [writing_task]);    

    useEffect(() => {
        // if (isLogin) {
        console.log('answerFeedback - ', answerFeedback)
        console.log('ready to updateAnswerElementById userOauthInfo - ', userOauthInfo)
        if (answerFeedback !== null) {
            const allCompleted = answerFeedback.task.every(
                (task) => task.response && task.response.Score
            );
            console.log('answerFeedback allCompleted - ', allCompleted)
            if (allCompleted) {
                // All data is filled and completed, call the saveDataToCloudFunction
                saveDataToCloudFunction();
            }    
        }
            

    }, [answerFeedback])

    useEffect(() => {
        console.log('userInputs - ', userInputs)
    }, [userInputs])

    useEffect(() => {
        console.log('ready to writing_answers length - ', writing_answers.length)
        // if (writing_answers !== undefined && writing_answers.length > 0) {
        //     saveDataToCloudFunction()
        // }
    }, [writing_answers])
    
    useEffect(() => {
        if (submitted === true) {
            saveAnswer(answerFeedback)
        }

    }, submitted)

    useEffect(() => {
        console.log('ExerciseTypeIndex useEffect, ExerciseTypeIndex - ' + ExerciseTypeIndex)
        if (writing_task_with_ids.Tasks !== undefined) {
            if (writing_task_with_ids.Tasks[ExerciseTypeIndex].ExerciseType === 'FillInTheBlanks') {
                const inputs = document.querySelectorAll('.answer-input');
                inputs.forEach((input, index) => {
                    input.value = answers[ExerciseTypeIndex][taskIndex] ? answers[ExerciseTypeIndex][taskIndex] : '';
                });
            } else {
                const answerInput = document.getElementById('answer-input');
                // answerInput.value = answers[ExerciseTypeIndex][taskIndex] || '';
            }    
            console.log('ready to set taskIndex - ' + taskIndex +' task element - ', writing_task_with_ids.Tasks[ExerciseTypeIndex].Items[taskIndex])
            setElement(writing_task_with_ids.Tasks[ExerciseTypeIndex].Items[taskIndex])
        }
    }, [ExerciseTypeIndex, taskIndex, answers]);
      
    const renderFillInTheBlanksTask = (task) => {
        const parts = task.split(/(_+)|(:)/);
        return parts.map((part, index) => {
            if (!part) return null;
            if (part.startsWith('_')) {
                return (
                    <input
                        key={index}
                        type='text'
                        className='answer-input'
                        style={{
                            width: '100px',
                            border: '1px solid black',
                            textAlign: 'center',
                        }}
                        onClick={(e) => e.target.focus()}
                    />
                );
            } else if (part === ':'){
                return (
                    <React.Fragment key={index}>
                        {part}
                        <br />
                    </React.Fragment>
                );    
            } else {
                return <React.Fragment key={index}>{part}</React.Fragment>;
            }
        });
    };

    async function fetchWritingScore(allAnswers) {
        startLoading("The AI is now calculating your scores based on your answers. Please wait...");

        const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
        console.log("fetchWritingScore answerFeedback - ", allAnswers)
        var feedback_items = []
        allAnswers.forEach(feedback => {
            const item = {"task_id": feedback.ID, "feedback" :{
                "Adherence Feedback": feedback["Adherence Feedback"],
                "Clarity Feedback": feedback["Clarity Feedback"],
                "Grammar Feedback": feedback["Grammar Feedback"],
                "Positive Feedback": feedback["Positive Feedback"],
                "Vocabulary Feedback": feedback["Vocabulary Feedback"]
            }}
            feedback_items.push(item)
        })

        const scores = {
            "user_id": userOauthInfo===null?'':userOauthInfo.id,
            "level":selectedLevel,
            "feedbacks": feedback_items
        }
        const data = {
          'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
          "type":"Score",
          "level":selectedLevel,
          'content': scores
        };

        console.log('fetchWritingScore data - ', JSON.stringify(data))
        if (data == null) {
            return
        }
        const headers = {
            'Content-Type': 'application/json',
            'Origin': 'https://alice-language.netlify.app/'
          };

        try {
            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        
            console.log('http request headers', headers)
        
            const answers = await response.json();
            console.log('get answers - ', answers)
            answers.scores.forEach(feedback => {
                updateScoreFieldById(feedback.task_id, feedback.score)
            });
            console.log('fetchArticle writing answers - ', answers)    
            // setWritingAnswers(answers)

            finishLoading()
            setSubmitted(true); // Move the setSubmitted function here so it only changes to true if the fetch was successful.
        } catch (error) {
            finishLoading()
            console.error('Error fetching data from server:', error);
            setIsDashboardVisible(false)
            setSubmitted(false)
            setFetchError(`Error fetching data from server: ${error}`);
        }

    }

    async function fetchWritingAnswer(user_response) {
        startLoading("Please wait while the AI is reviewing your Writing Test answers...");
        const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
        const data = {
          'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
          "type":"answer",
          "level":selectedLevel,
          'answer': user_response
        };
      
        const headers = {
          'Content-Type': 'application/json',
          'Origin': 'https://alice-language.netlify.app/'
        };
      
        try {
            const response = await fetch(fetch_url, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
              mode: 'cors'
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
      
            console.log('http request headers', headers)
        
            const answers = await response.json();
            console.log('get answers - ', answers)
            answers.forEach(feedback => {
                updateFeedbackFieldsById(feedback.ID, feedback)
                // updateAnswerElementById(feedback.ID, feedback);
            });

            console.log('fetchArticle writing answers - ', answers)    
            
            finishLoading()
            setWritingAnswers(answers)
            fetchWritingScore(answers)
            setSubmitted(true); // Move the setSubmitted function here so it only changes to true if the fetch was successful.
        } catch (error) {
            finishLoading()
            console.error('Error fetching data from server:', error);
            setIsDashboardVisible(false)
            setSubmitted(false)
            setFetchError(`Error fetching data from server: ${error}`);
        }
    }

    const saveDataToCloudFunction = () => {
        const url = 'https://us-central1-writingai-380014.cloudfunctions.net/save_answer_data';
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
        console.log('saveDataToCloudFunction answerFeedback - ', answerFeedback)
        const user_answers = {
            user_id: userOauthInfo?userOauthInfo.id: undefined,
            task_id: date,
            task: answerFeedback?answerFeedback['task']:null,
        };
        console.log('saveDataToCloudFunction - ', user_answers)

        if (userOauthInfo) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'https://ailearningbar.com'
                },
                body: JSON.stringify(user_answers),
                })
                .then(response => response.json())
                .then(data => console.log(data))
                .catch((error) => {
                console.error('Error:', error);
                });
    
        } else {
            console.log('writing page saveDataToCloudFunction - ', user_answers)
            sessionStorage.setItem('WritingQuizAnswer', JSON.stringify(user_answers))
            console.log('writing page sessionStorage - ', JSON.parse(sessionStorage.getItem('WritingQuizAnswer')))

        }
      
    }
    
    const handleSubmitClick = async () => {
        console.log('userInputs - ', userInputs)
        let student_result = []

        console.log('handleSubmitClick userOauthInfo - ', userOauthInfo)

        writing_task_with_ids.Tasks.forEach((exercise, exerciseIdx) => {
            console.log('ExerciseType index - ' + exerciseIdx + ' , exercise = ', exercise)
            let newExercise = {
                'ExerciseType': exercise.ExerciseType,
                'Expected Level': selectedLevel,
                'Items': []
            };
            exercise.Items.forEach((item, itemIdx) => {
                if (exercise.ExerciseType === "FillInTheBlanks") {
                    const inputs = document.querySelectorAll('.answer-input');
                    const userAnswers = Array.from(inputs).map((input) => input.value);
                    item.answer = item.Task.replace(/_+/g, (match, index) => userAnswers.shift());
                } else {
                    console.log(' - item -', item)
                    // let timestamp = new Date().getTime();
                    let newItem = {
                        'ID': item.id,
                        'Instructions': item.Instructions,
                        'Task': item.Task,
                        'creation_date': new Date(),
                        'Student response': userInputs[exerciseIdx][itemIdx]
                    };
                    newExercise.Items.push(newItem);
                    addAnswerElement(newItem)
                }
            });
            if(newExercise.Items.length > 0) {
                student_result.push(newExercise);
            }
        });
        
        console.log(JSON.stringify(student_result, null, 2));
        if (student_result.length > 0) {
            try {
                await fetchWritingAnswer(JSON.stringify(student_result, null, 2))
                setIsDashboardVisible(true); // Show the dashboard if the submission is successful
                setSubmitted(true); // Update the submission status
            } catch (error) {
                console.error('Error:', error);
                setIsDashboardVisible(false); // If there's an error, keep the dashboard hidden
                setSubmitted(false); // Update the submission status
            }
        }
    };

    const onDragStop = (dragWordElement) => {
        console.log('WritingQuizPage dragWordElement - ', dragWordElement)
        setUserInput(dragWordElement)
    }

    const confirmed_word = (word) => {
        console.log('WritingQuizPage word - ', word)
        setUserInput(null)
        setWordConfirm(word)
    }

    const handleKeyDown = (e, currentInputIndex) => {
        // If tab is pressed
        if (e.key === "Tab") {
            e.preventDefault();  // Prevent the default action
    
            // If shift is also pressed, go to the previous input field
            if (e.shiftKey) {
                focusInput(currentInputIndex - 1);
            } 
            // Otherwise, go to the next input field
            else {
                focusInput(currentInputIndex + 1);
            }
        }
    }

    const focusInput = (inputIndex) => {
        // Get the input field
        const input = document.getElementById(`input-${inputIndex}`);
        
        // If it exists, focus and scroll to it
        if (input) {
            input.focus();
            input.scrollIntoView({ behavior: "smooth" });
        }
    }
    
    
    return (
        <div
            id='writing-quiz-page'
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            {/* Tab bar */}
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', margin: '1rem'}}>
                {writing_task_with_ids===null?'':writing_task_with_ids.Tasks===undefined?'':writing_task_with_ids.Tasks.map(task => 
                    <div key={task.ExerciseType} onClick={() => handleTabClick(task.ExerciseType)} style={{cursor: 'pointer', fontWeight: selectedTab === task.ExerciseType ? 'bold' : 'normal'}}>
                        {task.ExerciseType}
                    </div>
                )}
            </div>
            <QuizContainer>
                <div id='task'>
                    {/* {writing_task.Tasks===undefined?'':writing_task.Tasks[ExerciseTypeIndex].ExerciseType === 'FillInTheBlanks' ? ( */}
                    {element===null?'':writing_task_with_ids.Tasks[ExerciseTypeIndex].ExerciseType === 'FillInTheBlanks' ? (
                        <>
                            <div id='task-description' style={{ border: '1px solid black', padding: '1rem', marginBottom: '1rem' }}>
                                {renderFillInTheBlanksTask(element.Task)}
                            </div>
                            {/* <div id='instruction' style={{ border: '1px solid black', padding: '1rem', marginBottom: '1rem', whiteSpace: 'pre-wrap' }}><TextWithLineBreaks text={element.Instructions} /></div> */}
                            <div id='sentences' style={{ border: '1px solid black', padding: '1rem', marginBottom: '1rem', whiteSpace: 'pre-wrap' }}>
                                {(element===undefined || element.Sentences===undefined)?'':element.Sentences.map((sentence, index) => {
                                    const answer = element.Answers[index];
                                    const parts = sentence.split(/(_+)/);
                                    return (
                                    <div key={index} style={{marginBottom:'3rem', marginTop:'1rem'}}>
                                        <div style={{ display: 'flex', flexDirection:'row'}}>
                                            {parts.map((part, index) => 
                                            part.includes("_")
                                            // If the part includes underscores, replace it with the Answer component
                                            ? <Answer 
                                                id={`input-${index}`}
                                                key={index} 
                                                element={answer} 
                                                underscore={part} 
                                                user_input={user_input} 
                                                confirm_callback={confirmed_word} 
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                            />
                                            // Otherwise, just render the part as text
                                            : part
                                            )}
                                        </div>
                                        {/* <div style={{display: 'flex', marginBottom:'2rem'}}>Answer: {answer}</div> */}
                                    </div>
                                    );
                                })}
                            </div>
                            <div id='word_bank' style={{ display: 'flex', border: '1px solid black', width: '98%', padding: '1rem', marginBottom: '1rem', whiteSpace: 'pre-wrap' }}>
                                <WordBank 
                                    element={element} 
                                    onDragStop={onDragStop} 
                                    confirmed={word_confirmed}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {element===null?'':writing_task_with_ids.Tasks[ExerciseTypeIndex].Items.map((element, itemIndex) => {
                                
                                console.log('ready to get answer element - ', element)
                                const answer_object = getAnswerItem(element.id)
                                return (
                                    <div style={{border: '1px solid black', padding: '1rem', marginBottom: '1rem', whiteSpace: 'pre-wrap'}}>
                                        <TaskDescription>{element.Task}</TaskDescription>
                                        <Instruction><TextWithLineBreaks text={element.Instructions} /></Instruction>
                                        
                                        <UserInput
                                            id="user-input"
                                            value={userInputs[ExerciseTypeIndex][itemIndex] || ''}
                                            onChange={e => {
                                                const newUserInputs = [...userInputs];
                                                newUserInputs[ExerciseTypeIndex][itemIndex] = e.target.value;
                                                setUserInputs(newUserInputs);
                                            }}
                                            placeholder="Your answer here..."
                                        />
                                        <div 
                                            id="chatgpt-correction"
                                            style={{
                                                border: '1px solid grey', 
                                                padding: '1rem', 
                                                whiteSpace: 'pre-wrap', 
                                                resize: 'vertical',
                                                overflow: 'auto',
                                                backgroundColor: '#e6e6e6',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <TextWithLineBreaks text={answer_object===null ?'':'Sample: ' + answer_object["Sample Answer"]} style={{
                                                width: '100%',
                                                marginBottom: '1rem'
                                            }}/>
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Adherence: ' + answer_object["Adherence Feedback"]} />
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Clarity: ' + answer_object["Clarity Feedback"]} />
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Grammar: ' + answer_object["Grammar Feedback"]} />
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Positive: ' + answer_object["Positive Feedback"]} />
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Vocabulary: ' + answer_object["Vocabulary Feedback"]} />
                                            <p></p>
                                            <TextWithLineBreaks text={answer_object===null ?'':'Assessment: ' + answer_object["Assessment"]} />
                                        </div>
                                    </div>
                                    )
                            })}

                            {/* <div id='instruction'>{element.Instructions}</div> */}
                        </>
                    )}
                </div>
            </QuizContainer>
            {/* {isDashboardVisible && (
            <div style={{position: 'absolute', top: '10%', left: '10%', zIndex: 1000}}>
                <Dashboard writingAnswerJson={writing_answer_json} />
                <button onClick={() => setIsDashboardVisible(false)}>Close Dashboard</button>
            </div>
            )} */}
            <div id='command-area' style={{ display: 'flex', justifyContent: 'space-between', width: '50%', margin: '1rem' }}>
                {!submitted && (
                    <SubmitButton onClick={handleSubmitClick}>Submit</SubmitButton>
                )}
                {submitted && (
                    <DashboardButton onClick={() => navigate('/dashboard?record=WritingQuizAnswer')}>
                        Dashboard
                    </DashboardButton>
                )}
            </div>
        </div>
    )
}

export default WritingQuizPage;