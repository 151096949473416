import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import TextWithLineBreaks from './TextWithLineBreaks';

const StyledCard = styled(Card)`
    margin: 10px 0;
`;

const FeedbackTitle = styled(Typography)`
    font-weight: bold;
    margin-bottom: 10px;
`;

const FeedbackScore = styled(Typography)`
    color: ${({ score }) => (score >= 3 ? 'green' : score === 1 ? 'red' : 'orange')};
    font-weight: bold;
    margin-top: 10px;
`;

const FeedbackCard = ({ title, comment, suggestion, score }) => (
    <StyledCard>
        <CardContent>
            <FeedbackTitle variant="h6">{title}</FeedbackTitle>
            <Typography align="left" variant="body2"><TextWithLineBreaks text={comment} fontSize="18px" /></Typography>
            <Typography align="left" variant="body2"><TextWithLineBreaks text={suggestion} fontSize="18px" /></Typography>
            <FeedbackScore score={score} variant="body1">Score: {score}</FeedbackScore>
        </CardContent>
    </StyledCard>
);

export default FeedbackCard;
