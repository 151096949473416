import React, { useState, useEffect, useContext, useImperativeHandle, useRef, Context } from "react";
import axios from 'axios';
import AuthContext from '../contexts/AuthContext';
import FeedbackCard from "./FeedbackCard";
import { Link, TextField, Button, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { Description } from "@material-ui/icons";
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const EntryExamWriting = () => {
  const exam_dataset = {
    "exam": {
      "year": "112",
      "subject": "English Composition",
      "topic": "Emoji",
      "tasks": [
        {
          "id": 1,
          "type": "Writing",
          "instructions": {
            "main": "不同的公園，可能樣貌不同，特色也不同。請以此為主題，並依據下列兩張圖片的內容，寫一篇英文作文，文分兩段。第一段描遂圖A和圖B中的公園各有何特色，第二段則說明你心目中理想公園的樣貌與特色，並解釋你的理由。",
            "emojis": [
                {
                    "image": "https://storage.googleapis.com/site_resource/upload_204c0063df4bae5d88132505b5cc04f3.jpg",
                    "description":  "A部分是一個兒童遊樂場的圖片，其中有滑梯、攀爬架和其他遊樂設施。這些設備通常是用來讓小孩子爬上爬下和滑行玩耍的。遊樂場的背景似乎是開闊的天空，給人一種開放空間的感覺。",
                    "alt": "A部分是一個兒童遊樂場的圖片，其中有滑梯、攀爬架和其他遊樂設施。這些設備通常是用來讓小孩子爬上爬下和滑行玩耍的。遊樂場的背景似乎是開闊的天空，給人一種開放空間的感覺。"
                },
                {
                    "image": "https://storage.googleapis.com/site_resource/upload_204c0063df4bae5d88132505b5cc04f3.jpg",
                    "description":  "B部分則是一個公園的景象，可以看到茂密的樹木和整齊的草地。公園看起來非常寬敞，樹木之間有適合散步的小路。整體上，這個場景給人一種平靜和自然的感覺。",
                    "alt": "B部分則是一個公園的景象，可以看到茂密的樹木和整齊的草地。公園看起來非常寬敞，樹木之間有適合散步的小路。整體上，這個場景給人一種平靜和自然的感覺。"
                }
            ]
          }
        }
      ]
    }
  }      
  
    // const exam_dataset = {
    //     "exam": {
    //       "year": "112",
    //       "subject": "English Composition",
    //       "topic": "Emoji",
    //       "tasks": [
    //         {
    //           "id": 1,
    //           "type": "Writing",
    //           "instructions": {
    //             "main": "隨著社群媒體的普及，表情符號（emoji）的使用也極為普遍。請參考下列表情符號，寫一篇英文作文，文分兩段。第一段說明人們何以喜歡使用表情符號，並從下列的表情符號中舉一至二例，說明表情符號在溝通上有何功能。第二段則以個人或親友的經驗為例，討論表情符號在訊息表達或解讀上可能造成的誤會或困擾，並提出可以化解的方法。",
    //             "emojis": [
    //                 {
    //                     "image": "https://storage.googleapis.com/site_resource/smiling-face-with-open-mouth-and-smiling-eyes.png",
    //                     "description":  "Smiling Face With Open Mouth",
    //                     "alt": "Smiling Face With Open Mouth"
    //                 },
    //                 {
    //                     "image": "https://storage.googleapis.com/site_resource/rolling-on-the-floor-laughing.png",
    //                     "description":  "Rolling on the Floor Laughing Face",
    //                     "alt": "Rolling on the Floor Laughing Face"
    //                 },
    //                 {
    //                     "image": "https://storage.googleapis.com/site_resource/angry-face.png",
    //                     "description":  "Angry Faces",
    //                     "alt": "Angry Faces"
    //                 },
    //                 {
    //                     "image": "https://storage.googleapis.com/site_resource/smiling-face-with-heart-eyes.png",
    //                     "description":  "Smiling Face With Heart-Shaped Eyes",
    //                     "alt": "Smiling Face With Heart-Shaped Eyes"
    //                 }
    //             ]
    //           }
    //         }
    //       ]
    //     }
    //   }      
      

    const [showFeedback, setShowFeedback] = useState(false);
    const [task, setTask] = useState(null);
    const [instruction, setInstruction] = useState(null);
    const [userResponse, setUserResponse] = useState("");
    const [reviewFeedback, setReviewFeedback] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const userResponseRef = useRef(null);
    const feedbackRef = useRef(null);

    const { startLoading, finishLoading, setFetchError, defaultLanguage } = useContext(AuthContext);


    const { topic, tasks } = exam_dataset.exam;
    const { main, emojis } = tasks[0].instructions;

    const handleSubmit = async () => {  
      console.log('userResponse - ', userResponse)
      if(userResponse.trim() === "") {
        alert("User response is required!");
        return;
      }

      if (window.gtag) {
        window.gtag('event', 'blog_task_submit', {
          'event_category': 'button_click',
          'event_label': 'blog_task_handleSubmit',
          // Send the userResponse as the event value
          'value': task===null?'':task
        });
      }
      setIsSubmit(true);
    };

    const createInstructionMessage = (main, emojis) => {
      const emojiDescriptions = emojis.map(emoji => {
          return `${emoji.description} (${emoji.image})`;
      }).join(', ');
  
      return `${main} Emojis to reference: ${emojiDescriptions}`;
    }

    const fetchUETScore = async () => {
      const instructionMessage = createInstructionMessage(exam_dataset.exam.tasks[0].instructions.main, exam_dataset.exam.tasks[0].instructions.emojis);
      console.log('defaultLanguage - ', defaultLanguage)
      console.log('exam_dataset_topic - ', exam_dataset['exam']['topic'])
      console.log('instructionMessage - ', instructionMessage)

      let bilingualLanguage = sessionStorage.getItem('defaultLanguage')
      // "language": defaultLanguage === 'zh-TW' ? 'Taiwanese' : (defaultLanguage === 'zh-CN' ? 'Chinese' : (defaultLanguage === 'ja' ? 'Japanese' : 'English')),
      startLoading("Please wait while the AI to review your answer...")
      const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/ut';
      const data = {
        'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
        "type":"UET-Score",
        "language" : bilingualLanguage,
        "topic": exam_dataset['exam']['topic'],
        "instruction": instructionMessage,
        "content": userResponse
      };
  
      const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://alice-language.netlify.app/'
      };
  
      console.log('fetchAnswer data - ', data)
      try {
        const response = await fetch(fetch_url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          mode: 'cors'
        });
    
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const article_data = await response.json();
  
        console.log('fetchAnswer article_data - ', article_data)
        setReviewFeedback(article_data)
  
        finishLoading()
      } catch (error) {
          finishLoading()
          console.error('Error fetching data from server:', error);
          setFetchError(error)
      }
  
    }
    
    useEffect(() => {
      if (isSubmit) {
        fetchUETScore();
      }
    }, [isSubmit]);

    useEffect(() => {
      console.log('reviewFeedback - ', reviewFeedback)
      setShowFeedback(true)
      if(feedbackRef.current) {
        feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
      }

    }, [reviewFeedback])

    useEffect(() => {
        userResponseRef.current.focus();
        setIsSubmit(false)
        setShowFeedback(false)
    }, []);
    
    return (
        <Paper style={{ padding: "1rem" }}>
          <Typography variant="h6">Topic: {topic}</Typography>
          <Alert severity="info" style={{ margin: '15px 0' }}>
              Note: 評分系統按照大考中心公布的學測英文作文評分原則，進行模擬。由GPT進行判斷與評分。若要了解更多，您可以參考<Link href="https://www.ceec.edu.tw/xcepaper/cont?xsmsid=0J066588036013658199&qunit=0M105409786198842071&sid=0M102340734727041623" target="_blank" rel="noopener noreferrer" style={{ marginLeft: 5 }}>
                大考中心官方網站</Link>
          </Alert>
          {/* Insert the new announcement here */}
          <Typography variant="body1" style={{ marginTop: '15px', marginBottom: '15px' }}>
              以下根據大考中心，112學年度學科能力測驗試題，所公布的英文作文題目為內容
          </Typography>

          <Accordion style={{ marginTop: '10px', marginBottom: '15px', backgroundColor: 'var(--green-50)' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>免責聲明</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography align="left">
                    本網站上的所有內容、意見和建議均僅供參考。我們不對任何資訊的準確性、完整性或可靠性做出任何明示或默示的保證。使用本網站的所有資訊和建議需自行承擔風險。我們概不負責因使用或依賴本網站上的任何資訊而直接或間接導致的任何損失或損害。
                </Typography>
            </AccordionDetails>
          </Accordion>

          <TextField
            label="Task"
            value={main}
            fullWidth
            margin="normal"
            multiline
            InputProps={{
              readOnly: true,
            }}
          />

          {emojis.map(emoji => (
            <div key={emoji.description} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
              <img src={emoji.image} alt={emoji.alt} width={50} height={50} style={{ marginRight: '15px' }} />
              <Typography>{emoji.description}</Typography>
            </div>
          ))}

          <TextField
            label="User Response"
            value={userResponse}
            onChange={e => setUserResponse(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
            inputRef={userResponseRef} 
          />
          
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>

          {showFeedback && reviewFeedback && (
            <div ref={feedbackRef}>
                {reviewFeedback['feedback'].map(feedback => (
                    <FeedbackCard
                        key={Object.keys(feedback)[0]}
                        title={Object.keys(feedback)[0]}
                        comment={feedback[Object.keys(feedback)[0]].comment}
                        suggestion={feedback[Object.keys(feedback)[0]].suggestion}
                        score={feedback[Object.keys(feedback)[0]].score}
                    />
                ))}
            </div>
          )}
        </Paper>
    )
}

export default EntryExamWriting;