import {React , useEffect, useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import About from "./about";
import AuthContext from '../contexts/AuthContext';
import { Helmet } from "react-helmet";

function AboutUsZhCn() {
    const { goHome, goBackHome, defaultLanguage, setDefaultLanguage } = useContext(AuthContext);
    const [langData, setLangData] = useState(null);

    useEffect(() => {
        console.log('AboutUs goHome - ', goHome)
        goBackHome(false)
    }, [])

    useEffect(() => {
        (async () => {
          let file_name = 'en'
          try {
            if (defaultLanguage === 'zh-TW') {
              file_name = 'zh'
            } else if(defaultLanguage === 'zh-CN') {
              file_name = 'cn'
            } else if(defaultLanguage === 'ja') {
              file_name = 'ja'
            } else {
              file_name = 'en'
            }
            console.log('AboutUsZhCn defaultLanguage - ', defaultLanguage)
            console.log('AboutUsZhCn file_name - ', file_name)
            const importedData = await import(`../assets/locales/${file_name}.json`);
            setLangData(importedData.default || importedData);
          } catch (error) {
            console.error(`Error loading ${defaultLanguage} language file:`, error);
            try {
              const fallbackData = await import('../assets/locales/en.json');
              setLangData(fallbackData.default || fallbackData);
            } catch (fallbackError) {
              console.error('Error loading fallback language file:', fallbackError);
            }
          }
        })();
    }, [defaultLanguage]);

    if (!langData) {
        return null; 
    }
    
    return (
        <div>
          <Helmet>
            <title>{langData.title}</title>
            <meta name="description" content={langData.description} />
            <meta name="keywords" content={langData.keywords} />
            <link rel="alternate" href="https://ailearningbar.com/en/about-us" hreflang="en" />
            <link rel="alternate" href="https://ailearningbar.com/tw/about-us-zh-TW" hreflang="zh-TW" />
            <link rel="alternate" href="https://ailearningbar.com/jp/about-us-jp" hreflang="ja" />
            <link rel="alternate" href="https://ailearningbar.com/cn/about-us-zh-cn" hreflang="zh-CN" />
          </Helmet>
            <div className="content"  style={{ minWidth:'100%', padding: '2rem' }}>
                <About/>
            </div>
            <Link to="/terms-of-service">{langData.termOfService}</Link> 
        </div>
    );
}

export default AboutUsZhCn;
