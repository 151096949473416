import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import './TitleMenu.css';
import Login from './components/Login';
import AuthContext from './contexts/AuthContext';


function TitleMenu() {
  const { goBackHome } = useContext(AuthContext);

  return (
    <div className='title-menu'>
      <div>
        <ul role="menu">
          <li role="menuitem">
            <Link 
              to="/" 
              onClick={() => {
                // event.preventDefault();
                goBackHome();
              }}
            >
              Home
            </Link>
            </li>
          <li role="menuitem"><Link to="/about-us">About Us</Link></li>
          {/* <li role="menuitem">
            Class
            <ul class="sub-menu">
              <li class="sub-menu-item"><a href="#">Reading</a></li>
              <li class="sub-menu-item"><a href="#">Write and Feedback</a></li>
            </ul>
          </li> */}
          <li role="menuitem"><Link to="/blogpost">Blog</Link></li>
          <li role="menuitem"><Link to="/contact-us">Contact Us</Link></li>
        </ul>
      </div>
      <div role="menuitem" className='login-item'><Login /></div>
    </div>
  );
}

export default TitleMenu;
