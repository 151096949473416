import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Link } from 'react-router-dom';
import TitleMenu from './titlemenu';
import Footer from "./Footer";
import Hero_Section from './components/Hero_Section';
import Highlight from './components/Highlight';
import ReadingQuizPage from './components/ReadingQuizPage'; // Import ReadingQuizPage
import WritingQuizPage from "./components/WritingQuizPage";
import ProgressBar from "./components/ProgressBar";
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from './components/AboutUs';
import BlogPage from "./components/BlogPage";
import Dashboard from "./components/Dashboard";
import ContactUs from "./ContactUS";
import SignInPage from "./components/SignInPage";
import AboutUsZhTw from "./components/AboutUsZhTw";
import BlogDetail from "./components/BlogDetail";
import TeacherLanguage from "./components/TeacherLanguage";

import PublisherList from "./components/PublisherList";
import UnitReadingByPublisher from "./components/UnitReadingByPublisher";
import JuniorReadingPageZhTW from "./components/JuniorReadingPageZhTW";
import SanminDemo from "./components/SanminDemo";
import Translate from "./components/Translate";
import TranslateDocument from "./components/TranslateDocument";

import EntryExamWriting from "./components/EntryExamWriting";
import AboutUsJp from "./components/AboutUsJp";
import BlogPageZhTw from "./components/BlogPageZhTw";
import BlogPageZhCn from "./components/BlogPageZhCn";
import BlogPageJp from "./components/BlogPageJp";
import AutoRedirect from './components/AutoRedirectAboutUs';
import AppEn from "./AppEn";
import AppCn from "./AppCn";
import AppTw from "./AppTw";
import AppJp from "./AppJp";
import Loader from "./Loader";

import './index.css'
import './App.css';
import './ReadingContest.css';
import AuthContext from "./contexts/AuthContext";  // Make sure to adjust the import path to your actual AuthContext location
import { Circles } from 'react-loader-spinner';
import { Helmet } from "react-helmet";
import { IntlProvider } from 'react-intl';
import EnglishMessages from './assets/locales/en.json'
import ChineseMessages from './assets/locales/zh.json';
import JapaneseMessage from './assets/locales/ja.json'
import { Box } from '@mui/material';
import styled from "styled-components";

import CookieConsent from "react-cookie-consent";

import AILearningBar from "./assets/AI LearningBAR.png";
import AILearningBar_TW from "./assets/AI LearningBAR_tw.png"
import About from "./components/about";
import AboutUsZhCn from "./components/AboutUsZhCn";

const messages = {
  'en': EnglishMessages,
  'zh': ChineseMessages,
  'ja': JapaneseMessage
};


const TextBlock = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  max-width: 25%;
  min-height: 25%;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  opacity: 0;
  animation: fadeIn 0.5s forwards, fadeOut 0.5s 2.5s forwards;
  display: flex; /* enable flexbox */
  flex-direction: column; /* stack items vertically */
  justify-content: center; /* center items vertically */
  align-items: center; /* center items horizontally */

  h1 {
    color: var(--green-200);
    font-weight: bold;
  }

  h2 {
    color: var(--green-300);
  }

  @keyframes fadeIn {
    to { opacity: 1; }
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
`;

const ImageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  height: 100px; /* Set the desired height */
  position: relative;
`;

const LogoImage = styled.img`
  width: auto;
  height: 200%; /* Occupy the full height of the container */
  object-fit: cover; /* Crop the image to cover the container */
  object-position: center; /* Center the image within the container */
  position: absolute;
  left: 0;
  margin-left:1%;
  z-index: -1;
`;

// const MainContent = ({ isLoggedIn, showDashboard, onBackToHome}) => {
const MainContent = ({ isLoggedIn, showDashboard, language, setBilingualLanguage}) => {
  const { showReadingQuiz, showWritingQuiz, currentProgressIndex, lastCompleteIndex, setCurrentProgressIndex, setLastCompleteIndex, startReadingQuiz, startWritingQuiz, goHome, setGoHome } = useContext(AuthContext);
  const location = useLocation();
  // const showProgressBar = location.pathname === "/" && showReadingQuiz;
  const showProgressBar = location.pathname === "/" && showReadingQuiz && currentProgressIndex !== 0;
  console.log('MainContent showProgressBar = ', showProgressBar)

  useEffect(() => {
    console.log('MainContent showReadingQuiz - ', showReadingQuiz)
    console.log('MainContent showWritingQuiz - ', showWritingQuiz)
  }, [showReadingQuiz, showWritingQuiz])

  useEffect(() => {
    console.log('language - ', language)
  }, [language])

  const handleReadingQuizPrevious = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex - 1)
  } 

  const handleReadingQuizCompletion = () => {
    if (currentProgressIndex >= lastCompleteIndex) 
      setLastCompleteIndex(currentProgressIndex)
  };

  const handleReadingQuizNext = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex + 1)
  }

  function RedirectToPreferredLang() {
    console.log('RedirectToPreferredLang navigator.language - ', navigator.language)
    console.log('RedirectToPreferredLang navigator.userLanguage - ', navigator.userLanguage)
    const preferredLanguage = (navigator.language || navigator.userLanguage)
    // const preferredLanguage = (navigator.language || navigator.userLanguage).substr(0, 2);
    
    if (preferredLanguage === 'zh-TW') {
      return <Navigate to="/tw" replace />;
    } else if (preferredLanguage === 'zh-CN') {
      return <Navigate to="/cn" replace />;
    } else if (preferredLanguage === 'ja') {
      return <Navigate to="/jp" replace />;
    } else if (preferredLanguage === 'en-US') {
      return <Navigate to="/en" replace />;
    } else {
      return <Navigate to="/en" replace />;
    }
  }

  const hookSetLanguageCallback = (optionLanguage) => {
    console.log('hookSetLanguageCallback optionLanguage - ', optionLanguage)
    setBilingualLanguage(optionLanguage)
  }
        
  return (
    <div id='App' className="App">

      <div id='header' className="TitleMenuContainer">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginRight: '20px'}}>
          <ImageContainer>
            <LogoImage src={language==='zh-TW'?AILearningBar_TW:AILearningBar} alt="Title" />
          </ImageContainer>
          <TeacherLanguage language={language} setLanguage={hookSetLanguageCallback} />
        </div>
        <div role="colored-bar"></div>
        <TitleMenu />
      </div>
      {showProgressBar && <ProgressBar currentActiveStep={currentProgressIndex} lastCompletedStep={lastCompleteIndex}/>}
      <div id='content-container' className="content-container" style={{ marginTop: currentProgressIndex === 0 ? '0px' : '60px' }}>
        <Routes>
          <Route path="/about-us" element={<AutoRedirect baseRoute="about-us" />} />
          <Route path="/blogpost" element={<AutoRedirect baseRoute="blogpost" />} />
          <Route path="/terms-of-service" element={<AutoRedirect baseRoute="terms-of-service" />} />

          <Route path="/tw/publisher-service" element={<PublisherList />} />
          <Route path="/tw/unit-reading/:selectedPublisher/:selectedGrade" element={<UnitReadingByPublisher />} />
          <Route path="/tw/junior-reading-zh-tw" element={<JuniorReadingPageZhTW />} />


          <Route path="/en/about-us-en" element={<AboutUs />} />
          <Route path="/cn/about-us-zh-cn" element={<AboutUsZhCn />} />
          <Route path="/tw/about-us-zh-tw" element={<AboutUsZhTw />} />
          <Route path="/jp/about-us-jp" element={<AboutUsJp />} />
          <Route path="/en/blogpost-en" element={<BlogPage />} />
          <Route path="/tw/blogpost-zh-tw" element={<BlogPageZhTw />} />
          <Route path="/cn/blogpost-zh-cn" element={<BlogPageZhCn />} />
          <Route path="/jp/blogpost-jp" element={<BlogPageJp />} />

          <Route path="/tw/entrance-zh-tw" element={<EntryExamWriting />} />

          <Route path="/tw/sanmin-demmo" element={<SanminDemo />} />
          <Route path="/tw/translate" element={<Translate />} />
          <Route path="/tw/translate-document" element={<TranslateDocument />} />

          <Route path="/en/blog/:id" element={<BlogDetail lang='en'/>}  />
          <Route path="/cn/blog/:id" element={<BlogDetail lang='cn'/>}  />
          <Route path="/tw/blog/:id" element={<BlogDetail lang='tw'/>}  />
          <Route path="/jp/blog/:id" element={<BlogDetail lang='jp'/>}  />
          <Route path="/en/terms-of-service-en" element={<TermsOfService />} />
          <Route path="/cn/terms-of-service-zh-cn" element={<TermsOfService />} />
          <Route path="/tw/terms-of-service-zh-tw" element={<TermsOfService />} />
          <Route path="/jp/terms-of-service-jp" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/en/*" element={<AppEn />} />
          <Route path="/cn/*" element={<AppCn />} />
          <Route path="/tw/*" element={<AppTw />} />
          <Route path="/jp/*" element={<AppJp />} />
          <Route path="*" element={<RedirectToPreferredLang />} />
        </Routes>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. {" "}
        <span><Link to="/privacy-policy" style={{color: "#fbd600"}}>Learn more</Link></span>
      </CookieConsent>

    </div>
  );
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const { setCurrentProgressIndex, setLastCompleteIndex, goHome, goBackHome, loadingCounter, defaultLanguage, setDefaultLanguage, FetchError, setFetchError, loadingMessage } = useContext(AuthContext);
  const [locale, setLocale] = useState('en'); // default to English

  const preferredLanguage = (navigator.language || navigator.userLanguage)
  console.log('preferredLanguage - ', preferredLanguage)
  setDefaultLanguage(preferredLanguage)

  const meta = {
    en: {
      title: "AI Learning Bar - Advanced English Learning Powered by AI",
      description: "Improve your English language skills with AI Learning Bar. Powered by GPT, our platform provides personalized reading and writing tests for effective and fun learning. Get started today!",
      keywords: "AI-powered English learning, GPT, OpenAI, personalized reading tests, personalized writing tests, English for students, advanced English learning, ESL"
    },
    'zh-TW': {
      title: "AI Learning Bar愛學霸 - 由AI驅動的進階英語學習",
      description: "利用AI Learning Bar愛學霸提升您的英語語言技能。我們的平台由GPT驅動，提供個性化的閱讀和寫作測驗，使學習效果卓越且富有趣味性。立即開始學習！",
      keywords: "AI驅動的英語學習，GPT，OpenAI，個性化閱讀測驗，個性化寫作測驗，學生英語，進階英語學習，ESL"
    },
    'zh-CN': {
      "title": "AI Learning Bar - 由人工智能驱动的高级英语学习",
      "description": "通过AI Learning Bar提高您的英语语言技能。我们的平台由GPT提供支持，提供个性化的阅读和写作测试，实现有效而有趣的学习。立即开始！",
      "keywords": "AI驱动的英语学习，GPT，OpenAI，个性化阅读测试，个性化写作测试，学生英语，高级英语学习，ESL"
    },
    ja: {
      title: "AI Learning Bar - AIによる高度な英語学習",
      description: "AI Learning Barを使用して、英語スキルを向上させましょう。 GPTによって強化された当社のプラットフォームは、効果的で楽しい学習のためのパーソナライズされた読解テストと作文テストを提供します。今日から始めましょう！",
      keywords: "AIによる英語学習、GPT、OpenAI、パーソナライズされた読解テスト、パーソナライズされた作文テスト、学生向け英語、高度な英語学習、ESL"
    }
  };

  // const currentMeta = meta[defaultLanguage];
  const currentMeta = meta[defaultLanguage] || meta['en']; // Set to 'en' if defaultLanguage doesn't match any locale

  const setSessionLanguage = (language) => {
    console.log('set session language - ', language)
    switch (language) {
      case 'zh-TW':
        sessionStorage.setItem('defaultLanguage', 'Taiwanese')
        break;
      case 'zh-CN':
        sessionStorage.setItem('defaultLanguage', 'Chinese')
        break;
      case 'ja':
        sessionStorage.setItem('defaultLanguage', 'Japanese')
        break;
      case 'en-US':
        sessionStorage.setItem('defaultLanguage', 'English')
        break;
      default:
        sessionStorage.setItem('defaultLanguage', 'English')
        break;
    }
  }

  useEffect(() => {
      // document.title = 'AI Learning Bar';
    var base_url = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://ailearningbar.com";
    const currentPath = window.location.pathname;
    console.log('App currentPath - ', currentPath)
    if (currentPath.includes('/tw') || currentPath.includes('/cn') || currentPath.includes('/jp')|| currentPath.includes('/en')) return;
    const preferredLanguage = (navigator.language || navigator.userLanguage)

        
    if (preferredLanguage === 'zh-TW') {
      return <Navigate to="/tw" replace />;
    } else if (preferredLanguage === 'zh-CN') {
      return <Navigate to="/cn" replace />;
    } else if (preferredLanguage === 'ja') {
      return <Navigate to="/jp" replace />;
    } else if (preferredLanguage === 'en-US') {
      return <Navigate to="/en" replace />;
    } else {
      return <Navigate to="/en" replace />;
    }

  }, []); // The title will be set when the component mounts

  useEffect(() => {
    console.log('App defaultLanguage - ', defaultLanguage)
    // document.title = 'AI Learning Bar';
  }, [defaultLanguage])

  useEffect(() => {
    console.log('goHome - ', goHome)
  }, [goHome])

  return (
    <IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
      <Helmet>
        {/* <html lang={defaultLanguage} /> */}
        <title>{currentMeta.title}</title>
        <meta name="description" content={currentMeta.description} />
        <meta name="keywords" content={currentMeta.keywords} />
        <link rel="alternate" href="https://ailearningbar.com/en/" hreflang="en" />
        <link rel="alternate" href="https://ailearningbar.com/tw/" hreflang="zh-TW" />
        <link rel="alternate" href="https://ailearningbar.com/jp/" hreflang="ja" />
        <link rel="alternate" href="https://ailearningbar.com/cn/" hreflang="zh-CN" />
      </Helmet>
      <Router>
        <div className="app-content">
          <MainContent 
            isLoggedIn={isLoggedIn} 
            showDashboard={showDashboard}
            language={defaultLanguage}
            setBilingualLanguage={setSessionLanguage}
          />
          {console.log('App isLoggedIn - ', isLoggedIn)}
          {console.log('App showDashboard - ', showDashboard)}
          {loadingCounter > 0 && <Loader message={loadingMessage}/>}

          {FetchError && 
            <TextBlock>
              <h2>{FetchError}</h2>
            </TextBlock>
          }
        </div>
      </Router>
    </IntlProvider>
  );
}

export default App;
