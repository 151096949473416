import React from 'react';
import { Typography, Link, Container } from '@mui/material';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    padding: 20px;
`;

const SanminDemo = () => {
    return (
        <StyledContainer>
            <Typography variant="h4" gutterBottom>
                三民書局Demo Page
            </Typography>
            <Typography variant="body1" paragraph>
                目前閱讀測驗內容都是以國中為主，寫作批改部分則不分國中高中
            </Typography>
            <div>
                <Link href="https://ailearningbar.com" target="_blank" rel="noopener">
                    多語系選擇
                </Link>
            </div>
            <div>
                <Link href="https://ailearningbar.com" target="_blank" rel="noopener">
                    主題是寫作與批改展示
                </Link>
            </div>
            <div>
                <Link href="https://ailearningbar.com/tw/junior-reading-zh-tw" target="_blank" rel="noopener">
                    閱讀、字彙、文法點生成題目
                </Link>
            </div>
            <div>
                <Link href="https://ailearningbar.com/tw/blogpost-zh-tw" target="_blank" rel="noopener">
                    主題式寫作題目生成。依據文章指引，給予寫作題目生成
                </Link>
            </div>
            <div>
                <Link href="https://ailearningbar.com/tw/entrance-zh-tw" target="_blank" rel="noopener">
                    大學入學考試評測
                </Link>
            </div>
            <Typography variant="body1" paragraph>
                Note:
            </Typography>
            <Typography variant="body1" paragraph>
                對話系統目前還在流程串接，因此還沒有開放
            </Typography>
            <Typography variant="body1" paragraph>
                以上的文章生成或是議題列表，不管是閱讀測驗或是寫作都會隨機更換，如果您想要自由選擇，也可以
            </Typography>
            <Typography variant="body1" paragraph>
                目前系統正在測試時事議題，也就是說有可能會串接目前時事議題，來生成寫作與閱讀文本
            </Typography>
        </StyledContainer>
    );
}

export default SanminDemo;
