import {React , useEffect, useContext, useState} from 'react';
import { Helmet } from 'react-helmet'; // Import the Helmet component
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom
import AboutJp from './aboutJp';
import AuthContext from '../contexts/AuthContext';

function AboutUsJp() {
    const { goHome, goBackHome } = useContext(AuthContext);
    const [langData, setLangData] = useState(null);

    useEffect(() => {
        console.log('AboutUsZhTw goHome - ', goHome)
        goBackHome(false)
    }, [])

    useEffect(() => {
        // We use a IIFE (Immediately Invoked Function Expression) here
        // so we can use async/await, which isn't allowed in useEffect directly
        (async () => {
          try {
            const importedData = await import('../assets/locales/ja.json');
            setLangData(importedData.default || importedData);
          } catch (error) {
            console.error(`Error loading zh language file:`, error);
            // load English as default if the selected language file isn't found
            try {
              const fallbackData = await import('../assets/locales/en.json');
              setLangData(fallbackData.default || fallbackData);
            } catch (fallbackError) {
              console.error('Error loading fallback language file:', fallbackError);
            }
          }
        })();
    }, []); // Only re-run if defaultLanguage changes

    if (!langData) {
        return null; // or a loading spinner, etc.
    }
    
    return (
        <div>
            <Helmet>
                <title>私たちについて - 愛学バー AI ラーニングバー、GPTによる提供</title>
                <meta name="description" content="小学5年生から中学3年生の英語学習者向け。OpenAIのGPTによる個別指導で言語学習を加速します。" />
                <meta name="keywords" content="ai、人工知能、英語学習、GPT、OpenAI、読解テスト、作文テスト、ESL（英語を第二言語とする学習者）、言語学習、ChatGPT" />
                <link rel="alternate" href="https://ailearningbar.com/en/about-us" hreflang="en" />
                <link rel="alternate" href="https://ailearningbar.com/tw/about-us-zh-TW" hreflang="zh-TW" />
                <link rel="alternate" href="https://ailearningbar.com/jp/about-us-jp" hreflang="ja" />
                <link rel="alternate" href="https://ailearningbar.com/cn/about-us-zh-cn" hreflang="zh-CN" />

                <meta property="og:title" content="私たちについて - 愛学バー AI ラーニングバー、GPTによる提供" />
                <meta property="og:description" content="小学5年生から中学3年生の学生向けに、インタラクティブな読解テストと作文練習を提供します" />
                <meta property="og:url" content="https://ailearningbar.com/about-us-ja-JP" />
                <meta property="og:locale" content="ja_JP" />
            </Helmet>

            <div className="content"  style={{ minWidth:'100%', padding: '2rem' }}>
                <AboutJp/>
            </div>
            <Link to="/terms-of-service">{langData.termOfService}</Link> {/* Add a link to the terms of service page */}
        </div>
    );
}

export default AboutUsJp;
