import React, { useState, useEffect, Fragment, useContext } from 'react';
import Question from './Question';
import './ReadingQuizPage.css'
import './Question.js';
import TextWithLineBreaks from './TextWithLineBreaks';
import VocabularyList from './VocabularyList';
import AuthContext from '../contexts/AuthContext';
import '../App.css';
// import { Puff } from 'react-loader-spinner';

function ReadingQuizPage(props) {
  const sample_element = {
    "Level": "A1 Beginner",
    "articleTitle": "The Importance of Exercise",
    "title": "A Healthy Conversation",
    "text": "Samantha: Hi, Sarah! How are you?\nSarah: Hi, Samantha! I’m good, thanks. How about you?\nSamantha: I’m doing well, thanks. I’ve been trying to exercise more lately.\nSarah: That’s great! Exercise is so important for our health.\nSamantha: Yeah, I’ve been going for a run every morning before work.\nSarah: That’s a great way to start the day. Have you noticed any changes in how you feel?\nSamantha: Definitely. I have more energy throughout the day and I feel more focused at work.\nSarah: That’s wonderful to hear. Keep it up!\n",
    "questions": [
      {
        "question": "What is Samantha trying to do more of?",
        "options": [
          "Eat more vegetables",
          "Exercise more",
          "Watch TV"
        ],
        "answer": "Exercise more"
      },
      {
        "question": "What does Sarah say about exercise?",
        "options": [
          "It is not important",
          "It is important for our health",
          "It is only for athletes"
        ],
        "answer": "It is important for our health"
      },
      {
        "question": "What has Samantha been doing every morning?",
        "options": [
          "Going for a run",
          "Watching TV",
          "Sleeping in"
        ],
        "answer": "Going for a run"
      },
      {
        "question": "What changes has Samantha noticed since she started exercising?",
        "options": [
          "She has less energy",
          "She feels more focused at work",
          "She has been eating more junk food"
        ],
        "answer": "She feels more focused at work"
      },
      {
        "question": "What does Sarah tell Samantha to do?",
        "options": [
          "Stop exercising",
          "Keep exercising",
          "Eat more junk food"
        ],
        "answer": "Keep exercising"
      }
    ],
    "vocabulary": [
      "exercise",
      "health",
      "energy",
      "focused",
      "athletes",
      "junk food"
    ]
  }

  const { isLogin, setLoginStatus, userOauthInfo, setOauthUserInfo, selectedLevel, setSelectedLevel, startLoading, finishLoading, FetchError, setFetchError } = useContext(AuthContext);

  const sample_article = {"articles":[]}

  const initialSampleArticle = sample_article.articles

  const initialCurrentArticleIndex = 0;

  const [sampleArticle, setSampleArticle] = useState(sample_article);

  const [currentPage, setCurrentPage] = useState('questions');
  const [showAnswer, setShowAnswer] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState('questions');

  // const [selectedLevel, setSelectedLevel] = useState('B1 Intermediate');
  const [currentArticleIndex, setCurrentArticleIndex] = useState(initialCurrentArticleIndex);
  const [selectedAnswers, setSelectedAnswers] = useState([]);


  // Append an item to the article array
  const appendItem = (newItem) => {
    setSampleArticle((prevState) => {
      return {
        ...prevState,
        articles: [...prevState.articles, newItem],
      };
    });
  };

  // Delete an item from the article array by index
  const deleteItem = (index) => {
    setSampleArticle((prevState) => {
      return { articles: prevState.articles.filter((_, i) => i !== index) };
    });
  };

  // Clear the article array
  const clearItems = () => {
    setSampleArticle(initialSampleArticle);
  };

  // Get an item from the article array by index
  const getArticleByIndex = (index) => {
    return sampleArticle.articles[index];
  };
  

  const handleAnswerChange = (selectedAnswer, questionIndex) => {
    setSelectedAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[questionIndex] = selectedAnswer;
      return newAnswers;
    });
  };
  
  const getTimeString = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  };  


  const fetchArticle = async () => {
      console.log('fetch selectedLevel - ', selectedLevel)
      
      startLoading("Crafting an engaging reading test for you. Hang on a moment...");

      const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/alice00';
      const data = {
        'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
        "type":"Reading",
        "level":selectedLevel,
        'message': ""
      };
    
      const headers = {
        'Content-Type': 'application/json',
        'Origin': 'https://ailearningbar.com'
      };

      try {
        
        const response = await fetch(fetch_url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
          mode: 'cors'
        });

        console.log('http request headers', headers)
    
        const article_data = await response.json();
        // const article_data = sample_element
        if (article_data.questions) {
          article_data.questions.forEach(question => {
              if (question.options) {
                  question.options.sort();
              }
          });
        }
      
        console.log('fetchArticle article_data - ', article_data)
        finishLoading()

        try {
            console.log('fetchArticle data title = ', article_data.title)
            console.log('fetchArticle data Text = ', article_data.text)
            if (200) {
              // if (response.ok) {
              appendItem(article_data);
              // setFetchError(response.status.toString())
            } else {
              // setFetchError('response error')
              setFetchError(response.status.toString())
            }
            // finishLoading();
          } catch (error) {
            console.error("Failed to parse JSON - ", error)
            setFetchError(error.toString())
            // finishLoading();
         }
         
        // finishLoading();
        console.log('fetch article ready to finish')
        // setFetchError(null)
      } catch (error) {
        console.error('Error fetching data from server:', error);
        setFetchError(error.toString())
        finishLoading();
      }
      
  }
    
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousArticle = () => {
    // to get previous article and update UI for article and question
    setShowAnswer(true);
  if (currentArticleIndex > 0) {
      setCurrentArticleIndex(currentArticleIndex - 1);
    } else {
      setCurrentArticleIndex(1)
    }
    if (props.onPrev) {
      props.onPrev()
    }
  };
      
  const handleNextArticle = async () => {
    setShowAnswer(false);
    // setIsSubmitted(false);
    if (props.onNext) {
      if (currentArticleIndex >= 3) {
        setCurrentArticleIndex(3)
      } else {
        setCurrentArticleIndex(currentArticleIndex + 1)
      }
      props.onNext();
      // props.onNext(currentArticleIndex + 1);
    }
    // }
  };
              
  const handleSubmit = () => {
    // Submit this answers for this article. Enable the next button to next article
    // const isCorrectArray = selectedAnswers.map(
    //   (answer, index) => answer === sampleArticle.articles[currentArticleIndex].questions[index].answer
    // );
    setShowAnswer(true);
    setIsSubmitted(true);
    // console.log(isCorrectArray); // This array will contain true or false values for each question
  
    if (props.onCompletion) {
      props.onCompletion();
    }
      // Fetch a new article after submitting the current one
    console.log('submot sampleArticle length - ' + sampleArticle.articles.length)
    if (sampleArticle.articles.length < 3) {
      //fetch reading article from gpt
      fetchArticle();
    } else {
      //fetch writing task from gpt
    }
  };
  

  // Other component logic here
  useEffect(() => {
    console.log('Reading fetch article for level - ', selectedLevel)
    // clearItems()
    sessionStorage.setItem('vocabularyList', undefined)
    fetchArticle();
  }, []);
  
  useEffect(() => {
    // Check if an item has been added
    if (sampleArticle.length > 0) {
      const newItem = sampleArticle.articles[sampleArticle.length - 1];
      console.log("sampleArticle useEffect, A new item has been added:", newItem);
      // Perform any desired side effects here
    }
  }, [sampleArticle]);
  
  useEffect(() => {
    if (currentArticleIndex < sampleArticle.articles.length) {
      setIsSubmitted(true); // Add this line to enable the "Next" button when going back
    } else {
      setIsSubmitted(false); // Add this line to enable the "Next" button when going back
    }
  }, [currentArticleIndex]);
  
  return (
    <div className="reading-quiz-page">

        <h1>Reading Quiz</h1>
        <div id='article-box' className='article'>
          <div id='article-area' className="scrollable-content">
            <div id='article-content' className="article-content" >
              {
                sampleArticle.articles.length > 0 && 
                currentArticleIndex < sampleArticle.articles.length &&
                sampleArticle.articles[currentArticleIndex] && (
                  // <TextWithLineBreaks text={sample_element.text} />
                  <TextWithLineBreaks text={sampleArticle.articles[currentArticleIndex].text} />
                )
              }
            </div>
          </div>
          <div id='question-area' className='question-area'>
            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '1rem', maxHeight: '60px' }}>
              <button 
                className={`tab-button ${activeTab === 'questions' ? 'tab-button-active' : ''}`} 
                onClick={() => handlePageChange('questions')}
              >
                Questions
              </button>
              <button 
                className={`tab-button ${activeTab === 'vocabulary' ? 'tab-button-active' : ''}`} 
                onClick={() => handlePageChange('vocabulary')}
              >
                Vocabulary
              </button>
              {/* <button onClick={() => handlePageChange('questions')}>Questions</button>
              <button onClick={() => handlePageChange('vocabulary')}>Vocabulary</button> */}
            </div>
            {currentPage === 'questions' ? (
              <Fragment>
              <div id='questions' className="scrollable-content" style={{height: '100%'}}>
                {/* Your questions content */}
                <div className="question-container" style={{'align-content': 'flex-start'}}>
                  <div className="questions-wrapper">
                    <div id="questions_section" className="questions-section">
                      {
                        sampleArticle.articles.length > 0 && 
                        currentArticleIndex < sampleArticle.articles.length &&
                        sampleArticle.articles[currentArticleIndex] && (
                          sampleArticle.articles[currentArticleIndex].questions.map((element, questionIndex) => (
                            <Question
                            key={element.question} // Add a key for each element to avoid React warnings
                            question={element.question}
                            options={element.options}
                            answer={element.answer}
                            onAnswer={(selectedAnswer) => handleAnswerChange(selectedAnswer, questionIndex)}
                            showAnswer={showAnswer}
                          />
                        )))
                      }

                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
            ) : (
              <div id='vocabulary' className="scrollable-content" style={{height: '100%'}}>
                <VocabularyList pageIndex={currentArticleIndex} words={(sampleArticle.articles.length - 1) < currentArticleIndex?'':sampleArticle.articles[currentArticleIndex].vocabulary} level={selectedLevel} native_language="Traditional Chinese" />
              </div>
            )}
          </div>
        </div>
        {/* Render the level selector, CTA button, text, and questions */}
        <div className="navigation-buttons" style={{height: '60px', width: '100%', display: 'flex', justifyContent: 'space-around' }}>
          <button
            className="previous-button"
            onClick={handlePreviousArticle}
            disabled={currentArticleIndex === 0}
          >
            Previous
          </button>
          <button 
            id='submit-answer'
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="next-button"
            onClick={handleNextArticle}
            disabled={
              ((currentArticleIndex === (sampleArticle.articles.length - 1)) && (sampleArticle.articles.length < 3)) || !isSubmitted
            }
          >
            Next
          </button>
        </div>
    </div>
  );
}

export default ReadingQuizPage;
