import {React , useEffect, useContext, useState} from 'react';
import './Highlight.css';
import Footer from '../Footer';
import AuthContext from '../contexts/AuthContext';

function Highlight() {
  const { defaultLanguage, setDefaultLanguage } = useContext(AuthContext);
  const [langData, setLangData] = useState(null);

  useEffect(() => {
    // We use a IIFE (Immediately Invoked Function Expression) here
    // so we can use async/await, which isn't allowed in useEffect directly
    (async () => {
      let file_name = 'en'
      try {
        if (defaultLanguage === 'zh-TW') {
          file_name = 'zh'
        } else if(defaultLanguage === 'zh-CN') {
          file_name = 'cn'
        } else if(defaultLanguage === 'ja') {
          file_name = 'ja'
        } else {
          file_name = 'en'
        }
        const importedData = await import(`../assets/locales/${file_name}.json`);
        setLangData(importedData.default || importedData);
      } catch (error) {
        console.error(`Error loading ${defaultLanguage} language file:`, error);
        // load English as default if the selected language file isn't found
        try {
          const fallbackData = await import('../assets/locales/en.json');
          setLangData(fallbackData.default || fallbackData);
        } catch (fallbackError) {
          console.error('Error loading fallback language file:', fallbackError);
        }
      }
    })();
  }, [defaultLanguage]); // Only re-run if defaultLanguage changes

  if (!langData) {
    return null; // or a loading spinner, etc.
  }

  return (
    <section className="highlight-container">
      <h2>{langData.OurEnglishLearningHighlights}</h2> {/* include your keywords in header */}
      <div className="highlight-grid">
        <article className="highlight-item"> {/* Use semantic HTML tags */}
          <h3>{langData.CustomizedEnglishLearning}</h3> {/* include keywords */}
          <p>{langData.CustomizedEnglishLearningDescription}</p>
        </article>
        <article className="highlight-item">
          <h3>{langData.FlexibleEnglishLearningExperience}</h3> {/* include keywords */}
          <p>{langData.FlexibleEnglishLearningExperienceDescription}</p>
        </article>
        <article className="highlight-item">
          <h3>{langData.FunEnglishLanguageInteraction}</h3> {/* include keywords */}
          <p>{langData.FunEnglishLanguageInteractionDescription}</p>
        </article>
        <article className="highlight-item">
          <h3>{langData.ContinuousGrowthInEnglishSkills}</h3> {/* include keywords */}
          <p>{langData.ContinuousGrowthInEnglishSkillsDescription}</p>
        </article>
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
}

export default Highlight;
