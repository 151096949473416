import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Update this import
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, TextField, Button, Link } from '@material-ui/core';
import AuthContext from './contexts/AuthContext';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const StyledContainer = styled(Container)`
  padding: 3rem 0;
`;

const ContactUs = () => {

  const { defaultLanguage, setDefaultLanguage, showMessage, setFetchError } = useContext(AuthContext);
  const [captchaValue, setCaptchaValue] = useState(null);

  const classes = useStyles();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleCaptchaChange = (value) => {
    setForm({ ...form, captcha: value });
    // setCaptchaValue(value);
  }


  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const supportEmail = 'chinghua.fan@gmail.com';
  const subject = 'Support Request';
  const facebookProfile = 'https://www.facebook.com/profile.php?id=100094511952319';
  const InstagramProfile = 'https://www.instagram.com/ailearningbar/';

  const composeEmailLink = () => {
    const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}`;
    return mailtoLink;
  };

  const handleInputChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form);

    // if (captchaValue === null) {
      // handle incomplete captcha here
      // return;
    // }

    // The form will be handled by Netlify now.
      // Encode form data for submission
    const encodedData = Object.keys(form)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(form[key]))
      .join("&");

    // Send POST request to Netlify
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodedData,
    })
      .then(() => showMessage('Message sent successfully')) // On successful response, redirect to '/success'
      .catch(error => setFetchError('An error occurred, please try again')); // Handle errors
  };

  return (
    <StyledContainer>
    <Typography variant="h2" gutterBottom>
      Contact Us
    </Typography>
    <Typography variant="body1" paragraph>
      Feel free to reach out to us for any inquiries or feedback!
    </Typography>
    <Typography variant="body1" paragraph>
      歡迎合作提案，以及相關技術洽詢！
    </Typography>
    <Typography variant="body1" paragraph>
      If you need immediate support, you can send an email to{' '}
      <Link href={composeEmailLink()}>{supportEmail}</Link>.
    </Typography>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <img src='https://storage.googleapis.com/site_resource/qrcode_www.facebook.com.png' 
          style={{ width: "150px", height: "150px" }} // Here we set the size
          alt='ailearningbar facebook' />
        <Typography variant="body1" paragraph>
          You can also reach us through our{' '}
          <Link href={facebookProfile} target="_blank" rel="noopener noreferrer">
            Facebook Page
          </Link>
          .
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <img src='https://storage.googleapis.com/site_resource/qrcode_www.instagram.com.png' 
          style={{ width: "150px", height: "150px" }} // Here we set the size
          alt='ailearningbar instagram' />
        <Typography variant="body1" paragraph>
          You can also reach us through our{' '}
          <Link href={InstagramProfile} target="_blank" rel="noopener noreferrer">
            Instagram
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
    </StyledContainer>
  );
};

export default ContactUs;
