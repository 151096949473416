import {React , useEffect, useContext, useState} from 'react';
import AuthContext from '../contexts/AuthContext';

function TermsOfService() {
    const { defaultLanguage, setDefaultLanguage } = useContext(AuthContext);
    const [langData, setLangData] = useState(null);

    useEffect(() => {
        // We use a IIFE (Immediately Invoked Function Expression) here
        // so we can use async/await, which isn't allowed in useEffect directly
        (async () => {
          let file_name = 'en'
          try {
            if (defaultLanguage === 'zh-TW') {
              file_name = 'zh'
            } else if(defaultLanguage === 'zh-CN') {
              file_name = 'cn'
            } else if(defaultLanguage === 'ja') {
              file_name = 'ja'
            } else {
              file_name = 'en'
            }
            const importedData = await import(`../assets/locales/${file_name}.json`);
            setLangData(importedData.default || importedData);
          } catch (error) {
            console.error(`Error loading ${defaultLanguage} language file:`, error);
            // load English as default if the selected language file isn't found
            try {
              const fallbackData = await import('../assets/locales/en.json');
              setLangData(fallbackData.default || fallbackData);
            } catch (fallbackError) {
              console.error('Error loading fallback language file:', fallbackError);
            }
          }
        })();
    }, [defaultLanguage]); // Only re-run if defaultLanguage changes

    if (!langData) {
        return null; // or a loading spinner, etc.
    }

    return (
        <div id='termofservice' style={{width:'100%', boxSizing: 'border-box', overflowX: 'hidden', paddingLeft:'60px', marginRight:'60px', textAlign:'left'}}>
            <h1>{langData.termOfServiceTitle}</h1>
            <h2>{langData.serviceIntroductionTitle}</h2>
            <p>{langData.serviceIntroduction}</p>
            <h2>{langData.registrationLoginTitle}</h2>
            <p>{langData.registrationLogin}</p>
            <h2>{langData.freeUsageTitle}</h2>
            <p>{langData.freeUsage}</p>
            <h2>{langData.costChangesTitle}</h2>
            <p>{langData.costChanges}</p>
            <h2>{langData.termsModificationTitle}</h2>
            <p>{langData.termsModification}</p>
            <h2>{langData.serviceTerminationTitle}</h2>
            <p>{langData.serviceTermination}</p>
            <h2>{langData.disclaimerTitle}</h2>
            <p>{langData.disclaimer}</p>
            <h2>{langData.intellectualPropertyTitle}</h2>
            <p>{langData.intellectualProperty}</p>
            <h2>{langData.dataProtectionTitle}</h2>
            <p>{langData.dataProtection}</p>
            <h2>{langData.applicableLawJurisdictionTitle}</h2>
            <p>{langData.applicableLawJurisdiction}</p>
            <h2>{langData.otherTermsTitle}</h2>
            <p>{langData.otherTerms}</p>
            <h2>{langData.advertisementsTitle}</h2>
            <p>{langData.advertisements}</p>
            <h2>{langData.GPTGeneratedContentTitle}</h2>
            <p>{langData.GPTGeneratedContent}</p>
            <h2>{langData.userGeneratedContentTitle}</h2>
            <p>{langData.userGeneratedContent}</p>

            {/* 將您的服務條款内容插入到這裡 */}
        </div>
    );
}

export default TermsOfService;
