import React, { useState, useEffect } from 'react';
import './Question.css'
import TextWithLineBreaks from './TextWithLineBreaks';

function Question({ question, options, answer, onAnswer, showAnswer }) {
  console.log('Question function executed');
  // function Question({ question, options, answer, onAnswer, active }) {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [correct, setCorrect] = useState(false);
  const [answered, setAnswered] = useState(false);

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
    setCorrect(event.target.value === answer);
    setAnswered(true);
    onAnswer(event.target.value);
      // setSelectedAnswer(event.target.value);
    // setCorrect(event.target.value === answer);
    // setAnswered(true);
    // onAnswer(event.target.value);
  };

  const formattedQuestion = question.split(/\r\n|\n/).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  useEffect(() => {
    console.log('Question component loaded');
  }, []);


  return (
    <div className={`question-container`}>
      <p className='question-text h1'>{formattedQuestion}</p>
      <ul className="answer-options">
        {options.map((option, index) => (
          <li key={index}>
            <label
              className={`answer-option ${
                showAnswer
                  ? option === answer
                    ? "correct"
                    : selectedAnswer === option
                    ? "incorrect"
                    : ""
                  : ""
              }`}
            >
              <input
                type="radio"
                name={question}
                value={option}
                checked={selectedAnswer === option}
                onChange={handleAnswerChange}
              />
              {option}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Question;
