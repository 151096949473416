import React, { useRef, useState, forwardRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useDrag } from 'react-dnd';

const WordBank = ({element, onDragStop, confirmed}) => {
    // const initialWords = element;
    // const wordRefs = useRef(wordBank.map(() => null)); // Create a ref for each word

    const [wordElements, setWordElements] = useState([]);

    const invertShownByWord = (word) => {
        const newWordElements = [...wordElements];
        console.log('invertShownByWord - ', word)
        newWordElements.forEach((element, wordIndex) => {
            if (element.word === word) {
                newWordElements[wordIndex] = {
                    ...newWordElements[wordIndex],
                    visible: !newWordElements[wordIndex].visible
                };
            }
        });
        setWordElements(newWordElements);
    }

    useEffect(() => {
        console.log('useEffect matched_word - ', confirmed)
        invertShownByWord(confirmed)
        confirmed = ""
    }, [confirmed])

    useEffect(() => {
        console.log('WordBank source - ', element)
        console.log(' WordBank reload - ', wordElements)
        if (element && element.Word_Bank) {
            setWordElements(element.Word_Bank.sort().map((word, index) => ({
                word: word,
                visible: true,
                position: { x: 0, y: 0 },
                rect: {top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0, x: 0, y: 0},
                wordRef: React.createRef() 
            })));
    
        }
        // wordBank = [...element.Word_Bank].sort();
    }, [])

    const handleDragStop = (index, e, data) => {
        // Reset the position of the draggable element
        const newWordElements = [...wordElements];
        newWordElements[index] = {
            ...newWordElements[index],
            position: { x: 0, y: 0 },
            rect: newWordElements[index].wordRef.current.getBoundingClientRect()
        };
        setWordElements(newWordElements);
        onDragStop(newWordElements[index]);
    }

    return (
        <div id='word_bank' style={{ display: 'flex', border: '1px solid black', width: '100%', padding: '1rem', marginBottom: '1rem', whiteSpace: 'pre-wrap' }}>
            {wordElements.map((element, index) => (
                <Draggable 
                    key={index} 
                    position={element.position} 
                    onStop={(e, data) => handleDragStop(index, e, data)}
                    
                    // nodeRef={wordRefs.current[index]} // Assign a unique ref to each Draggable
                    >
                    <div style={{ flex: 1, textAlign: 'center', visibility:element.visible ? 'visible' : 'hidden' }}>
                        <div ref={element.wordRef}>{element.word}</div>
                    </div>
                </Draggable>
            ))}
        </div>
    )
}

export default WordBank;
