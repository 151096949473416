import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

function AutoRedirect({ baseRoute }) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('AutoRedirect baseRoute - ', baseRoute)
    console.log('AutoRedirect navigator.language - ', navigator.language)
    console.log('AutoRedirect navigator.userLanguage - ', navigator.userLanguage)
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.startsWith("zh-TW")) {
      navigate(`/tw/${baseRoute}-zh-tw`);
    } else if (userLang.startsWith("zh-CN")) {
      navigate(`/cn/${baseRoute}-zh-cn`);
    } else if (userLang.startsWith("ja")) {
      navigate(`/jp/${baseRoute}-jp`);
    } else {
      navigate(`/en/${baseRoute}-en`);
    }
  }, [navigate, baseRoute]);

  return null;
}

export default AutoRedirect;
