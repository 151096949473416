import React, { useState, useEffect, useContext } from 'react';

import { Box, Typography, Link as MUILink, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';
import AuthContext from '../contexts/AuthContext';

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
  color: var(--green-500);
`;

const BlogItem = ({ id, title, brief, url, imageUrl }) => {
  const { defaultLanguage } = useContext(AuthContext);
  const [lang, setLang] = useState("tw")

  useEffect(() => {
    console.log('BlogItem default language - ', defaultLanguage);

    const languageMapping = {
        'zh-TW': 'tw',
        'zh-CN': 'cn',
        'ja': 'jp',
        'default': 'en'
    };

    setLang(languageMapping[defaultLanguage] || languageMapping.default);
  }, [defaultLanguage])

  useEffect(() => {
    console.log('id - ', id);

  }, [id])

  useEffect(() => {
    console.log('default lang - ', lang)
  }, [lang])

  return (
    <Box mb={4} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Card variant="outlined" style={{ borderColor: 'var(--green-200)' }}>
        <Grid container>
          {imageUrl && (
            <Grid item xs={3}>
              <CardMedia
                component="img"
                image={imageUrl}
                alt={title}
                title={title}
              />
            </Grid>
          )}
          <Grid item xs={imageUrl ? 9 : 12}>
            <CardContent style={{ padding: '20px' }}>
              <Typography variant="h5" gutterBottom align="left" style={{ fontFamily: 'Lato, sans-serif', color: 'var(--green-500)', marginBottom: '12px' }}>
                <MUILink component={StyledRouterLink} to={`/${lang}/blog/${id}`}>
                  {title}
                </MUILink>
              </Typography>
              <Typography variant="body2" align="left" style={{ fontFamily: 'Open Sans, sans-serif', color: 'var(--green-800)' }}>
                {brief}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default BlogItem;
