import React, { useState, useEffect, useContext, useImperativeHandle, useRef } from "react";
import axios from 'axios';
import AuthContext from '../contexts/AuthContext';
import { TextField, Button, Paper } from "@material-ui/core";
import { Description } from "@material-ui/icons";

const EnglishWritingPractice = ({post, visible, ref}) =>{

  const [task, setTask] = useState(null);
  const [instruction, setInstruction] = useState(null);
  const [userResponse, setUserResponse] = useState("");
  const [reviewFeedback, setReviewFeedback] = useState("");
  const [showComponent, setShowComponent] = useState(visible);
  const [showFeedback, setShowFeedback] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const { startLoading, finishLoading, setFetchError, defaultLanguage } = useContext(AuthContext);

  const userResponseRef = useRef(null);

  const handleSubmit = async () => {
    if(userResponse.trim() === "") {
      alert("User response is required!");
      return;
    }
    if (reviewFeedback !== "") {
      return
    }
    if (window.gtag) {
      window.gtag('event', 'blog_task_submit', {
        'event_category': 'button_click',
        'event_label': 'blog_task_handleSubmit',
        // Send the userResponse as the event value
        'value': task===null?'':task
      });
    }
    fetchAnswer()

  };

  async function fetchAnswer() {
    console.log('defaultLanguage - ', defaultLanguage)
    console.log('fetchAnswer - ', userResponse)
    let bilingualLanguage = sessionStorage.getItem('defaultLanguage')
    // "language": defaultLanguage === 'zh-TW' ? 'Taiwanese' : (defaultLanguage === 'zh-CN' ? 'Chinese' : (defaultLanguage === 'ja' ? 'Japanese' : 'English')),
    startLoading("Please wait while the AI to review your answer...")
    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/blog';
    const data = {
      'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
      "type":"answer",
      "question": {
        "task": task, 
        "instruction": instruction,
      },
      "language" : bilingualLanguage,
      "content": userResponse
    };

    const headers = {
      'Content-Type': 'application/json',
      'Origin': 'https://alice-language.netlify.app/'
    };

    console.log('fetchAnswer data - ', data)
    try {
      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors'
      });
  
      const article_data = await response.json();

      console.log('fetchAnswer article_data - ', article_data)
      setReviewFeedback(article_data['content'])

      finishLoading()
    } catch (error) {
        finishLoading()
        console.error('Error fetching data from server:', error);
        setFetchError(error)
    }
  }

  async function fetchBlogWriting() {
    startLoading("Please wait while the AI is generating your Writing Contest questions...")
    const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/blog';
    const data = {
      'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
      "type":"question",
      'content': post.brief
    };
  
    const headers = {
      'Content-Type': 'application/json',
      'Origin': 'https://alice-language.netlify.app/'
    };
  
    try {
      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
        mode: 'cors'
      });
      // console.log('http request headers', headers)
  
      const article_data = await response.json();
      console.log('fetchBlogWriting article_data - ', article_data)
      console.log('fetchBlogWriting task - ', article_data['task'])
      setTask(article_data['task'])
      // setInstruction(article_data['instruction'])
      setInstruction(article_data['instruction'] + '\n' + article_data['suggestion phrases and words']);

      sessionStorage.setItem(`task-${post.id}`, article_data['task']);
      sessionStorage.setItem(`instruction-${post.id}`, article_data['instruction'] + '\n' + article_data['suggestion phrases and words']);

      setDataFetched(true);  // Set dataFetched to true after successful fetch
      finishLoading()
    } catch (error) {
        finishLoading()
        console.error('Error fetching data from server:', error);
        setFetchError(error)
    }
  }

  useEffect(() => {
    console.log('EnglishWritingPractice visible - ', visible)
    console.log('EnglishWritingPractice post.id - ', post)
    if (!visible) return; // Only perform operations if the component is visible

    const taskInSessionStorage = sessionStorage.getItem(`task-${post.id}`);
    const instructionInSessionStorage = sessionStorage.getItem(`instruction-${post.id}`);
    const reviewFeedbackInSessionStorage = sessionStorage.getItem(`reviewFeedback-${post.id}`);

    console.log('EnglishWritingPractice taskInSessionStorage - ', taskInSessionStorage)
    console.log('EnglishWritingPractice instructionInSessionStorage - ', instructionInSessionStorage)
    console.log('EnglishWritingPractice reviewFeedbackInSessionStorage - ', reviewFeedbackInSessionStorage)
    if (taskInSessionStorage && instructionInSessionStorage) {
      setTask(taskInSessionStorage);
      setInstruction(instructionInSessionStorage);
      setDataFetched(true);
      
      if (reviewFeedbackInSessionStorage) {
        setShowFeedback(true)
        setReviewFeedback(reviewFeedbackInSessionStorage);
      }
    } else {
      fetchBlogWriting();
    }

  }, [visible, post.id]);

  useEffect(() => {
    console.log('EnglishWritingPractice useEffect reviewFeedback - ', reviewFeedback)
    if (reviewFeedback !== "") {
      setShowFeedback(true)
      sessionStorage.setItem(`reviewFeedback-${post.id}`, reviewFeedback);
    }
  }, [reviewFeedback, post.id]);

  useEffect(() => {
    userResponseRef.current.focus();
  }, []);

  const clearSessionStorage = () => {
    for(let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);

      if (key.startsWith('task-') || key.startsWith('instruction-') || key.startsWith('reviewFeedback-')) {
        sessionStorage.removeItem(key);
      }
    }
    const taskInSessionStorage = sessionStorage.getItem(`task-${post.id}`);
    const instructionInSessionStorage = sessionStorage.getItem(`instruction-${post.id}`);
    const reviewFeedbackInSessionStorage = sessionStorage.getItem(`reviewFeedback-${post.id}`);
    console.log('clearSessionStorage taskInSessionStorage - ', taskInSessionStorage)
    console.log('clearSessionStorage instructionInSessionStorage - ', instructionInSessionStorage)
    console.log('clearSessionStorage reviewFeedbackInSessionStorage - ', reviewFeedbackInSessionStorage)
  }

  useImperativeHandle(ref, () => ({
    clearSessionStorage,
  }));

  return (
    <>
      {showComponent && (
        <Paper style={{ padding: "1rem" }}>
          <TextField
            label="Task"
            value={task===null?'':task}
            fullWidth
            onChange={e => setTask(e.target.value)}
            margin="normal"
            multiline
            InputProps={{
              readOnly: true, // Make it non-editable
            }}
          />
          <TextField
            label="Instruction"
            value={instruction===null?'':instruction}
            onChange={e => setInstruction(e.target.value)}
            fullWidth
            multiline
            margin="normal"
            InputProps={{
              readOnly: true, // Make it non-editable
            }}
          />
          <TextField
            label="User Response"
            value={userResponse}
            onChange={e => setUserResponse(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            minRows={4}
            inputRef={userResponseRef} 
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          {showFeedback && (
            <TextField
              label="AI Review Feedback"
              value={reviewFeedback}
              fullWidth
              margin="normal"
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          )}
        </Paper>
      )}
    </>
  );
}

export default EnglishWritingPractice;
