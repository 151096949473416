import React, { useState, useContext, useEffect }  from 'react';
import { Typography, Button, Container, TextField, Box } from '@mui/material';
import styled from 'styled-components';
import AuthContext from '../contexts/AuthContext';


const TranslateContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledTextField = styled(TextField)`
  margin: 10px 0;
  & .MuiInputBase-root {
    font-size: 16px;
  }
`;

const Translate = () => {
    const [simplifiedText, setSimplifiedText] = useState('');
    const [traditionalText, setTraditionalText] = useState('');
    const { startLoading, finishLoading, setFetchError } = useContext(AuthContext);

    async function fetchAnswer() {
        startLoading("Please wait while the AI translates...");
        const fetch_url = 'https://thoote.com/translate'
        // const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/proxy'
        // const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/proxy/translate'
        // const fetch_url = 'http://199.223.232.18:5000';
        const data = {
            'Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
            'text': simplifiedText
        };

        const headers = {
            'Content-Type': 'application/json',
            'Origin': 'https://alice-language.netlify.app/'
    };

        try {
            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            });
        
            if (response.ok) {
                const article_data = await response.text();
                const decodedData = JSON.parse(article_data);
                console.log('decodedData - ', decodedData[0])
                setTraditionalText(decodedData[0]);
                // setTraditionalText(article_data);
            } else {
                // Handle non-successful HTTP responses
                const errorText = await response.text();
                setFetchError(errorText);
                console.error('Server error:', errorText);
            }
        } catch (error) {
            console.error('Error fetching data from server:', error);
            setFetchError(error);
        }
        finishLoading();
    }
    
    
    const handleTranslation = async () => {
        if (simplifiedText.trim() !== '') {
            fetchAnswer();
        }
    };

    const handleClear = () => {
        setSimplifiedText('');
        setTraditionalText('');
    };

    useEffect(() => {
        console.log('simplifiedText - ', simplifiedText)
        // if (simplifiedText.trim() !== '') {
        //     fetchAnswer();
        // }
        // fetchAnswer()
    }, [simplifiedText])

    return (
        <TranslateContainer maxWidth="md">
            <Typography variant="h5" component="h1" gutterBottom>
                Simplified to Traditional Chinese Translator v1.1
            </Typography>
            <StyledTextField
                label="Simplified Chinese"
                multiline
                rows={6}
                value={simplifiedText}
                onChange={(e) => setSimplifiedText(e.target.value)}
                variant="outlined"
                fullWidth
            />
            <Box textAlign='center' marginY={2}>
                <Button variant="contained" color="primary" onClick={handleTranslation} style={{ marginRight: '10px' }}>
                    Translate
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleClear}>
                    Clear
                </Button>
            </Box>
            <StyledTextField
                label="Traditional Chinese"
                multiline
                rows={6}
                value={traditionalText}
                InputProps={{
                    readOnly: true,
                }}
                variant="outlined"
                fullWidth
            />
        </TranslateContainer>
    );
};

export default Translate;
