import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import styled from 'styled-components';
import {
    Paper, Typography, List, ListItem, ListItemText, 
    Radio, RadioGroup, FormControlLabel, FormControl 
} from '@material-ui/core';

const StyledPaper = styled(Paper)`
    padding: 16px;
    margin: 16px 0;
    text-align: left;  // Explicitly left aligning the text
`;

const ContentTypography = styled(Typography)`
    line-height: 3.6;  // Adjust as needed for desired spacing
    margin-bottom: 16px;  // Adjust as needed for desired spacing between paragraphs
    margin-left: 32px;
`;

const WordList = styled(List)`
    display: flex;
    flex-wrap: wrap;
    li {
        width: 50%; // display two items per row
        margin-bottom: 8px;  // Adds space between list items
    }
`;

const JuniorReadingPageZhTW = () => {
    const [ selectedTopic, setSelectedTopic ] = useState(null)
    const [ReadingContent, setReadingContent ] = useState(null)
    const [ReadingOptionContest, setReadingOptionComntest] = useState([])
    const [SimpleWritingContest, setSimpleWritingContest] = useState([])
    const { startLoading, finishLoading, FetchError, setFetchError } = useContext(AuthContext);



    const FetchJuniorReadingSimpleWritingContest = (ReadingContent) => {
        if (ReadingContent === null || ReadingContent === undefined) {
            return
        }
        const fetchReading = async () => {
            const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/demo';
          
            const data = {
              'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
              "type":"ReadingWriting",
              "content": ReadingContent
            };
          
            const headers = {
              'Content-Type': 'application/json',
              'Origin': 'https://alice-language.netlify.app/'
            };
          
            console.log('ready to fetch')
            try {
              startLoading()
      
              const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
              });
          
              finishLoading()
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              let fetchContent = await response.json();
              console.log('fetchContent - ', fetchContent)
              setSimpleWritingContest(fetchContent)
              // setLoading(false);
            } catch (error) {
              finishLoading()
              console.log('fetch error - ', error)
              setFetchError(error.message)
              // setLoading(false);
            }
          };
        
          fetchReading()
  
    }

    const FetchJuniorReadingOptionContest = (ReadingContent) => {
        console.log('ReadingContent - ', ReadingContent)
        if (ReadingContent === null || ReadingContent === undefined) {
            return
        }
        const fetchReading = async () => {
            const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/demo';
          
            console.log('FetchJuniorReadingOptionContest - ', ReadingContent)
            const data = {
              'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
              "type":"ReadOption",
              "content": ReadingContent
            };
          
            const headers = {
              'Content-Type': 'application/json',
              'Origin': 'https://alice-language.netlify.app/'
            };
          
            console.log('ready to fetch')
            try {
              startLoading()
      
              const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
              });
          
              finishLoading()
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              let fetchContent = await response.json();
              console.log('fetchContent - ', fetchContent)
              setReadingOptionComntest(fetchContent)
              // setLoading(false);
            } catch (error) {
              finishLoading()
              console.log('fetch error - ', error)
              setFetchError(error.message)
              // setLoading(false);
            }
          };
          fetchReading()
    }

    useEffect(() => {
        const fetchReading = async () => {
          const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/demo';
        
          const data = {
            'Token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
            "type":"Reading"
          };
        
          const headers = {
            'Content-Type': 'application/json',
            'Origin': 'https://alice-language.netlify.app/'
          };
        
          console.log('ready to fetch')
          try {
            startLoading()
    
            const response = await fetch(fetch_url, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data),
              mode: 'cors'
            });
        
            finishLoading()
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            let fetchContent = await response.json();
            console.log('fetchContent - ', fetchContent)
            setReadingContent(fetchContent)
            // setLoading(false);
          } catch (error) {
            finishLoading()
            console.log('fetch error - ', error)
            setFetchError(error.message)
            // setLoading(false);
          }
        };
        fetchReading()

    }, []);

    useEffect(() => {
        console.log('ReadingContent - ', ReadingContent);
        if (ReadingContent === null || ReadingContent === undefined)
            return
        if (ReadingContent['content'] === null)
            return
        FetchJuniorReadingOptionContest(ReadingContent['content'])

    }, [ReadingContent]);

    useEffect(() => {
        console.log('ReadingOptionContest - ', ReadingOptionContest);
    
        if (!ReadingOptionContest) return;  // check if it's falsy, this covers null, undefined and other falsy values
    
        console.log('ReadingOptionContest - ', ReadingOptionContest);
    
        if (!ReadingContent || ReadingContent['content'] === null) return;
    
        FetchJuniorReadingSimpleWritingContest(ReadingContent['content']);
    }, [ReadingOptionContest]);
    
    useEffect(() => {
        console.log('SimpleWritingContest - ', SimpleWritingContest);
        if (!SimpleWritingContest) return;  // check if it's falsy, this covers null, undefined and other falsy values
        if (!SimpleWritingContest || SimpleWritingContest['content'] === null) return;
        console.log('SimpleWritingContest - ', SimpleWritingContest);
    }, [SimpleWritingContest])

    return (
        ReadingContent === null 
        ? null
        : (
            <div>
                <StyledPaper elevation={3}>
                    <Typography variant="h4" gutterBottom>
                        {ReadingContent.title}
                    </Typography>
                    {ReadingContent.content.split('\n').map((line, index) => (
                        <ContentTypography key={index} variant="body1">
                            {line}
                        </ContentTypography>
                    ))}
                </StyledPaper>
                <StyledPaper elevation={3}>
                    <Typography variant="h5" gutterBottom>
                        Words and Translations
                    </Typography>
                    <WordList>
                        {ReadingContent.words.map((wordPair, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={wordPair.word}
                                    secondary={wordPair.translate}
                                />
                            </ListItem>
                        ))}
                    </WordList>
                </StyledPaper>
                {ReadingOptionContest && ReadingOptionContest.content && Array.isArray(ReadingOptionContest.content) ? (
                    <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>
                            Reading Comprehension Questions
                        </Typography>
                        {ReadingOptionContest['content'].map((item, idx) => (
                            <div key={idx}>
                                <Typography variant="h6" gutterBottom>
                                    {item.question}
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup>
                                        {item.options.map((option, optionIdx) => (
                                            <FormControlLabel 
                                                key={optionIdx} 
                                                value={option} 
                                                control={<Radio />} 
                                                label={option} 
                                            />
                                        ))}
                                    </RadioGroup>
                                    <Typography variant="body1" color="primary" style={{ marginLeft: '16px' }}>
                                        Correct Answer: {item.answer}
                                    </Typography>
                                </FormControl>
                            </div>
                        ))}
                    </StyledPaper>
                ) : null}
                {SimpleWritingContest && SimpleWritingContest.content && Array.isArray(SimpleWritingContest.content) ? (
                    <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>
                            Writing Tasks
                        </Typography>
                        {SimpleWritingContest.content.map((item, idx) => (
                            <div key={idx} style={{ padding: '20px', marginBottom: '20px', borderBottom: idx !== SimpleWritingContest.content.length - 1 ? '1px solid #ddd' : 'none' }}>
                                <Typography variant="h6" gutterBottom>
                                    Grammar: {item.grammar}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Example: {item.example}
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    Task: {item.task_description}
                                </Typography>
                            </div>
                        ))}
                    </StyledPaper>
                ) : null}

            </div>
        )
    )
};

export default JuniorReadingPageZhTW;