import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button, Container, TextField, Box } from '@mui/material';
import styled from 'styled-components';
import AuthContext from '../contexts/AuthContext';

const TranslateContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledTextField = styled(TextField)`
  margin: 10px 0;
  & .MuiInputBase-root {
    font-size: 16px;
  }
`;

const TranslateDocument = () => {
    const [simplifiedText, setSimplifiedText] = useState('');
    const [file, setFile] = useState(null);
    const [sourceText, setSourceText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [traditionalText, setTraditionalText] = useState('');
    const { startLoading, finishLoading, setFetchError } = useContext(AuthContext);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    async function fetchAnswer() {
        startLoading("Please wait while the AI translates...");
        const fetch_url = 'https://thoote.com/translate'
        // const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/proxy'
        // const fetch_url = 'https://asia-east1-thoote.cloudfunctions.net/proxy/translate'
        // const fetch_url = 'http://199.223.232.18:5000';
        const data = {
            'Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE',
            'text': sourceText
        };

        console.log("ready to translate - " + sourceText)

        const headers = {
            'Content-Type': 'application/json',
            'Origin': 'https://alice-language.netlify.app/'
    };

        try {
            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
                mode: 'cors'
            });
        
            if (response.ok) {
                const article_data = await response.text();
                const decodedData = JSON.parse(article_data);
                console.log('decodedData - ', decodedData[0])
                setTranslatedText(decodedData[0]);
                // setTraditionalText(article_data);
            } else {
                // Handle non-successful HTTP responses
                const errorText = await response.text();
                setFetchError(errorText);
                console.error('Server error:', errorText);
            }
        } catch (error) {
            console.error('Error fetching data from server:', error);
            setFetchError(error);
        }
        finishLoading();
    }
    
    const handleFileUpload = async () => {
        startLoading("Please wait while the AI translates...");
    
        const formData = new FormData();
        formData.append("file", file);
    
        try {
            const uploadResponse = await fetch('https://thoote.com/Upload', {
                method: 'POST',
                body: formData
            });
    
            if (uploadResponse.ok) {
                const parsedContent = await uploadResponse.json();
                let payload = JSON.stringify({
                    "Token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQzMDg3NDd9.2jY8iOhQiW09CnN9z-x2IcUYqvS1kLq9lsV0lF1-9pE",
                    "file": parsedContent['filename']
                });
                console.log('success upload file - ' + parsedContent['filename']);
                const previewResponse = await fetch('https://thoote.com/preview', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: payload
                });
    
                if (previewResponse.ok) {
                    // Handle response from the preview API
                    // Example: const previewContent = await previewResponse.json();
                    // setTranslatedText(previewContent);
                    let previewContent = await previewResponse.json()
                    setSourceText(previewContent["content"]);
                } else {
                    // Handle non-successful response from the preview API
                }
            } else {
                const errorText = await uploadResponse.text();
                console.error('Error uploading file:', errorText);
                setFetchError(errorText);
            }
        } catch (error) {
            console.error('Error:', error);
            setFetchError(error);
        }
        finishLoading();
    };
    

    const handleTranslate = async () => {
        // Implement translation logic here, using traditionalText as the input
        if (sourceText.trim() !== '') {
            fetchAnswer();
        }
    };

    const handleClear = () => {
        // setSimplifiedText('');
        setFile(null);
        setSourceText('');
        setTranslatedText('');
    };

    return (
        <TranslateContainer maxWidth="md">
            <Typography variant="h5" component="h1" gutterBottom>
                Document Upload and Translate v1.3
            </Typography>
            <input type="file" onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleFileUpload} style={{ margin: '10px' }}>
                Upload
            </Button>
            <StyledTextField
                label="Source Content"
                multiline
                rows={6}
                value={sourceText}
                onChange={(e) => setSourceText(e.target.value)}
                variant="outlined"
                fullWidth
            />
            <Box textAlign='center' marginY={2}>
                <Button variant="contained" color="primary" onClick={handleTranslate} style={{ marginRight: '10px' }}>
                    Translate
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleClear}>
                    Clear
                </Button>
            </Box>
            <StyledTextField
                label="Translated Content"
                multiline
                rows={6}
                value={translatedText}
                InputProps={{
                    readOnly: true,
                }}
                variant="outlined"
                fullWidth
            />
        </TranslateContainer>
    );
};

export default TranslateDocument;
