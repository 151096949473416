import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { style } from 'd3';
import { Collapse } from 'react-bootstrap';

import React, { useState, useEffect, useContext } from 'react';
import styled, { css }  from 'styled-components';
import Card from 'react-bootstrap/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useSpring, animated, config } from 'react-spring';


// import ScoreChart from './ScoreChart';
// import BarChart from './BarChart';

// const writing_answer_json = {
//     "user_id": "111253404818182660338",
//     tasks:[
//         {
//             "task_id": 20230621,
//             "task": [
//                 {
//                 "ID": "1686585026204-TFullEssayI0",
//                 "Expected Level": "B1 Intermediate",
//                 "Task": "Write an essay describing your favorite hobby.",
//                 "Instructions": "Write a composition of about 100 words on the topic above. Your essay should have an introduction,a body,and a conclusion. You can use the following questions to guide your essay:\n- What is your hobby?\n- How did you become interested in it?\n- What do you like most about it?\n- Why is it important to you?",
//                 "Student response": "Garden is my favorite hobby. In the garden, I could find a piece of peace. I can collect my thoughts. When I was study in university, I was facing several stress from report, research and test. I found the garden could help me to freeze.",
//                 "response":{
//                     "Sample Answer": "My favorite hobby is gardening. I became interested in it because of the peace and tranquility it brings me. I love being surrounded by plants and flowers, and watching them grow. Gardening is important to me because it allows me to take a break from the stresses of everyday life and connect with nature.",
//                     "Positive Feedback": "The student has provided a clear and concise response that addresses the prompt.",
//                     "Grammar Feedback": "The grammar is generally correct, but there are some errors in subject-verb agreement and preposition usage.",
//                     "Vocabulary Feedback": "The vocabulary is simple but appropriate for the task.",
//                     "Clarity Feedback": "The response is clear and easy to understand.",
//                     "Adherence Feedback": "The student has followed the instructions and provided a description of their favorite hobby and why they enjoy it.",
//                     "Assessment": "Below B1 Intermediate level",
//                     "Score": {
//                         "Grammar":3,
//                         "Vocabulary":3,
//                         "Clarity":3,
//                         "Adherence":3
//                         }
//                     }
//                 },
//                 {
//                     "ID": "1686585026204-TLetterWritingI0",
//                     "Expected Level": "B1 Intermediate",
//                     "Task": "Write a letter to your friend who is visiting your city for the first time. Give him/her suggestions on things to do and places to visit.",
//                     "Instructions": "Write a letter of about 100 words on the topic above. Your letter should have an introductory paragraph,two or three body paragraphs,and a concluding paragraph. You can use the following questions to guide your letter:\n- What are some popular tourist attractions in your city?\n- What are some off-the-beaten-path places to visit?\n- What are some good restaurants or cafes to try?\n- What are some fun activities to do in your city?",
//                     "Student response": "Hi Jack,\nI am so excited you will visit my hometown next month. Remember that, the town is a Maple town. Don't forget to enjoy the beautiful of Maple. Next month, the Maple will be hot.\n",
//                     "response":{
//                         "Sample Answer": "Last summer, I traveled to Hualien, located in the east of Taiwan. During my trip, I visited the Jhu-iLu trail and was amazed by the breathtaking views from the cliff.",
//                         "Positive Feedback": "The student has provided a clear and concise response to the prompt.",
//                         "Grammar Feedback": "The grammar is generally correct, but there are some errors in verb tense and preposition usage.",
//                         "Vocabulary Feedback": "The vocabulary is simple but appropriate for the task.",
//                         "Clarity Feedback": "The response is clear and easy to understand.",
//                         "Adherence Feedback": "The student has followed the instructions and asked for advice on how to improve their progress.",
//                         "Assessment": "B1 Intermediate",
//                         "Score": {
//                             "Grammar":4,
//                             "Vocabulary":4,
//                             "Clarity":3,
//                             "Adherence":3
//                             }
//                         }
//                 }
//             ]
//         }, 
//         {
//             "task_id": 20230622,
//             "task": [
//                 {
//                 "ID": "1686585026304-TFullEssayI0",
//                 "Expected Level": "B1 Intermediate",
//                 "Task": "Write an essay describing your favorite hobby.",
//                 "Instructions": "Write a composition of about 100 words on the topic above. Your essay should have an introduction,a body,and a conclusion. You can use the following questions to guide your essay:\n- What is your hobby?\n- How did you become interested in it?\n- What do you like most about it?\n- Why is it important to you?",
//                 "Student response": "Garden is my favorite hobby. In the garden, I could find a piece of peace. I can collect my thoughts. When I was study in university, I was facing several stress from report, research and test. I found the garden could help me to freeze.",
//                 "response":{
//                     "Sample Answer": "My favorite hobby is gardening. I became interested in it because of the peace and tranquility it brings me. I love being surrounded by plants and flowers, and watching them grow. Gardening is important to me because it allows me to take a break from the stresses of everyday life and connect with nature.",
//                     "Positive Feedback": "The student has provided a clear and concise response that addresses the prompt.",
//                     "Grammar Feedback": "The grammar is generally correct, but there are some errors in subject-verb agreement and preposition usage.",
//                     "Vocabulary Feedback": "The vocabulary is simple but appropriate for the task.",
//                     "Clarity Feedback": "The response is clear and easy to understand.",
//                     "Adherence Feedback": "The student has followed the instructions and provided a description of their favorite hobby and why they enjoy it.",
//                     "Assessment": "Below B1 Intermediate level",
//                     "Score": {
//                         "Grammar":3,
//                         "Vocabulary":3,
//                         "Clarity":3,
//                         "Adherence":3
//                         }
//                     }
//                 },
//                 {
//                     "ID": "1686585026304-TLetterWritingI0",
//                     "Expected Level": "B1 Intermediate",
//                     "Task": "Write a letter to your friend who is visiting your city for the first time. Give him/her suggestions on things to do and places to visit.",
//                     "Instructions": "Write a letter of about 100 words on the topic above. Your letter should have an introductory paragraph,two or three body paragraphs,and a concluding paragraph. You can use the following questions to guide your letter:\n- What are some popular tourist attractions in your city?\n- What are some off-the-beaten-path places to visit?\n- What are some good restaurants or cafes to try?\n- What are some fun activities to do in your city?",
//                     "Student response": "Hi Jack,\nI am so excited you will visit my hometown next month. Remember that, the town is a Maple town. Don't forget to enjoy the beautiful of Maple. Next month, the Maple will be hot.\n",
//                     "response":{
//                         "Sample Answer": "Last summer, I traveled to Hualien, located in the east of Taiwan. During my trip, I visited the Jhu-iLu trail and was amazed by the breathtaking views from the cliff.",
//                         "Positive Feedback": "The student has provided a clear and concise response to the prompt.",
//                         "Grammar Feedback": "The grammar is generally correct, but there are some errors in verb tense and preposition usage.",
//                         "Vocabulary Feedback": "The vocabulary is simple but appropriate for the task.",
//                         "Clarity Feedback": "The response is clear and easy to understand.",
//                         "Adherence Feedback": "The student has followed the instructions and asked for advice on how to improve their progress.",
//                         "Assessment": "B1 Intermediate",
//                         "Score": {
//                             "Grammar":4,
//                             "Vocabulary":4,
//                             "Clarity":3,
//                             "Adherence":3
//                             }
//                         }
//                 }
//             ]
//         }
//     ]
// }


const HistoryContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid var(--grey-300);
  border-radius: 10px;
  padding: 20px;
  background: var(--white);
`;

const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--green-500);
`;

const LevelButton = styled.button`
  padding: 10px 20px;
  border: none;
  background: var(--green-500);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
  margin-left: 20px;

  &:hover {
    background: var(--green-600);
  }
`;

const StyledCollapse = styled(Collapse)`
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 10px;
`;


const CardHeaderStyled = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  background-color: #ffeeba;
  border-color: #ffeeba;
  color: #856404;
  transition: background-color 0.3s ease;
  padding: 10px;  // Add this line to give space between border and text

  &:hover {
    background-color: #ffd25a;  // Change to any color that suits your design
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    border-color: #007bff; // Change this to your preferred color
    box-shadow: 0 2px 3px rgba(0,0,0,0.15); // This adds a slight shadow to the card
  }
`;


// const ScoreChart = styled.div`
//   width: 200px;
//   height: 50px;
//   margin-left: 20px;
// `;

const History = ({input_history, onSelectedItem}) => {

    const [history, setHistory] = useState(null)

    const [open, setOpen] = React.useState((history===null || history===undefined)?[]:Array(history.tasks===undefined?0:history.tasks.length).fill(true)); // initialize as an empty array

    const handleToggle = (index) => {
        console.log('History handle toggle - ', index)
        console.log('History handle open - ', open[index])
        const newOpen = [...open];
        newOpen[index] = !newOpen[index];
        console.log('History handle toggle newOpen - ', newOpen[index])

        setOpen(newOpen);
    }

    const AnimatedDiv = ({ isOpen, children }) => {
        const [internalOpen, setInternalOpen] = useState(isOpen);
        
        const transitions = useSpring({
            from: { opacity: 0, height: 0 },
            to: { opacity: isOpen ? 1 : 0, height: isOpen ? 'auto' : 0 },
            config: config.stiff,
        });
    
        useEffect(() => {
            if (isOpen !== internalOpen) {
                setInternalOpen(isOpen);
            }
        }, [isOpen, internalOpen]);
    
        return (
            <animated.div style={transitions}>
                {children}
            </animated.div>
        );
    };
                   
    
    useEffect(() => {
        console.log('History input_history - ', input_history)
        if (input_history !== null && input_history !== undefined) {
            const tasks = input_history.tasks;
            setHistory(input_history);
            setOpen(Array(tasks.length).fill(false)); // all tasks are initially closed
        } else {
            setHistory(null);
            setOpen([]);    
        }
    }, [input_history]);
        

    const calculateAverageScore = (score) => {
        if (score===null || score === undefined) {
            return 0
        }
        const scoreValues = Object.values(score);
        const sum = scoreValues.reduce((acc, curr) => acc + curr, 0);
        const average = sum / scoreValues.length;
        return average;
    };
    
    const formatDate = (dateStr) => {
        if (!dateStr) {
            return "N/A";
        }
        const parts = dateStr.split('-'); // split by hyphen
        const year = parts[0];
        let month = parts[1];
        let day = parts[2];
    
        // Ensure month and day are always two digits
        if (month.length === 1) month = "0" + month;
        if (day.length === 1) day = "0" + day;
    
        return `${year}-${month}-${day}`;
    };
        

    return (    
        <HistoryContainer>
            {history===null || history===undefined  ? (
                <p>No history available</p>
            ) : (
                history.tasks===undefined?<p>No history available</p>:history.tasks.map((taskSet, index) => (
                    <Card key={index} style={{ marginBottom: '20px' }}>
                        <CardHeaderStyled as="h3" onClick={() => handleToggle(index)}>
                            Task Group Date: {formatDate(taskSet.task_id.toString())}
                            <IconButton>
                                {open[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </CardHeaderStyled>
                        <AnimatedDiv isOpen={open[index]}>
                            <div>
                                <Card.Body>
                                    {taskSet.task.map((test, idx) => (
                                        <StyledCard key={`${index}-${idx}`} style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => onSelectedItem && onSelectedItem(test)}>
                                            <Card.Header>Test ID: {test.ID}</Card.Header>
                                            <Card.Body>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div>
                                                    <p style={{ textAlign: 'left' }}>Level: {test.response["Assessment"]}</p>
                                                    <p>Average Score: {calculateAverageScore(test.response.Score)}</p>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </div>
                                            </Card.Body>
                                        </StyledCard>
                                    ))}
                                </Card.Body>
                            </div>
                        </AnimatedDiv>
                    </Card>
                ))
            )}
        </HistoryContainer>
   );
};

export default History;
