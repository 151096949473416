import React, { useEffect, useRef, useState } from 'react';


const Answer = ({index, element, underscore, user_input, confirm_callback}) => {

    const [isShown, setShown] = useState(false);
    const ansRef = useRef(null)

    const isOverlap =(rect1, rect2) => {
      
        return !(rect2.left > rect1.right || 
                 rect2.right < rect1.left || 
                 rect2.top > rect1.bottom ||
                 rect2.bottom < rect1.top);
    }

    useEffect(() => {
        if (user_input === null)
            return
        if (user_input.word === element) {
            console.log('Answer rect - ', user_input.rect)
            
            setShown(isOverlap(ansRef.current.getBoundingClientRect(), user_input.rect))
        }

    }, [user_input])

    useEffect(() => {
        console.log('Answer isShown - ', isShown)
        console.log('Answer element - ', element)
        if (isShown === true) {
            confirm_callback(element)
        }
    }, [isShown])

    return (
        <div ref={ansRef}>
            <span>{isShown? element : '_'.repeat(underscore.length * 3)}</span>
        </div>
    );
    
}


export default Answer;
