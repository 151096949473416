import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function RadarDiagram({ record }) {
  if (!record || !record.response || !record.response.Score) {
    return null; // or render a default message/error
  }

  const data = {
    labels: Object.keys(record.response.Score),
    datasets: [
      {
        label: 'Score',
        data: Object.values(record.response.Score),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        ticks: {
          beginAtZero: true,
          max: 5,
        },
      },
    },
  };
  

  return (
    <div>
      <h3>Score Radar Diagram</h3>
      <Radar data={data} options={options} />
    </div>
  );
}

export default RadarDiagram;
