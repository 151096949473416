// AppEn.js
import React, {useContext} from 'react';
import Highlight from './components/Highlight';
import Hero_Section from './components/Hero_Section';
import EnglishCertificationLevels from './components/EnglishCertificationLevels';
import WritingQuizPage from './components/WritingQuizPage';
import ReadingQuizPage from './components/ReadingQuizPage';
import BlogItem from './components/BlogItem';
import DataLoader from './contexts/DataLoader';

import AuthContext from './contexts/AuthContext';
import { Helmet } from "react-helmet";
import { Box } from '@mui/material';
import styled from 'styled-components';

// import other necessary components and hooks...
const StyledCertificationLevels = styled(EnglishCertificationLevels)`
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem;
`;

const StyledHighlight = styled(Highlight)`
  background-color: #fafafa;
  padding: 2rem;
  border-radius: 5px;
  margin: 1rem;
`;

const AppTw = () => {
  // Move the related states, functions and effects here...
  const { showReadingQuiz, showWritingQuiz, currentProgressIndex, lastCompleteIndex, setCurrentProgressIndex, setLastCompleteIndex, startReadingQuiz, startWritingQuiz, goHome, setGoHome } = useContext(AuthContext);


  const handleReadingQuizPrevious = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex - 1)
  } 

  const handleReadingQuizCompletion = () => {
    if (currentProgressIndex >= lastCompleteIndex) 
      setLastCompleteIndex(currentProgressIndex)
  };

  const handleReadingQuizNext = () => {
    console.log('ReadingQuizPage article index - ' + currentProgressIndex)
    setCurrentProgressIndex(currentProgressIndex + 1)
  }


  return (
    <div className="AppTw">
      <Helmet>
        <title>AI Learning Bar愛學霸 - 由AI驅動的進階英語學習</title>
        <meta name="description" content="利用AI Learning Bar愛學霸提升您的英語語言技能。我們的平台由GPT驅動，提供個性化的閱讀和寫作測驗，使學習效果卓越且富有趣味性。立即開始學習！" />
        <meta name="keywords" content="AI驅動的英語學習，GPT，OpenAI，個性化閱讀測驗，個性化寫作測驗，學生英語，進階英語學習，ESL" />
        <link rel="alternate" href="https://ailearningbar.com/en/" hreflang="en" />
        <link rel="alternate" href="https://ailearningbar.com/tw/" hreflang="zh-TW" />
        <link rel="alternate" href="https://ailearningbar.com/jp/" hreflang="ja" />
        <link rel="alternate" href="https://ailearningbar.com/cn/" hreflang="zh-CN" />
      </Helmet>
      {/* English version components here */}
      {!showReadingQuiz && goHome && (
        <>
          <Box id='hero_section' marginLeft='10px' maxWidth="90%">
            <Hero_Section onStartQuiz={startWritingQuiz} />
          </Box>
          <Box id='BlogQuickTest' marginLeft='10px' maxWidth="90%" >
            <DataLoader lang='tw'>
              {(data) => {
                  console.log('data loader data - ', data);
                  if (data && data.length > 0) {
                      return data.slice().reverse().map(post => <BlogItem key={post.id} imageUrl={post.imageSrc} {...post} />);
                  } else {
                      return <p>No posts available.</p>;
                  }
              }}
            </DataLoader>
          </Box>

          <Box id='Hightlights' marginLeft='10px' maxWidth="90%" >
            <Highlight />
          </Box>
        </>
      )}
      {!goHome && (
        <div className="reading-quiz-page-wrapper">
          {showWritingQuiz ? <WritingQuizPage /> : <ReadingQuizPage onPrev={handleReadingQuizPrevious} onCompletion={handleReadingQuizCompletion} onNext={handleReadingQuizNext}/>}
        </div>
      )}
    </div>
  );
}

export default AppTw;
